/* eslint-disable @next/next/no-img-element */
import { Box, Typography } from "@mui/material";
import Link from "next/link";
import useTranslation from "../../../hooks/useTranslation";
import cssVars from "../../../styles/vars.module.scss";

const CampaignCard = ({ item, sx }) => {
    const { t } = useTranslation()

    return (
        <Box className="campaign-card" key={item.id} sx={{
            height: '100%',
            "& a": {
                height: '100%',
                border: '1px solid #c6c7c8',
                borderRadius: cssVars.radius,
                boxShadow: '0px 8px 8px -4px rgb(44 44 44 / 4%), 0px 10px 24px -4px rgb(44 44 44 / 10%)',
                padding: '1.2rem 1.2rem 272px 1.2rem',
                backgroundPosition: 'bottom -10px right -5px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto 272px',
                display: "block", color: item.color, backgroundImage: `url(${item.image})`,
                "& h2": {
                    fontSize: '1.25rem',
                    lineHeight: '1.25rem',
                    position: 'relative',
                    color: cssVars.black,
                    width: "fit-content"
                }
            },
            ...sx
        }}>
            <Link className="campaign-link" href={(item.link || "#")}>
                <Box >
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            borderBottom: `4px solid ${item.color}`,
                            paddingLeft: '10px',
                            paddingBottom: '10px',
                            '& svg, & img': {
                                height: '1.4rem',
                                marginRight: '0.5rem',
                                width: 'unset',
                            },
                        }}
                    >
                        <img style={{ marginRight: "8px", width: "fit-content" }} src={item.icon} alt="icon" />
                        <Typography component="h2">{item.title}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        paddingTop: '16px',
                        '& .cat-text': {
                            color: cssVars.black,
                            fontWeight: 500,
                        },
                        '& .cat-description': {
                            color: cssVars.black,
                            minHeight: '96px',
                        },
                        '& .cat-status': {
                            fontSize: '0.875rem',
                            color: cssVars.extraDarkGray,
                            marginTop: '20px',
                        },
                    }}
                >
                    <Box className="cat-description dots4" dangerouslySetInnerHTML={{ __html: (item.content || item.description) }} />
                    {item.toData ? <Box className="cat-status">{t(`Valid until`)} {item.toDate}</Box> : null}
                </Box>
            </Link>
        </Box>
    );
}

export default CampaignCard;