


import { useEffect, createContext, useState, useMemo } from "react";
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES_SEARCHES, GET_POPULAR_SEARCHES, GET_PRODUCTS_SEARCHES, GET_PRODUCTS_SEARCHES1 } from "../graphql/search/queries";
import { useRouter } from "next/router";
import parseSearchParams from "../helpers/SearchParams";

export const SearchContext = createContext({})

export const SearchProvider = ({ children }) => {

    const router = useRouter()

    const searchParams = parseSearchParams(router)

    const [search, setSearch] = useState(searchParams.q || '')

    useEffect(() => {
        setSearch(searchParams.q || '')
    }, [router])

    const [skip, setSkip] = useState(true)
    console.log(search);
    // const [popularSearches, setPopularSearches] = useState()
    // const [categoriesSearches, setCategoriesSearches] = useState()
    const [productsSearches, setProductsSearches] = useState()

    // const { data: popularSearches1, loading: popularLoading } = useQuery(GET_POPULAR_SEARCHES, { variables: { search: search }, skip:true })
    // const { data: categoriesSearches1, loading: categoriesLoading } = useQuery(GET_CATEGORIES_SEARCHES, { variables: { search: search }, skip:true })
    const { data: productsSearches1, loading: productsLoading } = useQuery(GET_PRODUCTS_SEARCHES1, { variables: { search: search }, skip: (skip || search?.length === 0), context: { fetchOptions: { method: "GET" } } })

    useEffect(() => {
        if (productsSearches1) {
            // setPopularSearches(popularSearches1)
            // setCategoriesSearches(categoriesSearches1)
            console.log(productsSearches1);
            setProductsSearches(productsSearches1)
            // setSkip(true)
        }
    }, [productsSearches1])

    useEffect(() => {
        if (search?.length > 0) {
            setSkip(false)
        }
        else {
            setSkip(true)
            setProductsSearches(null)
        }
    }, [search])

    const contextValue = useMemo(() => ({
        search,
        setSearch,
        // popularSearches,
        // popularLoading,
        // categoriesSearches,
        // categoriesLoading,
        productsSearches,
        productsLoading
    }), [
        search,
        setSearch,
        // popularSearches,
        // popularLoading,
        // categoriesSearches,
        // categoriesLoading,
        productsSearches,
        productsLoading
    ])

    return (
        <SearchContext.Provider value={contextValue} >
            {children}
        </SearchContext.Provider>
    )
}