import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import cssVars from '../../styles/vars.module.scss'

const Carousel = ({ children, ...options }) => {

    const theme = useTheme()

    const [container, setContainer] = useState((
        <Box className="carousel-first-render" sx={{ overflow: "hidden", mt: 2.5, mb: 12 }}>
            <Box display="flex" alignItems="center" sx={{ flexWrap: "nowrap", width: "100% !important", "&>*": { width: (options.items ? `${100 / options.items}%` : "25%") } }}>
                {children.slice(0, (options.items || 6))}
            </Box>
        </Box>
    ))

    const router = useRouter()

    useEffect(() => {
        setContainer(
            (
                <Box >
                    <OwlCarousel
                        mouseDrag={false}
                        pullDrag={false}
                        onRefreshed={() => {
                            if (options.id) {
                                document.querySelectorAll(`#${options.id} a`).forEach(element => {
                                    element.addEventListener("click", (e) => {
                                        e.preventDefault()
                                        if (element.getAttribute('target') === '_blank') {
                                            window.open(element.getAttribute('href'))
                                        }
                                        else if (element.getAttribute('href').startsWith('#') && element.getAttribute('href').length > 1) {
                                            document.getElementById(element.getAttribute('href').substring(1)).scrollIntoView({ behavior: 'smooth' })
                                        }
                                        else {
                                            router.push(element.getAttribute('href'))
                                        }
                                    })
                                });
                            }
                        }}
                        navText={[
                            '<svg width="26" height="44" viewBox="0 0 26 44"><path d="M22.371 -2.53595e-07L25.1895 2.77523L15.4416 12.4015L5.69384 22.0279L25.1895 41.2248L22.371 44L0.000425298 22.0279L22.371 -2.53595e-07Z"></path></svg>',
                            '<svg width="26" height="44" viewBox="0 0 26 44"><path d="M3.62896 44L0.810547 41.2248L10.5584 31.5985L20.3062 21.9721L0.810547 2.77523L3.62896 0L25.9996 21.9721L3.62896 44Z"></path></svg>',
                        ]}
                        {...options}
                    >
                        {children}
                    </OwlCarousel>
                </Box>
            )
        )
    }, [theme.palette.primary.dark])

    return container
}

export default Carousel;