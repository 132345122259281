import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Link, Typography } from '@mui/material';
import cssVars from '../../styles/vars.module.scss'


const TreeView = ({ data, nodeRender, nodeKey = "children", isRoot = true, uid, icon = null, onClick, value }) => {
    const [closed, setClosed] = useState([])
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        if (value) {            
            setSelected(value)
        } else if (data) {            
            setSelected(data[uid])
        }
    }, [data, value])

    const TreeNode = ({ data, nodeRender, nodeKey = "children", isRoot = true, selected, setSelected, level = 0, icon = null, onClick, closed, setClosed }) => {
        const [opened, setOpened] = useState(!closed.includes(data[uid]))

        const clickDeal = () => {
            onClick(data)
            setSelected(data[uid])
        }

        const branchNodeClick = () => {
            if (opened) {
                setClosed([...closed, data[uid]])
            }
            else {
                setClosed(closed.filter(node => node !== data[uid]))
            }
        }

        return (
            <Box sx={{ fontFamily: "inherit" }}>
                {nodeRender
                    ?
                    <Box sx={{ pl: { xs: 0, md: 2 }, position: "relative", fontFamily: "inherit" }}>
                        <Box sx={{
                            width: "100%",
                            lineHeight: "38px",
                            fontSize: 15,
                            fontWeight: selected === data[uid] ? 500 : 400,
                            cursor: "pointer",
                            display: "inline-block",
                            color: cssVars.black,
                            ":hover": { backgroundColor: selected === data[uid] ? cssVars.primary + "30" : cssVars.extraLightGray },
                            backgroundColor: selected === data[uid] ? cssVars.primary + "30" : "transparent",
                            border: selected === data[uid] ? `1px solid ${cssVars.primary}+"30"` : "1px solid transparent",
                            borderRadius: "7px",
                            pl: 1,
                            my: 0.5,
                        }}
                            onClick={() => clickDeal()} >
                            {
                                data[nodeKey]?.length > 0
                                    ?
                                    <ChevronRightIcon sx={{ mr: 0.5, transition: "transform 0.1s", transform: opened ? "rotate(90deg)" : "none" }}
                                        onClick={() => branchNodeClick()}
                                    />
                                    :
                                    <Typography variant='span' sx={{ display: "inline-block", pl: 4 }} className='d-inline-block ps-4'></Typography>
                            }
                            {
                                !data[nodeKey]?.length
                                    ?
                                    <Typography variant='span' >
                                        {icon}
                                    </Typography>
                                    :
                                    null
                            }
                            <Typography variant='span'>
                                {nodeRender ? nodeRender(data) : ""}
                            </Typography>
                        </Box>

                        {(data[nodeKey]?.length > 0 & opened)
                            ?
                            data[nodeKey].map(child =>
                                <TreeNode key={data[uid] + child[uid]} data={child} nodeRender={nodeRender} nodeKey={nodeKey} isRoot={false} level={level ? level + 1 : 1} uid={uid} selected={selected} setSelected={setSelected} icon={icon} closed={closed} setClosed={setClosed} onClick={onClick} />
                            )
                            :
                            null
                        }
                    </Box>
                    :
                    null
                }

            </Box>
        )
    }

    return <Box sx={{
        width: "100%",
        fontSize: 16,
        fontWeight: 500,
        cursor: "pointer",
        display: "inline-block",
        color: selected === data[uid] ? cssVars.blue : "black"
    }}  >
        <TreeNode data={data} nodeRender={nodeRender} nodeKey={nodeKey} isRoot={isRoot} uid={uid} selected={selected} setSelected={setSelected} level={0} icon={icon} onClick={onClick} closed={closed} setClosed={setClosed} />
    </Box>
}

export default TreeView