import useTranslation from "../../hooks/useTranslation";
import { MultiWishListContext } from "../../Providers/MultiWishListContext";
import { useRouter } from "next/router";
import { Box, Link } from "@mui/material";
import ProductTitle from "./productTitle";
import ProductCardFooter from "./product-card-footer";
import WishListSection from "./wishListSection";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StockStatus from "../stock-status/stockStatus";
import { useContext } from "react";
import { useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { useGlobalStore } from "../../stores/globalStore";
import PercentOff from "./percentOff";
import Variants from "./variants";
import cssVars from "../../styles/vars.module.scss";
import ProductCardHeader from "./productCardHeader";
import Tags from "./tags";
import { useCatStore } from '../../stores/catStore'
import { useSnackbar } from "notistack";

const ProductCard = ({
    product,
    onFavClick,
    isVariant = false,
    variantOptions,
    isMoving = false,
    isCalibration = false
}) => {
    const { wishLists, isFav, addProductToWishlist, removeProductFromWishlist } = useContext(MultiWishListContext)

    const [showWishLists, setShowWishLists] = useState(false);
    const isAuthenticated = useAuthStore(state => state.token?.length > 0)

    const getURL = (product) => {
        if (isVariant || isCalibration) {
            return '#'
        }
        else {
            return "/" + product.canonical_url
        }
    }

    const calcNew = (from, to) => {
        const now = Date.now();
        return (from ? new Date(from).getTime() <= now : false) && (to ? (new Date(to).getTime() >= now) : true);
    };


    const isNew = calcNew(product.new_from_date, product.new_to_date) || product?.categories?.filter(cat => cat.name === "Nouveautes")?.length > 0;

    const router = useRouter()

    const setCatPath = useCatStore(state => state.setCatPath)
    const handleCat = (e, url) => {
        e.preventDefault()
        setCatPath(router.asPath)
        router.push(url)
    }

    const { enqueueSnackbar } = useSnackbar()

    const handleFavClick = () => {
        if (onFavClick) {
            onFavClick();
        } else {
            if (isAuthenticated) {
                setShowWishLists(prev => (!prev));
            } else {
                router.push("/customer/login.html" + `?redirect=${router.asPath}`);
            }
        }
    };



    const isAriba = useGlobalStore(s => s.isAriba)

    const Component = (isVariant || isCalibration ? Box : Link)
    const ComponentProps = isVariant || isCalibration
        ?
        { className: "product-card-link" }
        :
        {
            href: getURL(product),
            onClick: (e) => handleCat(e, getURL(product))
        }

    return (
        <Box
            position="relative"
            sx={{
                "& a, & .product-card-link": {
                    color: "inherit",
                    textDecoration: "none",
                    display: "block",
                    border: `1px solid ${cssVars.darkGray} !important`,
                    boxShadow: `${cssVars.shadow} !important`,
                    borderRadius: cssVars.radius,
                    cursor: isVariant ? "auto" : "pointer",
                    p: "10px 21px 21px",
                    cursor: isCalibration ? "auto" : "pointer",
                }
            }}
        >
            <Component {...ComponentProps}>
                <ProductCardHeader product={product} isNew={isNew} isVariant={isVariant} isCalibration={isCalibration} />
                <Box
                    sx={{
                        position: "relative",
                        mt: 1.5,
                        height: "223px",
                        padding: 1,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundOrigin: "content-box",
                        backgroundImage: (!isVariant ? "url(" + (product.image?.url || product.small_image?.url) + ")" : null)
                    }}
                >
                    {
                        !isCalibration
                            ?
                            <Box position="absolute" top={0} right={-7}>
                                {isFav(product.sku)
                                    ? product.__typename != "ConfigurableProduct" && (
                                        <FavoriteIcon
                                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleFavClick() }}
                                            sx={{ visibility: isAriba ? "hidden" : "visible", fontSize: "30px", color: cssVars.blue, "&:hover": { color: cssVars.darkBlue } }}
                                        />
                                    )
                                    : product.__typename != "ConfigurableProduct" && (
                                        <FavoriteBorderOutlinedIcon
                                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleFavClick() }}
                                            sx={{ visibility: isAriba ? "hidden" : "visible", fontSize: "30px", color: cssVars.darkGray, "&:hover": { color: cssVars.blue } }}
                                        />
                                    )
                                }
                            </Box>
                            :
                            null
                    }
                    <PercentOff product={product} isVariant={isVariant} />
                    <Tags product={product} />
                    <Variants isVariant={isVariant} variantOptions={variantOptions} />
                </Box>
                <ProductCardFooter product={product} isVariant={isVariant} isCalibration={isCalibration} />
                {
                    !onFavClick
                        ?
                        <WishListSection
                            sku={product.sku}
                            wishLists={wishLists}
                            show={showWishLists}
                            setShowWishLists={setShowWishLists}
                        />
                        :
                        null
                }
            </Component >
        </Box>
    )
};

export default ProductCard;
