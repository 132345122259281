import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import Link from "next/link";
import useTranslation from "../../../hooks/useTranslation";
import cssVars from "../../../styles/vars.module.scss";
import Carousel from "../../carousel";
import ThemeCard from "../../theme-card";
import BrandCard from "../../brand-card";

const SubCatsWidget = ({ cats, type }) => {

    const rootCat = { ...cats }
    const subCats = cats.items

    if (type === "small") {
        console.log(subCats);
    }

    if (type === "thumbnail") {
        console.log(subCats);
    }

    const { t } = useTranslation()

    const responsive = type === "small" ?
        {
            0: {
                items: 1,
                nav: false,
                margin: 12
            },
            600: {
                items: 2,
                nav: false,
                margin: 17
            },
            992: {
                items: 3,
                nav: false,
                margin: 35
            },
            1200: {
                items: 4,
                nav: true,
                margin: 35
            },
        }
        : {
            0: {
                items: 2,
                nav: false,
                margin: 17
            },
            600: {
                items: 4,
                nav: false,
                margin: 17
            },
            1200: {
                items: 6,
                nav: true,
                margin: 35
            },
        }

    return (
        rootCat && subCats && subCats.length > 0 && type
            ?
            <Box sx={{ my: 7, "&:last-child": { mb: 0 } }}>
                <Grid display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: { xs: "29px", md: "23px" } }}>
                    <Link href={"/" + rootCat.url_path + ".html"}>
                        <Box component="h2" textTransform="uppercase" sx={{ fontSize: { xs: "22px", md: "30px" } }} color={cssVars.extraDarkGray}>{rootCat.title}</Box>
                    </Link>
                    <Link href={"/" + rootCat.url_path + ".html"} style={{ textTransform: "uppercase", border: `1px solid ${cssVars.darkGray}`, borderRadius: cssVars.radius, padding: "4px 11px", color: cssVars.extraDarkGray, fontSize: "14px" }}>{t("More")}</Link>
                </Grid>
                <Box>
                    <Carousel
                        className="owl-theme"
                        dots={true}
                        responsive={responsive}
                    >
                        {
                            subCats?.map(cat =>
                                type === "small" ?
                                    <ThemeCard key={cat.title} cat={cat} />
                                    :
                                    <BrandCard key={cat.title} cat={cat} />
                            )
                        }
                    </Carousel>
                </Box>
            </Box>
            :
            null
    );
}

export default SubCatsWidget;