import { gql } from "@apollo/client";
import { CATEGORY_FRAGMENT } from "./fragments";

export const GET_CAT_CHILDREN = gql`
query categories($ids: String!) {
  categories(filters: { ids: { eq: $ids } }) {
    page_info {
      current_page
      page_size
      total_pages
    }
    items {
      uid
      name
      include_in_menu
      display_mode
      canonical_url
      level
      description
      image
      color
      icon
      url_path
      thumbnail
      show_children_menu
      children {
        uid
        name
        include_in_menu
        display_mode
        canonical_url
        level
        description
        product_count
        image
        color
        icon
        url_path
        thumbnail
        show_children_menu
        children {
          uid
          name
          include_in_menu
          display_mode
          canonical_url
          level
          description
          product_count
          image
          color
          icon
          url_path
          thumbnail
          show_children_menu
        }
      }
    }
  }
}

`;

export const PLP_CATEGORY = gql`
${CATEGORY_FRAGMENT}
query plpCategory($uid: String!){
  categories(filters: { category_uid: { in: [$uid] } }) {
    items {
      uid
      ...CategoryFragment
      __typename
    }
    __typename
  }
}
`;


export const BRANDS_CATEGORIES = gql`
query{
  categories(filters:{url_key:{eq:"brands"}}){
    items{
      uid
      name
      canonical_url
      image
      meta_title
      meta_keywords
      meta_description
      children{
        uid
        name
        canonical_url
        image
        product_count
      }
    }
  }
}
`;

export const BRANDS_MINIMAL = gql`
query{
  categories(filters:{url_key:{eq:"brands"}}){
    items{
      uid
      children{
        name
        canonical_url
      }
    }
  }
}
`;


export const GET_ALL_CAT = gql`
query {
    categories {
        page_info {
            current_page
            page_size
            total_pages
        }
        items {
            uid
            name
            include_in_menu
            display_mode
            canonical_url
            level
            description
            image
            children {
                image
                uid
                name
                include_in_menu
                display_mode
                canonical_url
                level
                description
                product_count
                image
                featured_categorie
                children {
                    uid
                    name
                    include_in_menu
                    display_mode
                    canonical_url
                    level
                    description
                    product_count
                    image
                    featured_categorie
                }
            }
        }}}`;

export const GET_CATEGORY_FILES = gql`
query getCategoryFiles($uid: String!) {
  categories(filters: { category_uid: { eq: $uid } }) {
    items {
      files {
        size
        title
        url
      }
    }
  }
}
`

export const GET_SUGGESTED_CATEGORIES_IDS = gql`
query suggestedCategoriesIds($currentPage: Int, $pageSize: Int, $ids: [String!]) {
  categories(pageSize: $pageSize,currentPage: $currentPage,filters: { ids: { in: $ids } }) {
    page_info {
      current_page
      page_size
      total_pages
    }
    items {
      id
      name
      products {
        total_count
      }     
    }
  }
}`