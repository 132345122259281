import { Box } from "@mui/material";
import cssVars from '../../styles/vars.module.scss'

const PercentOff = ({ product, isVariant, type = "plp" }) => {
    console.log(type);
    console.log(product.sku);

    const percent_off =
        product?.price_range?.maximum_price?.discount.percent_off > 0
            ? product?.price_range?.maximum_price?.discount.percent_off
            : null;


    return (
        percent_off && !isVariant && product.on_sale
            ?
            <Box sx={(type === "plp" ? { position: "absolute", top: 1, left: 0 } : {})} fontWeight={600} fontSize={type === "plp" ? 22 : 16} color={cssVars.red} > -{Math.ceil(percent_off)} %</Box>
            :
            null
    )
}

export default PercentOff;