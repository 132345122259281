
import { useMutation } from "@apollo/client"
import { useEffect } from "react"
import { useAuthStore } from "../../stores/authStore"
import { CREATE_CART } from "../../graphql/cart/mutations"
import { usePaymentStore } from "../../stores/paymentStore"

export const useCart = () => {

	const ready = useAuthStore((state) => state.ready)
	const isAuthenticated = useAuthStore((state) => state.token).length > 0
	const cartId = useAuthStore((state) => state.cartId)
	const setCartId = useAuthStore((state) => state.setCartId)
	const setGuestEmail = useAuthStore(state => state.setGuestEmail)
	const paymentOngoing = usePaymentStore(state => state.paymentOngoing)
	
	const [createCart] = useMutation(CREATE_CART, {
		onCompleted: (data) => {
			if (data.cart_id) {
				setCartId(data.cart_id)
			}
		}
	})


	useEffect(() => {
		console.log(ready);
		console.log(cartId);
		console.log(!paymentOngoing);
		
		if (ready) {
			if ((cartId === "")) {
				if (!paymentOngoing) {
					console.log("yyy");
					
					createCart()
					if (!isAuthenticated) {
						setGuestEmail("false")
					}
				}

			}
		}
	}, [isAuthenticated, cartId, ready])


	return null
}

