import { useContext, useState } from 'react'
import { Avatar, Badge, Box, Button, Chip, CircularProgress, Divider, Grid, IconButton, Skeleton, Switch, TextField } from '@mui/material';
import { CustomerContext } from '../../Providers/CustomerContext';
import useTranslation from '../../hooks/useTranslation';
import cssVars from '../../styles/vars.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import TreeView from '../b2bDashboard/treeView';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { useEffect } from 'react';
import { useAuthStore } from '../../stores/authStore';
import DrawerOrModal from "../DrawerOrModal";

const SelectCompany = ({ open, setOpen, onClick }) => {

    const { customerData, customerDataLoading } = useContext(CustomerContext);
    const currentCompany = useAuthStore(state => state.company);
    const [company, setCompany] = useState('')
    const [data, setData] = useState({})

    const treeData = (companies, parent_company) => {
        const result = [];

        companies
            ?.filter(c => c.parent_company === parent_company && !c.is_default)
            .forEach(company => {
                const companyCopy = { ...company };
                const children = treeData(companies, company.company_code);
                if (children.length > 0) {
                    companyCopy.children = children;
                }
                result.push(companyCopy);
            });

        return result;
    };

    useEffect(() => {
        if (customerData.companies) {
            let d = customerData.companies.filter(c => c.is_default)[0]
            let sons = treeData(customerData.companies, d.company_code)

            if (sons.length) {
                setData({ ...d, children: sons })
                setCompany({ ...d, children: sons })
            } else {
                setData(d)
                setCompany(d)
            }

        }
    }, [customerData])
    const { t } = useTranslation()
    console.log(customerData.companies);
    
    return (
        <DrawerOrModal open={open} setOpen={setOpen} anchor="bottom">
            <Box sx={{ minWidth: { xs: "fit-content", md: 750 }, p: 2, pt: 3 }}>
                <Box
                    sx={{
                        position: 'absolute',
                        left: { xs: 16, md: 28 },
                        top: { xs: 32, md: 16 },
                        right: 60,
                    }}
                    fontSize="20px"
                    fontWeight={500}
                >
                    {t("Company") + " "}
                    (
                    <Box sx={{ fontWeight: 600, display: "inline" }}>
                        {
                            `${currentCompany.company_name}`
                        }
                    </Box>
                    )

                </Box>
                <IconButton
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        display: { xs: "none", md: "block" },
                        right: 16,
                        top: 12,
                        py: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider fullWidth
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: { xs: 80, md: 60 },
                        borderColor: (theme) => theme.palette.grey[500],
                    }}
                />
                <Box textAlign="center" sx={{ mt: 6, textAlign: "left" }}>
                    <TreeView
                        data={data}
                        uid="company_code"
                        nodeRender={(node) => node.company_name}
                        icon={<BusinessOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />}
                        onClick={(node) => setCompany(node)}
                        value={currentCompany.company_code}
                    />
                    <Box sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "space-between", alignItems: "center", pt: 6, px: { xs: 0, sm: 2 } }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            sx={(theme) => {
                                return {
                                    borderRadius: '25px', mr: { xs: 0, sm: 5 }, mb: { xs: 2, sm: 0 },
                                    ":hover": { bgcolor: theme.palette.primary.dark, borderColor: theme.palette.primary.dark, color: "#FFF" }
                                }
                            }}
                            onClick={() => { setOpen(false); }}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            fullWidth
                            sx={(theme) => {
                                return {
                                    borderRadius: '25px',
                                    bgcolor: theme.palette.primary.main, borderColor: theme.palette.primary.main, color: "#FFF",
                                    ":hover": { bgcolor: theme.palette.primary.dark, borderColor: theme.palette.primary.dark, color: "#FFF" }
                                }
                            }}
                            onClick={(e) => {
                                console.log(company);

                                onClick(company)
                                // setOpen(false)
                            }}
                            disabled={customerDataLoading || customerData?.companies?.length === 1 }
                        >
                            {t("Select Company")}
                            {customerDataLoading ? <CircularProgress sx={{ ml: 1, mt: -0.34 }} style={{ width: "15px", height: "15px", color: cssVars.white }} /> : null}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </DrawerOrModal>
    )
}

export default SelectCompany