import { gql } from "@apollo/client";
import { STORE_BLOG_FRAGMENT, STORE_FRAGMENT } from "./fragments";

export const GET_STORE_CONFIG = gql`
${STORE_FRAGMENT}
${STORE_BLOG_FRAGMENT}
query{
  storeConfig {
    ...StoreFragment
    ...StoreBlogFragment
  }
}
`;

export const GET_AVAILABLE_STORE = gql`
query{
  availableStores{
    id
    store_name
    store_code
    base_currency_code
    locale
    is_default_store
  }
}
`;

export const GET_STORE_CMS_BASE_LINKS = gql`
query StoreConfigCmsBaseLinks { 
    baseLinks: storeConfig {
        cms_home_page
        cms_no_cookies
        cms_no_route
    }
}
`;