import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT, CUSTOMER_FRAGMENT, CUSTOMER_ORDERS_FRAGMENT } from "./fragments";

export const GET_CUSTOMER = gql`
${CUSTOMER_FRAGMENT}
query customer {
  customer {
    ...CustomerFragment
  }
}
`
export const GET_CUSTOMER_ADDRESSES = gql`
${ADDRESS_FRAGMENT}
query customer {
   getCustomerAddresses: customer {
    addresses {
      ...AddressFragment
    }
  }
}
`;

export const SHARED_WISHLIST_CUSTOMER_LIST = gql`
query sharedWishlistCustomerList($wishlistId: String!) {
  getCustomerWishList (wishlistId: $wishlistId ) {
    shared_customers
  }
}
`;

export const GET_CUSTOMER_ORDER_DETAILS = gql`
query getCustomerOrderDetails($orderNumber: String!) {
  customer {
    orders(filter: { number: { eq: $orderNumber } }) {
      items {
        id
        order_date
        number
        status
        billing_address {
          firstname
          lastname
          city
          company
          country_code
          postcode
          telephone
          street
          region
        }
        shipping_address {
          firstname
          lastname
          city
          company
          country_code
          postcode
          telephone
          street
          region
        }
        payment_methods {
          name
        }
        files {
          title
          url
          __typename
        }
        total {
          grand_total {
            value
            __typename
          }
          __typename
        }
        invoices {
          id
          files {
            title
            url
            __typename
          }
          total {
            grand_total {
              value
            }
            subtotal {
              value
            }
            taxes {
              amount {
                value
              }
            }
            total_tax {
              value
            }
            total_shipping {
              value
            }
          }
          items {
            product_name
            product_sku
            product_sale_price {
              value
            }
            quantity_invoiced
          }
        }
        shipments {
          id
          files {
            title
            url
            __typename
          }
          tracking {
            number
          }
          items {
            product_name
            product_sku
            quantity_shipped
          }
        }
        total {
          grand_total {
            value
          }
          subtotal {
            value
          }
          taxes {
            amount {
              value
            }
          }
          total_tax {
            value
          }
          total_shipping {
            value
          }
          discounts {
            amount {
              value
            }
          }
        }
        items {
          id
          product_url_key
          product_name
          product_sku
          product_sale_price {
            value
          }
          product_small_image {
            url
            label
          }
          quantity_ordered
        }
        __typename
      }
    }
  }
}
`

export const COMPANY_CUSTOMERS = gql`
query company_customer{
    company_customer {
        is_disabled
        firstname
        email
        gender
        telephone
        lastname
        company
        is_company
        itm_cardcode
        bp_channel_code
        role
        id
    }
}
`
export const CUSTOMER_ORDERS = gql`
${CUSTOMER_ORDERS_FRAGMENT}
query customer($currentPage: Int, $pageSize: Int) {
  customer {
  ...CustomerOrdersFragment
  }
}
`

export const GET_COMPANY_ORDERS = gql`
query companyOrders($currentPage: Int, $pageSize: Int){
    customer {
        orders:company_orders(currentPage: $currentPage, pageSize: $pageSize) {
            page_info {
                total_pages
                current_page
                page_size
            }
            items {
                id
                order_date
                itm_cardcode
                number
                status
                files {
                title
                url
                __typename
                }
                total {
                  grand_total {
                    value
                  }
                  subtotal {
                    value
                  }
                  taxes {
                    amount {
                      value
                    }
                  }
                  total_tax {
                    value
                  }
                  total_shipping {
                    value
                  }
                  discounts {
                    amount {
                      value
                    }
                  }
                }
                billing_address {
                    firstname
                    lastname
                    city
                    company
                    country_code
                    postcode
                    telephone
                    street    
                }
                shipping_address {
                    firstname
                    lastname
                    city
                    company
                    country_code
                    postcode
                    telephone
                    street
                }
                payment_methods {
                  name
                }
                invoices {
                  files {
                      title
                      url
                      __typename
                  }
                  total {
                      total_tax {
                        value
                      }
                      discounts {
                        amount {
                          value
                        }
                      }
                      grand_total {
                        value
                        currency
                      }
                      subtotal {
                        value
                        currency
                      }
                      taxes {
                      amount {
                          currency
                          value
                      }
                      }
                      total_shipping {
                      value
                      currency
                      }
                  }
                  items {
                      product_name
                      product_sku
                      product_sale_price {
                      value
                      currency
                      }
                      quantity_invoiced
                  }
                }
                shipments {
                    files {
                        title
                        url
                        __typename
                    }
                    tracking{
                        number
                    }
                    items {
                        product_name
                        product_sku
                        quantity_shipped
                    }
                }
                total {
                    grand_total {
                        value
                        currency
                    }
                    subtotal {
                        value
                        currency
                    }
                    taxes {
                        amount {
                        currency
                        value
                        }
                    }
                    total_shipping {
                        value
                        currency
                    }
                }
                items {
                    product_small_image{
                        url
                        label
                    }
                    product_url_key
                    product_name
                    product_sku
                    product_sale_price {
                        value
                        currency
                    }
                    quantity_ordered
                }

            }

        }
    }
}
`
