/* eslint-disable @next/next/no-img-element */

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Box, List, ListItem, Skeleton } from "@mui/material";
import cssVars from "../../styles/vars.module.scss"
import Link from "next/link";
import { GET_CAT_CHILDREN } from "../../graphql/categories/queries";
import { useCacheStore } from "../../stores/cacheStore";
import MenuCard from "./MenuCard";

const Navbar = () => {
    const headerCats = useCacheStore(s => s.navbarCategories)
    const setHeaderCats = useCacheStore(s => s.setNavbarCategories)
    const cacheReady = useCacheStore(s => s.ready)

    const { data } = useQuery(GET_CAT_CHILDREN, { variables: { ids: "2" }, skip: (headerCats || !cacheReady), context: { fetchOptions: { method: "GET" } } })
    console.log(data);
    const [subCats, setSubCats] = useState([])
    const [subMenuColor, setSubMenuColor] = useState()


    useEffect(() => {
        if (data) {
            const temp = data.categories.items[0].children.filter(cat => cat.include_in_menu)
            setHeaderCats(temp)
        }
    }, [data])

    const [isOpen, setIsOpen] = useState(false)

    const openMenu = (e, cat) => {
        if (cat.children.length > 0 && cat.show_children_menu) {
            const temp = cat.children.filter(cat => cat.include_in_menu).slice(0, 18)
            setSubCats(temp)
            setSubMenuColor(cat.color)
            setIsOpen(true)
        }
        else {
            setSubCats([])
            setSubMenuColor(null)
            setIsOpen(false)
        }
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    return (
        <Box className="container" component="nav" position="relative" display={{ xs: "none", lg: "block" }} pt={2} onMouseLeave={(e) => { closeMenu(e) }}>
            <List sx={{ display: "flex", alignItems: "center" }}>
                {
                    headerCats?.length > 0
                        ?
                        headerCats.map(cat =>
                            <ListItem key={cat.uid} sx={{ display: "inline-block", p: 0, "& a": { p: 1, borderBottom: `3px solid ${cat.color}`, display: "inline-block", width: "100%", textAlign: "center", fontSize: "18px", textTransform: "uppercase", fontWeight: 500, "& img": { mr: "10px", "@media(max-width:1399px)": { display: "none" } } } }}>
                                <Link href={`/${cat.url_path}.html`} onMouseEnter={(e) => { openMenu(e, cat) }} >
                                    <img styl src={process.env.NEXT_PUBLIC_BASE_URL + cat.icon} alt="menu-icon" /> {cat.name}</Link></ListItem>
                        )
                        :
                        Array.from({ length: 6 }, (_, i) => {

                            return <ListItem key={i} style={{ display: "inline-block", borderBottom: `3px solid ${cssVars.lightGray}` }}>
                                <Skeleton variant="rectangular" width={100} height={22} />
                            </ListItem>
                        })
                }
            </List>
            <Box position="absolute" bgcolor="#fff" className={isOpen ? "show" : ""} id="navDropDownMenu" sx={{ left: 12, right: 12, border: `3px solid ${subMenuColor}`, borderTop: "none", zIndex: 512, display: "none", "&.show": { display: "block" } }}>
                <Box display="flex" flexWrap="wrap" className="menu-items">
                    {
                        subCats.map((cat) =>
                            <MenuCard key={cat.uid} link={`/${cat.url_path}.html`} title={cat.name} img={cat.thumbnail ? process.env.NEXT_PUBLIC_BASE_URL + cat.thumbnail : null} count={cat.product_count} className="forMenu" />
                        )
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Navbar;