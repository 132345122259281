import { useEffect, createContext, useState, useMemo, useCallback } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { MULTI_WISHLISTS } from "../graphql/multi-wishlist/queries";
import { ADD_PRODUCT_TO_CUSTOM_WISH_LIST, CREATE_WISHLIST, REMOVE_CUSTOMER_WISHLIST, REMOVE_PRODUCT_FROM_CUSTOM_WISHLIST, UPDATE_CUSTOM_WISHLIST } from "../graphql/multi-wishlist/mutations";
import { useAuthStore } from "../stores/authStore";
import { useGlobalStore } from "../stores/globalStore";
import { useRouter } from "next/router";
import parseSearchParams from "../helpers/SearchParams";

export const MultiWishListContext = createContext({})

export const MultiWishListProvider = ({ children }) => {
    const [skip, setSkip] = useState(true)
    const isAuthenticated = useAuthStore(state => state.token?.length > 0)
    const [wishLists, setWishLists] = useState([])
    const [wishListsSku, setWishListsSku] = useState([])
    const [ready, setReady] = useState(false)
    const router = useRouter()
    const searchParams = parseSearchParams(router)

    const variables = { currentPage: searchParams.page || 1, pageSize: 12 }

    const { data } = useQuery(MULTI_WISHLISTS, { variables: variables, skip: skip, nextFetchPolicy: "cache-only" })

    useEffect(() => {
        if (isAuthenticated) {
            setSkip(false);
        } else {
            setWishLists([])
            setWishListsSku([])
        }
    }, [isAuthenticated])

    const setWishListData = (data) => {
        console.log(data.items.map((item) => item.item_collection.items.map((subItem) => subItem.sku)).flat(1));
        setWishLists(data || [])
        setWishListsSku(data.items.map((item) => item.item_collection.items.map((subItem) => subItem.sku)).flat(1))
    }

    useEffect(() => {
        if (data) {            
            setWishListData(data.wishlists)
            setSkip(true)
            setReady(true)
        }
    }, [data])

   
    console.log(data?.items);
    
    const [addProductToWishlist, { loading: addProductToWishlistLoading, data: addData }] = useMutation(
        ADD_PRODUCT_TO_CUSTOM_WISH_LIST
    )

    const [removeProductFromWishlist, { loading: removeProductFromWishlistLoading, data: removeData }] = useMutation(REMOVE_PRODUCT_FROM_CUSTOM_WISHLIST)

    const [createWishlist, { loading: createWishListLoading, data: createData }] = useMutation(CREATE_WISHLIST)

    const [deleteWishList, { loading: deleteWishListLoading, data: deleteData }] = useMutation(REMOVE_CUSTOMER_WISHLIST)

    const [updateNewWishList, { loading: updateWishListLoading, data: updateData }] = useMutation(UPDATE_CUSTOM_WISHLIST)

    useEffect(() => {
        if (addData) {
            setWishListData(addData.wishlists)
        }
    }, [addData])

    useEffect(() => {
        if (removeData) {
            setWishListData(removeData.wishlists)
        }
    }, [removeData])

    useEffect(() => {
        if (createData) {
            setWishListData(createData.wishlists)
        }
    }, [createData])

    useEffect(() => {
        if (deleteData) {
            setWishListData(deleteData.wishlists)
        }
    }, [deleteData])

    useEffect(() => {
        if (updateData) {
            setWishListData(updateData.wishlists)
        }
    }, [updateData])

    const isFav = (sku = "", wishListId) => {
        if (wishListId)
            return wishLists.items.filter(item => item.wishlist_id === wishListId)[0]?.item_collection.items.filter(item => item.sku === sku).length > 0
        else
            return wishListsSku.includes(sku)
    }

    const favReady = () => { return ready }

    const openListsDrawer = useGlobalStore((state) => state.openListsDrawer)
    const setListDrawerSku = useGlobalStore((state) => state.setListDrawerSku)

    const handleClickMultiList = useCallback((sku) => {
        if (isAuthenticated) {
            openListsDrawer()
            setListDrawerSku(sku)
        }
        else {
            router.push(`/customer/login.html?redirect=${router.asPath}`);
        }
    }, [isAuthenticated])

    const contextValue = useMemo(() => ({
        favReady,
        wishLists,
        isFav,
        wishListsSku,
        createWishlist,
        addProductToWishlist,
        removeProductFromWishlist,
        deleteWishList,
        updateNewWishList,
        addProductToWishlistLoading,
        removeProductFromWishlistLoading,
        createWishListLoading,
        deleteWishListLoading,
        updateWishListLoading,
        wishListLoading: !skip,
        addRemoveLoading: addProductToWishlistLoading || removeProductFromWishlistLoading,
        handleClickMultiList
    }), [
        favReady,
        wishLists,
        isFav,
        wishListsSku,
        createWishlist,
        addProductToWishlist,
        removeProductFromWishlist,
        deleteWishList,
        updateNewWishList,
        addProductToWishlistLoading,
        removeProductFromWishlistLoading,
        createWishListLoading,
        deleteWishListLoading,
        updateWishListLoading,
        skip,
        addProductToWishlistLoading,
        removeProductFromWishlistLoading,
        handleClickMultiList
    ])

    return (
        <MultiWishListContext.Provider value={contextValue} >
            {children}
        </MultiWishListContext.Provider>
    )
}