import { create } from 'zustand';
import Cookies from 'js-cookie';
import { createJSONStorage, persist } from 'zustand/middleware';
import { cookieStorage } from './cookieStorage';
import { getClient } from "../Providers/graphqlClient"
import { gql } from '@apollo/client';

interface CurrencyStoreType {
    currency: string
    startup: () => Promise<void>
};

const currencyQuery = gql`
query {
    currency {
        default_display_currency_symbol
    }
}
`

const getCurrency = async (): Promise<string> => {
    const client1 = getClient('fr')
    const { data } = await client1.query(
        {
            query: currencyQuery
        })
    if (data?.currency?.default_display_currency_symbol) {
        return data.currency.default_display_currency_symbol
    }
    else
        return "CHF"
}


export const useCurrencyStore = create<CurrencyStoreType>()(
    persist((set) => ({
        currency: "CHF",
        startup: async () => {

            const currency = await getCurrency()
            set(() => ({ currency }));
        }
    }),
        {
            onRehydrateStorage: (state) => {
                // optional
                return (state, error) => {
                    if (error) {
                        console.log("an error happened during hydration", error);
                    } else {
                        state?.startup()
                    }
                };
            },

            name: 'currency',
            storage: createJSONStorage(() => cookieStorage),
            partialize: (state) => ({ currency: state.currency }),
        }
    )
);
