import { Box } from "@mui/material";

const Tag = ({ text, color, show }) => {

    const tagStyle = {
        fontSize: '11px',
        fontWeight: 700,
        borderRadius: '18px',
        display: 'block',
        backgroundColor: '#fff',
        padding: '4px 10px',
        marginBottom: '6px',
        textAlign: 'center',
        textTransform: 'uppercase',
        minWidth: '88px',
        position: 'static',
        color: color,
        border: `1px solid ${color}`
    }

    return (
        show ? <Box sx={tagStyle} className="roboto" component="span">{text}</Box> : null
    );
}

export default Tag;