import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { cookieStorage } from './cookieStorage';



/**
 * Interface representing a company.
 */
interface Company {
    company_code: string;
    company_id: number;
    company_name: string;
    parent_company: string;
    role: string;
}

const companyInitialState: Company = {
    company_code: "",
    company_id: -1,
    company_name: "",
    parent_company: "",
    role: ""
}

/**
 * Interface representing the authentication store.
 */
interface AuthStoreType {
    token: string;
    signIn: (token: string) => void;
    signOut: () => void;

    guestEmail: string;
    setGuestEmail: (guestEmail: string) => void;

    ready: boolean;
    setReady: (status: boolean) => void;

    compareListId: string;
    setCompareListId: (compareListId: string) => void;

    cartId: string;
    setCartId: (cartId: string) => void;

    loginDone: boolean;
    setLoginDone: (loginDone: boolean) => void;

    company: Company;
    setCompany: (company: Company) => void;
}

/**
 * Zustand store for managing authentication state.
 */
export const useAuthStore = create<AuthStoreType>()(
    persist(
        (set, get) => ({
            // Authentication token
            token: "",
            signIn: (token: string) => {
                set(() => ({ token }));
            },
            signOut: () => {
                set(() => ({ token: '', cartId: "", loginDone: false, company: companyInitialState }));
            },

            // Guest email
            guestEmail: 'false',
            setGuestEmail: (guestEmail: string) => {
                set(() => ({ guestEmail }));
            },

            // Ready status
            ready: false,
            setReady: (status: boolean) => {
                set(() => ({ ready: status }));
            },

            // Compare list ID
            compareListId: "",
            setCompareListId: (compareListId: string) => {
                set(() => ({ compareListId }));
            },

            // Cart ID
            cartId: "",
            setCartId: (cartId: string) => {
                set(() => ({ cartId }));
            },

            // Login status
            loginDone: false,
            setLoginDone: (loginDone: boolean) => {
                set(() => ({ loginDone }));
            },

            // Company information
            company: companyInitialState,
            setCompany: (company: Company) => {
                set(() => ({ company }));
            },
        }),
        {
            name: 'auth-store',
            storage: createJSONStorage(() => cookieStorage),
            partialize: (state) => ({
                token: state.token,
                guestEmail: state.guestEmail,
                compareListId: state.compareListId,
                cartId: state.cartId,
                loginDone: state.loginDone,
                company: state.company
            }),
            onRehydrateStorage: (state) => {
                return (state, error) => {
                    if (error) {
                        console.log("An error occurred during hydration of the auth store", error);
                    } else {
                        state?.setReady(true);
                    }
                };
            }
        }
    )
);