import { gql } from "@apollo/client";
import { MULTI_WISHLIST_FRAGMENT } from "./fragments";

export const MULTI_WISHLISTS = gql`
${MULTI_WISHLIST_FRAGMENT}
query wishlists($pageSize:Int,$currentPage:Int){
  wishlists:customerWishlist(pageSize:$pageSize,currentPage:$currentPage) {
    ...WishlistsFragment
  }
}
`;
