import zipCodes from '../zip_codes.json'

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const validatePassword = (password) => {
    return String(password).length >= 8
};

const validateConfirmPassword = (password, confirmPassword) => {
    return String(password) === String(confirmPassword)
};

const validateCurrentAndNewPassword = (currentPassword, newPassword) => {
    return String(currentPassword) !== String(newPassword)
};

const getPostCodeList = (countryCode) => {
    return zipCodes.config.zip.filter((item) => item.countryCode === countryCode)[0]
}

const validatePostCode = (postCode, countryCode) => {
    const postLists = getPostCodeList(countryCode)
    return Array.isArray(postLists?.codes?.code) ? postLists?.codes?.code?.filter((item) => new RegExp(item['#text']).test(postCode)).length > 0 : new RegExp(postLists?.codes?.code['#text']).test(postCode)
}

export { validateEmail, validatePostCode, validatePassword, validateConfirmPassword, validateCurrentAndNewPassword };