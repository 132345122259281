import { useEffect, createContext, useState, useMemo, useCallback } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PRODUCT_TO_WISH_LIST, REMOVE_PRODUCT_FROM_WISHLIST, UPDATE_WISHLIST } from "../graphql/wishlist/mutations";
import { GET_WISHLIST } from "../graphql/wishlist/queries";
import { useRouter } from "next/router";
import useTranslation from "../hooks/useTranslation";
import { useSnackbar } from "notistack";
import parseSearchParams from "../helpers/SearchParams";
import { useAuthStore } from "../stores/authStore";

export const WishListContext = createContext({})

export const WishListProvider = ({ children }) => {
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const [skip, setSkip] = useState(true)
    const isAuthenticated = useAuthStore((state) => state.token).length > 0

    const [wishList, setWishList] = useState({ items_count: 0, items_v2: { items: [] } })

    const { data } = useQuery(GET_WISHLIST, { variables: { currentPage: searchParams.page || 1, pageSize: 16 }, skip: skip })


    const [addRemoveLoading, setAddRemoveLoading] = useState([])

    useEffect(() => {
        if (isAuthenticated) {
            setSkip(false);
        } else {
            setWishList({ items_count: 0, items_v2: { items: [] } })
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (data) {
            setWishListData(data.customer.wishlists[0])
            setSkip(true)
        }
    }, [data])

    const setWishListData = (data) => {
        setWishList(data || { items_count: 0, items_v2: { items: [] } })
        let skus = []
        data.items_v2.items.map((item) =>
            skus.push(item.product.sku)
        )
    }

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [addProductToWishlist, { loading: addProductToWishlistLoading, data: addData }] = useMutation(ADD_PRODUCT_TO_WISH_LIST)

    const [removeProductFromWishlist, { loading: removeProductFromWishlistLoading, data: removeData }] = useMutation(REMOVE_PRODUCT_FROM_WISHLIST)

    const [updateNewWishList, { loading: updateWishListLoading, data: updateData }] = useMutation(UPDATE_WISHLIST)

    useEffect(() => {
        if (addData) {
            setWishListData(addData.action.wishlist)
        }
    }, [addData])

    useEffect(() => {
        if (removeData) {
            setWishListData(removeData.action.wishlist)
        }
    }, [removeData])

    useEffect(() => {
        if (updateData) {
            setWishListData(updateData.action.wishlist)
        }
    }, [updateData])

    const isFav = (sku = "", selected_options) => {
        return findItemsInWishlist(sku, selected_options).length > 0
    }

    const findItemsInWishlist = (sku, selected_options) => {
        return wishList.items_v2.items.filter(item => {
            if (item.product.sku === sku) {
                if (selected_options && item.configurable_options) {
                    return item.configurable_options.every(option => selected_options.includes(option.configurable_product_option_value_uid))
                }
                else {
                    return true
                }
            }
            else {
                return false
            }
        })
    }

    const handleClick = useCallback((sku, selected_options) => {
        if (isAuthenticated) {
            setAddRemoveLoading([...addRemoveLoading, `${sku}-${selected_options?.join("-")}`])
            if (isFav(sku, selected_options)) {
                const itemId = findItemsInWishlist(sku, selected_options).at(0).id;
                removeProductFromWishlist({
                    variables: { wishlistId: wishList.id, id: itemId },
                    onCompleted: (data) => {
                        setAddRemoveLoading(addRemoveLoading.filter(s => s !== `${sku}-${selected_options?.join("-")}`))
                        if (data) {
                            enqueueSnackbar(t("Product has been removed successfully."), { variant: 'success' });
                        } else {
                            enqueueSnackbar(t("Something went wrong. Please try again."), { variant: 'error' })
                        }
                    },
                    onError: (err) => {
                        setAddRemoveLoading([])
                        enqueueSnackbar(err.message, { variant: 'error' })
                    }
                })
            }
            else {
                addProductToWishlist({
                    variables: { wishlistId: wishList.id, sku, selected_options },
                    onCompleted: (data) => {
                        setAddRemoveLoading(addRemoveLoading.filter(s => s !== `${sku}-${selected_options?.join("-")}`))
                        if (data) {
                            enqueueSnackbar(t("Product has been added successfully."), { variant: 'success' });
                        } else {
                            enqueueSnackbar(t("Something went wrong. Please try again."), { variant: 'error' })
                        }
                    },
                    onError: (err) => {
                        setAddRemoveLoading([])
                        enqueueSnackbar(err.message, { variant: 'error' })
                    }
                })
            }
        }
        else {
            router.push(`/customer/login.html?redirect=${router.asPath}`);
        }
    }, [wishList, addRemoveLoading, isAuthenticated])

    useEffect(() => {
        setAddRemoveLoading([])
    }, [wishList])

    const contextValue = useMemo(() => ({
        wishList,
        isFav,
        handleClick,
        addRemoveLoading,
        updateWishListLoading,
        wishListLoading: !skip
    }), [
        wishList,
        isFav,
        handleClick,
        addRemoveLoading,
        updateWishListLoading,
        skip
    ])

    return (
        <WishListContext.Provider value={contextValue} >
            {children}
        </WishListContext.Provider>
    )
}