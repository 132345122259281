import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { cookieStorage } from './cookieStorage';

interface CatStoreType {
    catPath: string | null
    setCatPath: (catPath: string) => void
};



export const useCatStore = create<CatStoreType>()(
    persist((set) => ({
        catPath: null,
        setCatPath: (catPath: string) => {
            set(() => ({ catPath }));
        }
    }),
        {
            name: 'category-store',
            storage: createJSONStorage(() => cookieStorage),
        }
    )
);
