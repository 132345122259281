import { gql } from "@apollo/client";
import { BUNDLE_PRODUCT_FRAGMENT, CONFIGUREABLE_PRODUCT_FRAGMENT, PRODUCT_FRAGMENT, RELATED_PRODUCTS_FRAGMENT } from "./fragments";

// plp
export const GET_PLP_PRODUCTS = gql`
${PRODUCT_FRAGMENT}
${BUNDLE_PRODUCT_FRAGMENT}
query GetCategories(
  $pageSize: Int!
  $currentPage: Int!
  $filters: ProductAttributeFilterInput!
  $sort: ProductAttributeSortInput
) {
  products(
    pageSize: $pageSize
    currentPage: $currentPage
    filter: $filters
    sort: $sort
  ) {
    items {
      ...ProductFragment
      ...BundleProductFragment
    }
    page_info {
      total_pages
      page_size
      current_page
      __typename
    }
    sort_fields {
      default
      options {
        label
        value
      }
    }
    total_count
  }
}
`;

export const GET_FILTERS = gql`
query getFilters($id: String!) {
  products(filter: { category_id: { eq: $id } }) {
    total_count
    aggregations {
      attribute_code
      count
      label
      position
      options {
        count
        label
        value
      }
    }
  }
}

`;

export const GET_SORT_FIELDS = gql`
query getSortFields($id: String!){
  products(filter: { category_id: { eq: $id } }){
    sort_fields {
      default
      options {
        label
        value
        sorting_direction
      }
    }
  }
}
`

// pp
export const PPQ = gql`
${PRODUCT_FRAGMENT}
${BUNDLE_PRODUCT_FRAGMENT}
query getProduct($url:String!){
    products(filter: { url_key: { eq: $url } }) {
        items {
            ...ProductFragment
            ...BundleProductFragment          
        }
    }
}
`

export const PPQC = gql`
${CONFIGUREABLE_PRODUCT_FRAGMENT}
query getProduct($url:String!){
    products(filter: { url_key: { eq: $url } }) {
        items {
            ...ConfigurableProductFragment          
        }
    }
}
`

export const RELATED_PRODUCTS = gql`
${RELATED_PRODUCTS_FRAGMENT}
query getProduct($url:String!){
    products(filter: { url_key: { eq: $url } }) {
        items {
            ...RelatedProductsFragment          
        }
    }
}
`

// homepage custom categories carousel products

export const GET_PRODUCT_LIST_BY_ID = gql`
${PRODUCT_FRAGMENT}
${BUNDLE_PRODUCT_FRAGMENT}
query products(
  $id: String!
  $pageSize: Int!
  $currentPage: Int!
  ){
  products(
    filter:{category_id:{eq:$id}}
    pageSize:$pageSize 
    currentPage: $currentPage
    ){
    items {
      ...ProductFragment
      ...BundleProductFragment
    }
    page_info {
      total_pages
      page_size
      current_page
      __typename
    }
    sort_fields {
      default
      options {
        label
        value
      }
    }
    total_count
  }
}
`;

// rating meta data in PP
export const GET_PRODUCT_RATING_METADATA = gql`
query {
  productReviewRatingsMetadata {
    items {
      id
      name
      values {
        value_id
        value
      }
    }
  }
}
`

export const GET_PRODUCT_BY_SKU = gql`
${PRODUCT_FRAGMENT}
${BUNDLE_PRODUCT_FRAGMENT}
query getProductBySku($sku: [String]) {
  products(filter:{sku:{in: $sku}}) { 
    items {
      ...ProductFragment
      ...BundleProductFragment
    }
  }
}
`;