
import React from 'react';
import createJSX from './createJSX';
import { convertAttributesToJSX } from './helper';
import { Box, Grid } from '@mui/material';
import { parse } from 'node-html-parser';

const CmsParser = (domElement) => {
    const tagName = domElement.tagName?.toLowerCase();
    const attributes = convertAttributesToJSX(domElement.attributes);
    const children = domElement.childNodes.map((childNode, i) => {
        if (childNode.nodeType === 3) {
            return childNode.textContent;
        } else if (childNode.nodeType === 1) {
            return CmsParser(childNode);
        }
        return null;
    });

    return createJSX(tagName, attributes, children)
};

const CmsPageParser = ({ id, children, sx = {}, useContainer = true }) => {
    if (!children) {
        return null
    }

    const dom = parse(children, {
        lowerCaseTagName: false,
        script: true,
        style: true,
        pre: false
    });
    return (
        useContainer ?
            <Box className="container">
                <Grid id={id} sx={sx}>
                    {dom ? CmsParser(dom) : null}
                </Grid>
            </Box>
            :
            <Grid id={id} sx={sx}>
                {dom ? CmsParser(dom) : null}
            </Grid>
    )
};

export { CmsPageParser, CmsParser };