import { gql } from "@apollo/client";

export const CATEGORY_FRAGMENT = gql`
fragment CategoryFragment on CategoryTree {
  uid
  image
  # banner_image
  description
  name
  meta_title
  meta_keywords
  meta_description
  url_path
  product_count
  thumbnail
  # hide_banner
  # hide_sorting
  # hide_layered_navigation
  cms_block{
    content
    identifier
    title
  }
  breadcrumbs {
    category_name
    category_url_path
    category_level
  }
  children{
    name
    url_path
    product_count
    thumbnail
  }
  __typename
}
`
