
import { useLazyQuery, useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import { useContext, useEffect, useRef, useState } from "react"
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";
import { ClickAwayListener, Container, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Popper } from "@mui/material"
import TopBar from "./topbar"
import styles from '../../styles/header.module.scss'
import Logo from "../logo"
import cssVars from "../../styles/vars.module.scss"
import SearchIcon from '@mui/icons-material/Search';
import QuickLinks from "./quicklinks"
import Navbar from "./navbar"
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from "./menuDrawer"
import { GET_AUTO_COMPLETE_RESULTS } from "../../graphql/search/queries";

import SearchRes from "../search/searchRes";
import { SearchContext } from "../../Providers/SearchProvider";
import useTranslation from "../../hooks/useTranslation";
import { Box } from "@mui/material";

const Header = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onNavigate = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const srchRef = useRef()


    const router = useRouter()

    const { search, setSearch } = useContext(SearchContext)

    const { t } = useTranslation()


    return (
        <Box component="header" className={styles.header} >
            <TopBar />
            <Box className="container" sx={{ my: { xs: 1, lg: 0 }, pt: { xs: "6px", lg: 0 } }} >
                <Grid display={{ xs: "block", lg: "flex" }} alignItems="center">
                    <Grid display="flex" justifyContent={{ xs: "center", lg: "start" }} mr={{ xs: "auto", lg: 3 }}>
                        <Logo />
                    </Grid>
                    <Grid mt="20px" mx={{ xs: "auto", lg: 5 }} width={`100% !important`} maxWidth={{ xs: "80%", lg: 700 }} position="relative">
                        <ClickAwayListener onClickAway={(e) => {
                            if (!e?.target?.classList?.contains("searchable-spec")) {
                                setAnchorEl(null)
                            }
                        }}>
                            <Box>
                                <Box boxShadow="0 20px 18px -4px rgba(44,44,44,.08),0 8px 8px -4px rgba(44,44,44,.04)" borderRadius={cssVars.roundedRadius}>
                                    <FormControl ref={srchRef} id="mainSearch" fullWidth variant="standard" aria-describedby={id} onFocus={handleClick}
                                        sx={{ "& fieldset, .Mui-focused fieldset": { borderColor: `${cssVars.darkGray} !important` } }}>
                                        <OutlinedInput
                                            type="text"
                                            sx={{ borderRadius: cssVars.roundedRadius, paddingLeft: 0 }}
                                            placeholder={t("Search")}
                                            size="small"
                                            value={search} onChange={(e) => { setSearch(e.target.value) }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    onNavigate()
                                                    router.push(`/catalogsearch/result/${strEncode('{"q":"' + search + '"}')}`)
                                                }
                                            }}
                                            startAdornment={
                                                <InputAdornment position="end" sx={{ color: cssVars.primary, pr: "16px" }}>
                                                    <IconButton edge="end" aria-label="Search">
                                                        <SearchIcon sx={{ color: cssVars.primary, fontSize: "30px" }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                                <Popper
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    sx={{
                                        width: srchRef.current?.offsetWidth,
                                        boxShadow: '0px 3px 20px #00000029',
                                        zIndex: '4096',
                                        background: "#ffffff",
                                        borderRadius: "30px"
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <SearchRes onNavigate={onNavigate} />
                                </Popper>
                            </Box>
                        </ClickAwayListener>
                    </Grid>

                    <Grid mt="20px" id="quick-links-column" display="flex" justifyContent={{ xs: "center", lg: "end" }} alignItems="center" sx={{ minWidth: { lg: "fit-content" } }}>
                        <QuickLinks />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <Navbar />
            </Box>
        </Box >
    )
}

export default Header;