import React from 'react';
import useAddZeroes from "../../../hooks/useAddZeroes";
import useTranslation from '../../../hooks/useTranslation';
import { Grid, Typography, Box } from '@mui/material';

const TierPrices = ({ product }) => {
    const { t } = useTranslation();
    const { z } = useAddZeroes();

    return (
        product?.price_tiers?.length > 0 && (
            <Box my={3}>
                <Grid container textTransform="capitalize">
                    <Grid item xs={3}>
                        <Typography align="left">{t("Quantity")}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography align="right">{t("for")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="right">{t("discount")}</Typography>
                    </Grid>
                </Grid>
                <Box>
                    {product.price_tiers.map((p, i) => (
                        <Grid container key={i}>
                            <Grid item xs={3}>
                                <Typography align="left">{p.quantity}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="right">
                                    {p.final_price.currency} {z(p.final_price.value)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography align="right" color="error">
                                    {p.discount.percent_off}%
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
        )
    );
}

export default TierPrices;