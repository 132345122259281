import { Box, Grid, ListItem, Skeleton } from "@mui/material";

const CartItemSkeleton = ({ variant }) => {
    return (
        <ListItem disablePadding sx={{ borderBottom: `1px solid #E5E5E7`, py: { xs: "18px", sm: "23px" } }}>
            <Grid container columns={5} columnSpacing="70px">
                <Grid item xs={5} lg={3} display="flex" alignItems="center">
                    <Skeleton variant="rectangular" width={97} height={80} />
                    <Box className="details" width="100%" fontSize={16} ml={{ xs: "10px", lg: 3 }}>
                        <Skeleton variant="text" width="30%" height={21} />
                        <Skeleton variant="text" width="80%" height={24} sx={{ my: 0.5 }} />
                        <Skeleton variant="text" width="20%" height={19} />
                    </Box>
                </Grid>
                <Grid item xs={5} lg={2} mt={{ xs: "20px", lg: 0 }} display="flex" alignItems="center" justifyContent="space-between">
                    {
                        variant !== "mini-cart"
                            ?
                            <Box display="flex" alignItems="center" mr="30px">
                                <Skeleton variant="rectangular" width={26} height={26} sx={{ ml: "8px", mr: "38px" }} />
                                <Skeleton variant="rectangular" width={138} height={44} />
                            </Box>
                            :
                            <Box></Box>
                    }
                    <Box>
                        <Skeleton variant="text" height={30} width={90} />
                    </Box>
                </Grid>
            </Grid>

        </ListItem>
    );
}

export default CartItemSkeleton;