import React from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import useTranslation from '../../hooks/useTranslation';
import { useGlobalStore } from '../../stores/globalStore';
import { Box } from '@mui/material';
import cssVars from '../../styles/vars.module.scss'
import PriceBox from '../price-box';
import { useSnackbar } from 'notistack';
import { useCartActions } from "../../hooks/cart/useCartActions";
import { useAuthStore } from '../../stores/authStore';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { useRouter } from 'next/router';
import TierPrices from '../pdp/tier-prices';
import { useMediaQuery } from '@mui/material';
import parseSearchParams from '../../helpers/SearchParams';

const ProductCardFooter = ({ product, isVariant, isCalibration }) => {
    const { t } = useTranslation((state) => state.translate)
    const router = useRouter()
    const setLoading = useGlobalStore(s => s.setFullPageLoading)
    const openMiniCart = useGlobalStore(s => s.openMiniCart)
    const { addProductsToCart, disabled: cartDisabled, addBundleProductsToCart } = useCartActions()
    const cartId = useAuthStore(state => state.cartId);
    const cartReady = !cartDisabled
    const { enqueueSnackbar } = useSnackbar()

    const setOpenCalibrationModal = useGlobalStore(s => s.setOpenCalibrationModal)
    const setCalibrationProduct = useGlobalStore(s => s.setCalibrationProduct)

    const searchParams = parseSearchParams(router)
    const isCalibrationSelected = searchParams?.filters?.brand && searchParams?.filters?.model

    const addToCart = (e, product) => {
        e.preventDefault();
        e.stopPropagation();

        if (cartReady) {
            if (isCalibration) {
                if (product.selected_options) {
                    if (isCalibrationSelected) {
                        setCalibrationProduct(product)
                        setOpenCalibrationModal(true)
                    }
                    else {
                        document?.getElementById("calibration-filters")?.scrollIntoView({ behavior: "smooth" })
                    }
                }
                return
            }
            if ((product?.variant_products?.length > 0 || product?.availability?.status === "not-available")) {
                router.push(`/${product.canonical_url}`)
                return
            }
            setLoading(true)
            if (product.__typename === "BundleProduct") {
                addBundleProductsToCart({
                    variables: {
                        cartId: cartId,
                        sku: product.sku,
                        quantity: product.custom_stock_item.min_sale_qty,
                        optionId: product.items[0].option_id,
                        id: [product.items[0].options[0].id]
                    },
                    onCompleted: (data) => {
                        setLoading(false);
                        if (data) {
                            openMiniCart();
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false);
                    },
                });
            } else {
                addProductsToCart({
                    variables: { cartId: cartId, cartItems: [{ sku: product.sku, quantity: product.custom_stock_item.min_sale_qty }] },
                    onCompleted: (data) => {
                        setLoading(false)
                        if (data) {
                            if (data.action?.user_errors?.length > 0) {
                                for (let i = 0; i < data.action?.user_errors?.length; i++) {
                                    const userError = data.action?.user_errors.at(i)?.message
                                    enqueueSnackbar(userError, { variant: 'error' })
                                }
                            }
                            else {
                                openMiniCart()
                            }
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false)
                    }
                })
            }
        }
    }

    const brand = product?.custom_attributes?.filter(attr => attr.attribute_metadata.code === "brand")?.at(0)?.selected_attribute_options?.attribute_option?.at(0)?.label || null

    const tierRef = React.useRef(null)

    const isLG = useMediaQuery(theme => theme.breakpoints.up('lg'))

    return (
        <Box>
            {
                !isVariant
                    ?
                    <>
                        <Box
                            sx={{
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontWeight: 500,
                                color: cssVars.extraDarkGray,
                                marginBottom: 0,
                                minHeight: '16px',
                                backgroundColor: '#fff',
                            }}
                        >
                            {product.sku}
                        </Box>

                        <Box
                            sx={{
                                fontWeight: 600,
                                lineHeight: '29px',
                                marginBottom: 0,
                                minHeight: '29px',
                                backgroundColor: '#fff',
                            }}
                        >
                            {brand}
                        </Box>

                        <Box
                            sx={{
                                fontSize: '12px',
                                fontWeight: 600,
                                marginBottom: '7px',
                                backgroundColor: '#fff',
                                minHeight: '18px',
                                color: '#222',

                            }}
                        >
                            {product.model_no}
                        </Box>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                minHeight: '44px',
                                color: cssVars.extraDarkGray,
                            }}
                        >
                            {product.name}
                        </Box>
                    </>
                    :
                    null
            }



            <Box
                sx={{
                    mt: 2,
                    border: `2px solid ${cssVars.lightBlack}`,
                    p: "8px 12px",
                    display: 'flex',
                    alignItems: 'end',
                    borderRadius: cssVars.radius,
                    '&:hover': {
                        bgcolor: cssVars.extraLightGray,
                    },
                    cursor: isCalibration && !product.selected_options ? "auto" : "pointer",
                    bgcolor: cartReady ? "#fff" : cssVars.extraLightGray,
                    position: "relative",
                }}
                onMouseEnter={() => { isLG && (tierRef.current.style.display = "block") }}
                onMouseLeave={() => { isLG && (tierRef.current.style.display = "none") }}
                onClick={(e) => addToCart(e, product)}
            >
                {
                    isCalibration
                        ?
                        !product.selected_options
                            ?
                            <Box minHeight={27} px={1} py={0.045} display="flex" alignItems="center" fontWeight={500} fontSize={16} color={cssVars.red} mx="auto" onClick={e => e.stopPropagation()}>
                                <Box>{t("Not orderable")}</Box>
                            </Box>
                            :
                            !isCalibrationSelected
                                ?
                                <Box minHeight={27} px={1} py={0.045} display="flex" alignItems="center" fontWeight={500} fontSize={16} color={cssVars.red} mx="auto" onClick={e => { e.stopPropagation(); document?.getElementById("calibration-filters")?.scrollIntoView({ behavior: "smooth" }) }}>
                                    {t("Select options")}
                                </Box>
                                :
                                <PriceBox product={product} variant="plp" />
                        :
                        product?.variant_products?.length > 0 || (product?.availability?.status === "not-available" && !isCalibration)
                            ?
                            <Box minHeight={27} px={1} py={0.045} display="flex" alignItems="center" fontWeight={500} fontSize={16}>
                                <ViewHeadlineIcon sx={{ fontSize: 31, mr: 1 }} />
                                <Box>{t("View more")}</Box>
                            </Box>
                            :
                            <PriceBox product={product} variant="plp" />

                }
                <Box ref={tierRef} position="absolute" bgcolor="#fff" bottom={50} left={-21} right={-21} sx={{ display: "none", transition: "", "&>div": { my: "0px !important", boxShadow: "0 -4px 5px 0 rgba(0,0,0,.1)", px: "35px", py: "12px" } }}>
                    <TierPrices product={product} />
                </Box>
            </Box>

            {
                product?.price_tiers?.length > 0
                    ?
                    <Box mt={2} fontSize={14} textAlign="center" color={cssVars.blue} display={{ xs: "block", lg: "none" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            (tierRef.current.style.display === "block" ? (tierRef.current.style.display = "none") : (tierRef.current.style.display = "block"))
                            tierRef.current?.style.display === "block"
                                ?
                                e.target.textContent = t("Hide volume discount")
                                :
                                e.target.textContent = t("Show volume discount")
                        }}>
                        {t("Show volume discount")}
                    </Box>
                    :
                    null
            }


        </Box >
    )
}

export default ProductCardFooter