import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { COMPANY_CUSTOMERS, GET_CUSTOMER, SHARED_WISHLIST_CUSTOMER_LIST } from "../graphql/customer/queries";
import { CREATE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_ADDRESS, DELETE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_EMAIL, UPDATE_CUSTOMER, SUBSCRIBE_EMAIL_TO_LETTER, CHANGE_CUSTOMER_PASSWORD, REMOVE_CUSTOMER_FROM_WISHLIST, ASSIGN_CUSTOMER_TO_WISHLIST } from "../graphql/customer/mutations";
import { LOGOUT } from "../graphql/authentication/mutations";
import { useRouter } from "next/router";
import { useAuthStore } from "../stores/authStore";
import { SET_CART_COMPANY, UPDATE_CART_ITEMS_AFTER_DATE_DATE, UPDATE_PRICING_DATE } from "../graphql/cart/mutations";
import { useSnackbar } from "notistack";
import { useGlobalStore } from "../stores/globalStore";


export const CustomerContext = createContext()

export const CustomerProvider = ({ children }) => {

    const isAuthenticated = useAuthStore((state) => state.token).length > 0
    const loginDone = useAuthStore((state) => state.loginDone)

    const [skip, setSkip] = useState(true)
    const [isB2B, setIsB2B] = useState(false)

    const router = useRouter()
    const [count, setCount] = useState(1)
    const [customerData, setCustomerData] = useState({ firstname: "", lastname: "", orders: { total_count: 0 } })
    const { data } = useQuery(GET_CUSTOMER, { variables: { count: count }, skip: (skip || !isAuthenticated), fetchPolicy: 'network-only' })

    const [createCustomerAddress, { data: createAddressData, loading: createAddressLoading }] = useMutation(CREATE_CUSTOMER_ADDRESS)
    const [updateCustomerAddress, { data: updateAddressData, loading: updateAddressLoading }] = useMutation(UPDATE_CUSTOMER_ADDRESS)
    const [deleteCustomerAddress, { data: deleteAddressData, loading: deleteAddressLoading, }] = useMutation(DELETE_CUSTOMER_ADDRESS)
    const [updateCustomer, { data: updateData, loading: updateCustomerLoading, error: updateCustomerError }] = useMutation(UPDATE_CUSTOMER)
    const [changeEmail, { data: changeEmailData, loading: changeEmailLoading }] = useMutation(UPDATE_CUSTOMER_EMAIL)
    const [changePassword, { data: changePasswordData, loading: changePasswordLoading, error: changePasswordError }] = useMutation(CHANGE_CUSTOMER_PASSWORD)
    const [subscribeNewsLetter, { data: subscribeData, loading: subscribeLoading }] = useMutation(SUBSCRIBE_EMAIL_TO_LETTER)
    const [revokeCustomerToken, { }] = useMutation(LOGOUT)
    const [setCartCompany, { data: cartCompanyData, loading: cartCompanyLoading }] = useMutation(SET_CART_COMPANY)
    const [assignCustomerToWishList, { data: assignCustomerToWishListData, loading: assignCustomerToWishListLoading }] = useMutation(ASSIGN_CUSTOMER_TO_WISHLIST)
    const [removeCustomerToWishList, { data: removeCustomerToWishListData, loading: removeCustomerToWishListLoading }] = useMutation(REMOVE_CUSTOMER_FROM_WISHLIST)

    const cartId = useAuthStore(state => state.cartId)

    // company section
    const [employeesList, setEmployeesList] = useState()
    const [skipComp, setSkipComp] = useState(false)
    const { data: customersList, loading: companyLoading } = useQuery(COMPANY_CUSTOMERS, { skip: skipComp && (!isB2B || !isAuthenticated), nextFetchPolicy: "cache-only" })

    useEffect(() => {
        if (customersList) {
            setEmployeesList(customersList)
            setSkipComp(true)
        }
    }, [customersList])

    useEffect(() => {
        if (cartCompanyData) {
            setSkip(false)
        }
    }, [cartCompanyData])
    // end company section


    const signOut = useAuthStore((state) => state.signOut)

    const [updatePricingOrderItem] = useMutation(UPDATE_CART_ITEMS_AFTER_DATE_DATE)
    const { enqueueSnackbar } = useSnackbar()
    const setFullPageLoading = useGlobalStore(s => s.setFullPageLoading)

    const [navLogout, setNavLogout] = useState(false)

    const logOut = useCallback((path = "/") => {
        revokeCustomerToken({
            onCompleted: () => {
                signOut()
                setNavLogout(path)
            }
        })

    }, [])

    useEffect(() => {
        if (customerData)
            setIsB2B(customerData.companies?.length > 0)
    }, [customerData])

    useEffect(() => {
        if (navLogout && !isAuthenticated && !loginDone && cartId === "") {
            router.push(navLogout)
        }
    }, [isAuthenticated, loginDone, cartId, navLogout])

    useEffect(() => {
        if (isAuthenticated) {
            setSkip(false)
        }
        else if (!isAuthenticated && count > 1) {
            setCustomerData({ orders: { total_count: 0 } })
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (data) {
            setCustomerData(data.customer)
            setSkip(true)
            setCount(count + 1)
        }
    }, [data])

    useEffect(() => {
        if (createAddressData) {
            setSkip(false)
        }
    }, [createAddressData])
    useEffect(() => {
        if (createAddressData) {
            setSkip(false)
        }
    }, [createAddressData])

    useEffect(() => {
        if (updateAddressData)
            setSkip(false)
    }, [updateAddressData])

    useEffect(() => {
        if (deleteAddressData) {
            setSkip(false)
        }
    }, [deleteAddressData])

    useEffect(() => {
        if (updateData)
            setCustomerData(updateData.action.customer)
    }, [updateData])

    useEffect(() => {
        if (changeEmailData)
            setCustomerData(changeEmailData.action.customer)
    }, [changeEmailData])

    useEffect(() => {
        if (subscribeData && isAuthenticated)
            refetchCustomerData()
    }, [subscribeData, isAuthenticated])

    const refetchCustomerData = useCallback(() => {
        setSkip(false)
        setCount(count => count + 1)
    }, [])

    const contextValue = useMemo(() => (
        {
            isB2B,
            customerData,
            refetchCustomerData,
            changePasswordData,
            createCustomerAddress,
            createAddressLoading,
            updateCustomerAddress,
            updateAddressLoading,
            deleteCustomerAddress,
            deleteAddressLoading,
            changeEmail,
            changePassword,
            changeEmailLoading,
            changePasswordLoading,
            updateCustomer,
            updateCustomerLoading,
            updateCustomerError,
            changePasswordError,
            subscribeNewsLetter,
            subscribeLoading,
            logOut,
            customerLoading: !skip,
            employeesList,
            companyLoading: !skipComp,
            setCartCompany,
            removeCustomerToWishList,
            assignCustomerToWishList,
            removeCustomerToWishListLoading,
            assignCustomerToWishListLoading,
        }
    ), [
        isB2B,
        customerData,
        refetchCustomerData,
        changePasswordData,
        createCustomerAddress,
        createAddressLoading,
        updateCustomerAddress,
        updateAddressLoading,
        deleteCustomerAddress,
        deleteAddressLoading,
        changeEmail,
        changePassword,
        changeEmailLoading,
        changePasswordLoading,
        updateCustomer,
        updateCustomerLoading,
        updateCustomerError,
        changePasswordError,
        subscribeNewsLetter,
        subscribeLoading,
        logOut,
        skip,
        employeesList,
        companyLoading,
        setCartCompany,
        assignCustomerToWishList,
        removeCustomerToWishList,
        removeCustomerToWishListLoading,
        assignCustomerToWishListLoading,
    ]
    )

    return (
        <CustomerContext.Provider value={contextValue} >
            {children}
        </CustomerContext.Provider>
    )
}
