import { Box } from "@mui/material";
import Footer from "../footer";
import Header from "../header";
import MiniCartDrawer from "../mini-cart-drawer";
import Newsletter from "../newsletter";
import cssVars from "../../styles/vars.module.scss";
import CalibrationModal from "../calibration-modal";

const GeneralLayout = ({ path, children }) => {
    return (
        <>
            <Header />
            <Box component='main' sx={{ bgcolor: { lg: path === '/checkout/cart' ? cssVars.extraLightBlue : cssVars.white } }}>
                {children}
            </Box>
            <Newsletter />
            <Footer />
            <MiniCartDrawer />
            <CalibrationModal/>
        </>

    );
}

export default GeneralLayout;