import { useContext } from "react";
import { useAuthStore } from '../../stores/authStore';
import { useQuery } from "@apollo/client";
import Link from 'next/link';
import { Box, Button, Divider, ListItem, Menu, MenuItem, Typography } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { CustomerContext } from '../../Providers/CustomerContext';
import cssVars from '../../styles/vars.module.scss'

import { useGlobalStore } from '../../stores/globalStore';
import { GET_CART_ITEMS_COUNT } from '../../graphql/cart/queries';
import { usePaymentStore } from '../../stores/paymentStore';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from '@mui/icons-material/Search';
import Image from "next/image";
import { MultiWishListContext } from "../../Providers/MultiWishListContext";
import Badge from "../BadgeCustom";
import MenuDrawer from "./menuDrawer";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";

const QuickLinks = ({ extended }) => {

    const isAuthenticated = useAuthStore((state) => state.token).length > 0
    const company = useAuthStore(state => state.company)
    const isAriba = useGlobalStore(s => s.isAriba)


    const { t } = useTranslation()

    const router = useRouter()

    const { customerData, logOut } = useContext(CustomerContext)
    const isB2B = customerData?.is_company
    const { wishLists } = useContext(MultiWishListContext)
    console.log(isB2B);

    const openMiniCart = useGlobalStore((state) => state.openMiniCart)

    const [popOpen, setPopOpen] = useState(false)
    const closePop = (e) => {
        const condition = [document.getElementById('popUpMenu'), document.getElementById('popUpLink')].indexOf(e?.relatedTarget) > -1
        if (!condition) {
            setPopOpen(false)
        }
    }

    const loginDone = useAuthStore(state => state.loginDone)

    const cartId = useAuthStore(state => state.cartId)
    const paymentOngoing = usePaymentStore(state => state.paymentOngoing)


    const { data } = useQuery(GET_CART_ITEMS_COUNT, { variables: { cart_id: cartId }, skip: (!cartId || paymentOngoing || (isAuthenticated ? !loginDone : false)), nextFetchPolicy: "cache-only" })
    const cartCount = data?.cartItemsCount?.total_quantity ? data.cartItemsCount.total_quantity : 0

    const loginLink = "/customer/login.html"

    const wishlistProductsCount = wishLists?.total_product_count

    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const isLG = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    useEffect(() => {
        if (!isAuthenticated) {
            setPopOpen(false)
        }
    }, [isAuthenticated])
    console.log(wishLists);
    
    return (
        <Box width={{ xs: "80%", lg: "initial" }} display="flex" alignItems="center" justifyContent="space-between" sx={{ "& svg": { fill: cssVars.darkGray, "&.active, &:hover": { fill: cssVars.blue } }, "& a": { ml: { xs: 1, sm: 2, lg: 3 } }, "& .p-mobile": { display: { xs: "inline-block", lg: "none" } } }}>
            {extended && <Link href="/" aria-label="home page link" style={{ display: "flex", alignItems: "center" }}><Image src={mobLogo} alt="mobile logo" height="35" /></Link>}
            {extended && <Link href="/" aria-label="search trigger" onClick={(e) => {
                e.preventDefault()
                document.querySelector('header a.logo').scrollIntoView()
                document.querySelector('header #srch input').focus()
            }}>
                <SearchIcon htmlColor="#0072ba" style={{ fontSize: 40, height: "100%" }} />
            </Link>}

            <Link href={isAuthenticated ? "/customer/account/favorites.html" : "/customer/login.html?redirect=/customer/account/favorites.html"} aria-label="favorite list" style={{ visibility: isAriba ? "hidden" : "visible" }}>
                <Badge value={wishlistProductsCount} color="#df0009" >
                    <FavoriteIcon className={wishlistProductsCount > 0 ? "active" : ""} style={{ marginTop: 2, fontSize: 36 }} />
                </Badge>
            </Link>
            {isAuthenticated
                ?
                <Box display={{ xs: 'none', lg: "inline-block" }} position="relative" sx={{ visibility: isAriba ? "hidden" : "visible" }}>
                    <Link id="popUpLink" aria-label="customer mini menu" onMouseLeave={(e) => { closePop(e) }} href={"/customer/account/orders.html"} onMouseEnter={() => setPopOpen(true)}>
                        <Badge>
                            <PersonIcon className="active" style={{ fontSize: 42 }} />
                        </Badge>
                    </Link>
                    <Box
                        fontSize={14}
                        id="popUpMenu"
                        zIndex={4097}
                        position="absolute" top={38} right={-10} minWidth="256px" bgcolor={cssVars.white}
                        border={`1px solid ${cssVars.darkGray}`}
                        py={1}
                        sx={{ boxShadow: cssVars.shadow, borderRadius: cssVars.radius }} display={popOpen && isLG ? "block" : "none"}
                        onMouseLeave={(e) => { closePop(e) }}
                    >
                        {isB2B
                            ?
                            (
                                <Box borderBottom={`1px solid ${cssVars.gray}`} pb={1.5}>
                                    <Box display="flex" alignItems="center" mb={1.5} px={2} width="100%" justifyContent="space-between">
                                        <Box>
                                            <ApartmentIcon fontSize="large" sx={{ fill: `${cssVars.darkGray} !important` }} />
                                        </Box>
                                        <Box>
                                            <Box fontWeight={500}>{company.company_name}</Box>
                                        </Box>
                                    </Box>

                                    <Box sx={{ "& a": { p: "8px 16px", "&:hover": { bgcolor: cssVars.extraLightGray } } }}>
                                        <Box sx={{ "& a": { display: "block", m: 0 } }}>
                                            <Link href="/customer/account/orders.html#allOrders">{t('Company orders')}</Link>
                                        </Box>
                                        <Box sx={{ "& a": { display: "block", m: 0 } }}>
                                            <Link href="/customer/account/b2b.html">{t('Company employees')}</Link>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                            :
                            null
                        }
                        <Box display="flex" alignItems="center" pt={isB2B ? 1 : 0} mb={1.5} px={2} width="100%" justifyContent="space-between">
                            <Box>
                                <PersonIcon sx={{ fill: `${cssVars.darkGray} !important` }} fontSize="large" />
                            </Box>
                            <Box
                                border={`1px solid ${cssVars.gray}`}
                                borderRadius={cssVars.radius}
                                p="4px 8px"
                                sx={{ cursor: 'pointer', "&:hover": { bgcolor: cssVars.extraLightGray } }}
                                fontSize={14}
                                onClick={() => logOut()}
                            >
                                {t('Logout')}
                            </Box>
                        </Box>
                        <Box pb={2} px={2} mb={1} borderBottom={`1px solid ${cssVars.gray}`}>
                            <Box fontWeight={500}>
                                {customerData.gender === 1 ? t('Sir') + '.' : customerData.gender === 2 ? t('Madams') + '.' : ''} {customerData.firstname} {customerData.lastname}
                            </Box>
                        </Box>
                        <Box sx={{ "& a": { p: "8px 16px", "&:hover": { bgcolor: cssVars.extraLightGray } } }}>
                            <Box sx={{ "& a": { display: "block", m: 0 } }}>
                                <Link href="/customer/account/orders.html">{t('Folders')}</Link>
                            </Box>
                            <Box sx={{ "& a": { display: "block", m: 0 } }}>
                                <Link href="/customer/account/favorites.html">{t('Favorite groups')}</Link>
                            </Box>
                            <Box sx={{ "& a": { display: "block", m: 0 } }}>
                                <Link href="/customer/account/profileinformation.html">{t('Customer information')}</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                :
                <Box
                    sx={{
                        display: { xs: 'none', lg: 'inline-block' },
                        position: 'relative',
                        visibility: isAriba ? 'hidden' : 'visible',
                        "& a": {
                            display: { lg: 'block' },
                        }
                    }}
                >
                    <Link href={loginLink} aria-label="login link" >
                        <Badge>
                            <PersonIcon style={{ fontSize: 42 }} />
                        </Badge>
                    </Link>
                </Box>
            }
            <Link href={isAuthenticated ? "/customer/account/orders.html" : loginLink} className="p-mobile" aria-label={isAuthenticated ? "account dashboard" : "login"} style={{ visibility: isAriba ? "hidden" : "visible" }}>
                <Badge>
                    <PersonIcon className={isAuthenticated ? "active" : null} style={{ fontSize: 42 }} />
                </Badge>
            </Link>
            <Link href='#' aria-label="mini cart open" onClick={(e) => { e.preventDefault(); openMiniCart() }}>
                <Badge value={cartCount} color="#01bf5a" styles={{ paddingTop: "4px" }}>
                    <ShoppingBasketIcon className={cartCount > 0 ? "active" : ""} style={{ fontSize: 36 }} />
                </Badge>
            </Link>

            <Box sx={{ display: { xs: "inline-block", lg: "none" }, pb: "4px" }} onClick={() => { setOpenMobileMenu(true) }}>
                <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 9.70605H25.5" stroke="#8AB2DB" strokeWidth="3" />
                    <path d="M0.5 1.70605H25.5" stroke="#C6C7C8" strokeWidth="3" />
                    <path d="M0.5 17.7061H25.5" stroke="#0072BA" strokeWidth="3" />
                </svg>
            </Box>
            <MenuDrawer open={openMobileMenu} setOpen={setOpenMobileMenu} />
        </Box>
    );
}

export default QuickLinks;
