import React, { useEffect } from 'react';
import Layout from '../components/layout/layout'
import 'nprogress/nprogress.css'
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import createEmotionCache from '../helpers/createEmotionCache';
import { getTheme } from '../styles/theme/themes';
import '../styles/globals.scss';
import '../components/pdp/product-details/show-box/showBox.scss'
import { Roboto, Poppins } from "next/font/google";
import Head from 'next/head';
import useRouterEvents from '../hooks/useRouterEvents';
import { useGlobalStore } from '../stores/globalStore';

const clientSideEmotionCache = createEmotionCache();

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['400', '500', '700', '900'],
  variable: "--roboto"
})


const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
  variable: "--poppins"
})


function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps, ...appProps }) {

  const router = useRouterEvents()
  const selectedTheme = useGlobalStore((state) => state.selectedTheme)

  return (
    <>
      <style jsx global>{`
        html body{
          font-family: ${poppins.style.fontFamily}, ${roboto.style.fontFamily}, sans-serif;
        }
        #checkoutAgreement *{
          font-family: ${poppins.style.fontFamily} !important;
        }
        button {
          font-family: ${poppins.style.fontFamily} !important;
        }
        .roboto {
            font-family: ${roboto.style.fontFamily}, sans-serif !important;
        }

        .Poppins {
            font-family: ${poppins.style.fontFamily}, sans-serif !important;
        }
      `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5"
        />
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={getTheme(selectedTheme)}>
          <CssBaseline />
          <Layout key={router.locale} pathname={appProps.router.pathname}>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default MyApp
