import { Box, Grid, useTheme } from "@mui/material";
import Link from "next/link";
import cssVars from '../../styles/vars.module.scss'
import useTranslation from "../../hooks/useTranslation";
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const BlogCard = ({ blog, forServices = false }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <Box borderRadius={cssVars.radius} bgcolor={cssVars.lightBlue} pt={blog.image ? 0 : 3} pb={3}>
            {
                blog.image
                    ?
                    <Box position="relative" paddingTop={"68%"} mb={3}>
                        <Box position="absolute"
                            sx={{
                                inset: 0,
                                borderTopLeftRadius: cssVars.radius,
                                borderTopRightRadius: cssVars.radius,
                                backgroundOrigin: "content-box",
                                backgroundImage: `url(${blog.image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                overflow: "hidden"
                            }} />
                    </Box>
                    :
                    null
            }

            <Box px={{ xs: "20px", md: "24px" }}>
                <Box fontSize={20} fontWeight={500} className="dots2" sx={{ height:"60px" }} mb={2}>{blog.name}</Box>
                <Box component="p" className="dots4" mb={2} sx={{ height:"96px" }}>
                    {blog.short_description}
                </Box>
                <Link style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: `${theme.palette.primary.main} !important`, fontWeight: 500 }} href={`/${forServices ? 'services' : 'news'}/${blog.url_key}.html`}>
                    <Box component="span" display="block" sx={{ color: `${theme.palette.primary.main} !important` }}>
                        {t("Read more")}
                    </Box>
                    <Box sx={{ color: `${theme.palette.primary.main} !important` }}>
                        <ChevronRightIcon sx={{ fontSize: "26px" }} />
                    </Box>
                </Link>
            </Box>
        </Box>
    );
}

export default BlogCard;