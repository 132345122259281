import Drawer from "../drawer/drawer";
import CloseIcon from '@mui/icons-material/Close';
import cssVars from "../../styles/vars.module.scss"
import { useRouter } from "next/router";
import { Box, Button, Grid, List, ListItem } from "@mui/material";
import { useContext } from "react";
import Image from "next/image";
import useTranslation from "../../hooks/useTranslation";
import Link from "next/link";
import useAddZeroes from "../../hooks/useAddZeroes";
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import { useGlobalStore } from "../../stores/globalStore";
import { useTheme } from "@mui/material";
import { useAuthStore } from "../../stores/authStore";
import { useCurrencyStore } from "../../stores/currencyStore";
import { useQuery } from "@apollo/client";
import { GET_CART_ITEMS } from "../../graphql/cart/queries";
import CartItemSkeleton from "../cart-item-skeleton";
import { usePaymentStore } from "../../stores/paymentStore";

const MiniCartDrawer = () => {
    const currency = useCurrencyStore(state => state.currency)

    const router = useRouter()

    const miniCartOpen = useGlobalStore((state) => state.miniCartOpen)
    const openMiniCart = useGlobalStore((state) => state.openMiniCart)
    const closeMiniCart = useGlobalStore((state) => state.closeMiniCart)


    const { t } = useTranslation()

    const { z } = useAddZeroes()
    const theme = useTheme()

    const isAuthenticated = useAuthStore(state => state.token).length > 0
    const loginDone = useAuthStore(state => state.loginDone)

    const cartId = useAuthStore(state => state.cartId)

    const paymentOngoing = usePaymentStore(state => state.paymentOngoing)

    const { data, loading: itemsLoading } = useQuery(GET_CART_ITEMS, { variables: { cart_id: cartId }, skip: (!cartId || paymentOngoing || (isAuthenticated ? !loginDone : false)), nextFetchPolicy: "cache-only" })
    const cart = data?.cartItems ? data.cartItems : null

    const includeTax = useGlobalStore(state => state.includeTax) 

    const getPrice = (item) => {
        if (includeTax) {            
            return item.prices.price_including_tax.value
        } else {
            return item.prices.price.value
        }
    }

    return (
        <Drawer anchor="right" open={miniCartOpen} setOpen={(open) => { open ? openMiniCart() : closeMiniCart() }} swipeable={false}>
            <Box width={{ xs: "100%", lg: "660px" }} minWidth="272px" sx={{ overflow: "hidden", p: "0px !important" }}>
                <Grid mt={{ xs: "-18px", lg: "-44px" }} mx="-24px" p={{ xs: "24px 24px", lg: "60px 68px 20px 68px" }} paddingBottom="15px" borderBottom={`1px solid #E5E5E7`} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" fontSize="20px" fontWeight={600}>{t("Your cart")} {cart?.total_quantity > 0 ? <Box ml={0.6}>({cart?.total_quantity})</Box> : null}</Box>
                    <CloseIcon sx={{ fontSize: "28px", cursor: "pointer" }} onClick={closeMiniCart} />
                </Grid>
                {
                    itemsLoading
                        ?
                        <Box mx="-24px">
                            <Box px={{ xs: "24px", lg: "68px" }}>
                                {
                                    Array.from({ length: 4 }).map(i =>
                                        <Box key={`cart-item-skeleton111-${i}`}>
                                            <CartItemSkeleton variant="mini-cart" />
                                        </Box>
                                    )
                                }
                            </Box>

                        </Box>
                        :
                        cart?.total_quantity > 0
                            ?
                            <Box mx="-24px">
                                <List disablePadding
                                    sx={{
                                        px: { xs: "24px !important", lg: "66px !important" },
                                        textTransform: 'capitalize', py: "10px",
                                        height: { xs: "calc(100vh - 240px)", sm: "calc(100vh - 180px)", lg: "calc(100vh - 266px)" }, overflowY: "auto",
                                        "&::-webkit-scrollbar": { width: 8 },
                                        "&::-webkit-scrollbar-track": { background: 'unset' },
                                        "&::-webkit-scrollbar-thumb": { background: cssVars.gray, borderRadius: cssVars.radius }
                                    }}
                                >
                                    {
                                        cart.items?.map(item => {
                                            const Component = item?.customizable_options?.length > 0 ? Box : Link
                                            const ComponentsProps = item?.customizable_options?.length > 0 ? { onClick: (e) => { e.preventDefault() } } : { href: `/${item.product.url_key}.html` }
                                            return (
                                                <ListItem disablePadding key={item.product.sku} sx={{ display: "flex", alignItems: "center", borderBottom: `1px solid #E5E5E7`, py: { xs: "18px", sm: "22px" } }}>
                                                    <Component {...ComponentsProps} >
                                                        <Image width={70} height={70} style={{ objectFit: "contain", objectPosition: "center" }} alt={item.product.name} src={item.product.small_image.url} />
                                                    </Component>
                                                    <Box className="details" fontSize={16} ml={2}>
                                                        <Box sx={{ color: theme.palette.primary.main, fontWeight: 500, fontSize: 14 }}>{
                                                            item.product.brand
                                                                ?
                                                                item.product.custom_attributes.filter(attr => attr.attribute_metadata.code === "brand")[0].selected_attribute_options.attribute_option[0]?.label
                                                                :
                                                                null
                                                        }</Box>
                                                        <Component {...ComponentsProps} >
                                                            {
                                                                item.__typename === 'BundleCartItem' && item?.bundle_options?.at(0)?.values?.at(0) && item?.product && item?.product?.items?.at(0)?.options?.at(0)
                                                                    ?
                                                                    <Box sx={{ my: 0.5 }} fontWeight={500}>
                                                                        <Box>{item.product.b2b_uom_label} ({item.product.items.at(0).options.at(0).quantity} {item.product.b2c_uom_label})</Box>
                                                                        <Box>{item.bundle_options.at(0).values.at(0).quantity} x {item.bundle_options.at(0).values.at(0).label}</Box>
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ my: 0.5 }} fontWeight={500}>{item.product.name}</Box>
                                                            }
                                                        </Component>
                                                        {
                                                            item?.customizable_options?.length > 0
                                                                ?
                                                                <Box display="flex" alignItems="center" flexWrap="wrap" color={cssVars.lightBlack} my={0.5}>
                                                                    {
                                                                        item.customizable_options.map(option =>
                                                                            <Box key={option.label} display="flex" alignItems="center" mr="14px">
                                                                                <Box mr={0.5} fontWeight={500} >{option.label}:</Box>
                                                                                <Box> {option.type === "drop_down" ? option.values.at(0).label : option.values.at(0).value}</Box>
                                                                            </Box>
                                                                        )
                                                                    }
                                                                </Box>
                                                                :
                                                                null
                                                        }
                                                        <Box display="flex" alignItems="center">
                                                            <Box component="span" sx={{ fontWeight: 500 }}>{z(getPrice(item), currency)}</Box>
                                                            <Box ml="20px">{t("QTY")}: {item.quantity}</Box>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            )
                                        }
                                        )
                                    }
                                </List>
                                <Box position="absolute" bottom={0} left={0} right={0} px={{ xs: "24px", lg: "68px" }} py={3} sx={{ bgcolor: cssVars.white }} >
                                    <Grid container columns={{ xs: 1, sm: 2 }} spacing="13px">
                                        <Grid item xs={1}  >
                                            <Button fullWidth onClick={() => { router.push("/checkout/cart"); }} sx={{ py: 3, borderRadius: '25px', bgcolor: cssVars.white, border: `1px solid ${cssVars.black}`, color: cssVars.black, ":hover": { bgcolor: cssVars.black, borderColor: cssVars.black, color: cssVars.white } }}>
                                                {t("View cart")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button fullWidth onClick={() => { router.push("/checkout"); }} sx={{ py: 3, borderRadius: '25px', bgcolor: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}`, color: cssVars.white, ":hover": { bgcolor: theme.palette.primary.dark, borderColor: theme.palette.primary.dark, color: cssVars.white } }}>
                                                {t("Checkout now")}
                                            </Button>
                                        </Grid>
                                        {
                                            cart.display_quote_button
                                                ?
                                                <Grid item xs={2}>
                                                    <Button fullWidth onClick={() => { router.push("/quote-request.html"); }} sx={{ display: { xs: 'none', lg: "block" }, borderRadius: '25px', bgcolor: cssVars.white, border: `1px solid ${cssVars.black}`, color: cssVars.black, ":hover": { bgcolor: cssVars.black, borderColor: cssVars.black, color: cssVars.white } }}>
                                                        {t("Request a quotation")}
                                                    </Button>
                                                    <Box height={40} display={{ xs: "flex", lg: 'none' }} justifyContent="center" alignItems="center" onClick={() => { router.push("/quote-request.html") }} sx={{ cursor: "pointer", fontWeight: 500 }}>
                                                        {t("Request a quotation")}
                                                    </Box>
                                                </Grid>
                                                :
                                                null
                                        }
                                        <Box display={{ xs: "none", lg: "block" }} px="13px" mt="11px" color={cssVars.darkGray}>{t("* Shipping, taxes, and discounts will be calculated at checkout.")}</Box>
                                    </Grid>
                                </Box>
                            </Box>
                            :
                            <Box display="flex" height="calc(100vh - 232px)" fontSize="22px" alignItems="center" justifyContent="center" flexDirection="column" color={cssVars.darkGray}>
                                <RemoveShoppingCartOutlinedIcon sx={{ fontSize: "150px", color: cssVars.lightGray, display: "block", mb: 2 }} />
                                {t("Your cart is empty")}.
                            </Box>
                }
            </Box>
        </Drawer >
    );
}

export default MiniCartDrawer;