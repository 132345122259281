import { ADD_BUNDLE_PRODUCT_TO_CART, ADD_NOTE_TO_CART, ADD_PRODUCT_TO_CART, SAME_BILLING_AS_SHIPPING_ADDRESS_ON_CART,ADD_SIMPLE_PRODUCT_TO_CART, APPLY_COUPON_TO_CART, CREATE_BRAINTREE_CLIENT_TOKEN, CREATE_PAYPAL_EXPRESS_TOKEN, PLACE_ORDER, REMOVE_COUPON_FROM_CART, REMOVE_PRODUCT_FROM_CART, SET_BILLING_ADDRESS_ON_CART, SET_EMAIL_ON_GUEST_CART, SET_PAYMENT_METHOD, SET_PICKUP_LOCATION_CART, SET_SHIPPING_ADDRESS_ON_CART, SET_SHIPPING_METHOD_ON_CART, UPDATE_CART_ITEMS, UPDATE_CART_STORE_CODE } from "../../graphql/cart/mutations"
import { GET_APPLIED_COUPONS, GET_AVAILABLE_SHIPPING_METHODS, GET_CART_BILLING_ADDRESS, GET_CART_ERRORS, GET_CART_ITEMS, GET_CART_ITEMS_COUNT, GET_CART_PRICES, GET_CART_SELECTED_SHIPPING_METHOD, GET_CART_SHIPPING_ADDRESS, GET_CART_SHIPPING_COST, GET_SELECTED_PAYMENT_METHOD } from "../../graphql/cart/queries"
import { useMutation } from "@apollo/client"
import { useAuthStore } from "../../stores/authStore"


export const useCartActions = () => {

    const cartId = useAuthStore((state) => state.cartId)

    const removeItemFromCartRefetch = [
        GET_CART_ITEMS_COUNT,
        GET_CART_ITEMS,
        GET_CART_PRICES,
        GET_CART_SHIPPING_COST,
        GET_CART_ERRORS,
    ]

    const IncreaseItemQty = [
        GET_CART_ITEMS_COUNT,
        GET_CART_ITEMS,
        GET_CART_PRICES,
        GET_CART_SHIPPING_COST,
        GET_CART_ERRORS,
    ]

    const refetchAfterAddToCart = [
        GET_CART_ITEMS_COUNT,
        GET_CART_ITEMS,
        GET_CART_PRICES,
    ]

    const refetchShippingAddressChange = [
        GET_CART_SHIPPING_ADDRESS,
        GET_AVAILABLE_SHIPPING_METHODS,
    ]

    const refetchBillingAddressChange = [
        GET_CART_BILLING_ADDRESS,
    ]

    const refetchShippingMethodChange = [
        GET_CART_SELECTED_SHIPPING_METHOD,
        GET_CART_SHIPPING_COST,
        GET_CART_PRICES,
    ]

    const refetchPaymentMethodChange = [
        GET_CART_PRICES,
        GET_SELECTED_PAYMENT_METHOD
    ]

    const refetchCouponChange = [
        GET_CART_PRICES,
        GET_APPLIED_COUPONS,
    ]

    const [addProductsToCart, { loading: addProductsToCartLoading }] = useMutation(ADD_PRODUCT_TO_CART, { refetchQueries: refetchAfterAddToCart })
    const [addSimpleProductsToCart, { loading: addSimpleProductsToCartLoading }] = useMutation(ADD_SIMPLE_PRODUCT_TO_CART, { refetchQueries: refetchAfterAddToCart })
    const [addBundleProductsToCart, { loading: addBundleProductsToCartLoading }] = useMutation(ADD_BUNDLE_PRODUCT_TO_CART, { refetchQueries: refetchAfterAddToCart })
    const [removeItemFromCart, { loading: removeItemFromCartLoading }] = useMutation(REMOVE_PRODUCT_FROM_CART, { refetchQueries: removeItemFromCartRefetch })
    const [updateCartItems, { loading: updateCartItemsLoading }] = useMutation(UPDATE_CART_ITEMS, { refetchQueries: IncreaseItemQty })
    const [setShippingAddressesOnCart, { loading: setShippingAddressesOnCartLoading }] = useMutation(SET_SHIPPING_ADDRESS_ON_CART, { refetchQueries: refetchShippingAddressChange })
    const [setBillingAddressOnCart, { loading: setBillingAddressOnCartLoading }] = useMutation(SET_BILLING_ADDRESS_ON_CART, { refetchQueries: refetchBillingAddressChange })
    const [setShippingMethodsOnCart, { loading: setShippingMethodsOnCartLoading }] = useMutation(SET_SHIPPING_METHOD_ON_CART, { refetchQueries: refetchShippingMethodChange })
    const [setPaymentMethodOnCart, { loading: setPaymentMethodOnCartLoading }] = useMutation(SET_PAYMENT_METHOD, { refetchQueries: refetchPaymentMethodChange })
    const [placeOrder, { loading: placeOrderLoading }] = useMutation(PLACE_ORDER)
    const [setGuestEmailOnCart, { loading: setGuestEmailOnCartLoading }] = useMutation(SET_EMAIL_ON_GUEST_CART)
    const [applyCouponToCart, { loading: applyCouponToCartLoading }] = useMutation(APPLY_COUPON_TO_CART, { refetchQueries: refetchCouponChange })
    const [removeCouponFromCart, { loading: removeCouponFromCartLoading }] = useMutation(REMOVE_COUPON_FROM_CART, { refetchQueries: refetchCouponChange })
    const [createPaypalExpressToken, { loading: createPaypalExpressTokenLoading }] = useMutation(CREATE_PAYPAL_EXPRESS_TOKEN)
    const [createBrainreeClientToken, { loading: createBrainreeClientTokenLoading }] = useMutation(CREATE_BRAINTREE_CLIENT_TOKEN)
    const [setPickupLocation, { loading: setPickupLocationLoading }] = useMutation(SET_PICKUP_LOCATION_CART, { refetchQueries: refetchShippingAddressChange })
    const [updateCartStoreCode, { data: storeCodeData, loading: updateCartStoreCodeLoading }] = useMutation(UPDATE_CART_STORE_CODE, { refetchQueries: refetchShippingMethodChange })
    const [setBillingSameShipping, { loading: setBillingSameShippingLoading }] = useMutation(SAME_BILLING_AS_SHIPPING_ADDRESS_ON_CART, { refetchQueries: refetchBillingAddressChange })
    const [addNotesToCart] = useMutation(ADD_NOTE_TO_CART)

    return {
        addProductsToCart,
        addBundleProductsToCart,
        removeItemFromCart,
        updateCartItems,
        setShippingAddressesOnCart,
        setBillingAddressOnCart,
        setShippingMethodsOnCart,
        setPaymentMethodOnCart,
        placeOrder,
        setGuestEmailOnCart,
        applyCouponToCart,
        removeCouponFromCart,
        createPaypalExpressToken,
        createBrainreeClientToken,
        setPickupLocation,
        updateCartStoreCode,
        addNotesToCart,
        setBillingSameShipping,
        setBillingSameShippingLoading,
        addSimpleProductsToCart,
        addProductsToCartLoading,
        addBundleProductsToCartLoading,
        removeItemFromCartLoading,
        updateCartItemsLoading,
        setShippingAddressesOnCartLoading,
        setBillingAddressOnCartLoading,
        setShippingMethodsOnCartLoading,
        setPaymentMethodOnCartLoading,
        placeOrderLoading,
        setGuestEmailOnCartLoading,
        applyCouponToCartLoading,
        removeCouponFromCartLoading,
        createPaypalExpressTokenLoading,
        createBrainreeClientTokenLoading,
        setPickupLocationLoading,
        addSimpleProductsToCartLoading,
        disabled: !cartId
    }

}

