import { gql } from "@apollo/client"

export const ADD_PRODUCT_TO_CART = gql`
mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
  action: addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
    cart {
      id
    }
    user_errors {
      message
    }
  }
}
`

export const ADD_SIMPLE_PRODUCT_TO_CART = gql`
mutation addSimpleProductsToCart(
  $cartId: String!
  $cartItems: [SimpleProductCartItemInput!]!
) {
  action: addSimpleProductsToCart(
    input: { cart_id: $cartId, cart_items: $cartItems }
  ) {
    cart {
      id
    }
  }
}
`

export const ADD_BUNDLE_PRODUCT_TO_CART = gql`
mutation addBundleProductsToCart(
  $cartId: String!
  $sku: String!
  $quantity: Float!
  $optionId: Int!
  $id: [String]!
) {
  action:addBundleProductsToCart(
    input: {
      cart_id: $cartId
      cart_items: {
        data: {
          sku: $sku
          quantity: $quantity
        },
        bundle_options:[{
          id:$optionId,
          quantity:1,
          value:$id
        }]
      }
    }
  ) {
    cart {
      id
    }
  }
}
`;

export const REMOVE_PRODUCT_FROM_CART = gql`
mutation removeItemFromCart($cart_id: String!, $cart_item_uid: ID!) {
  action: removeItemFromCart(
    input: { cart_id: $cart_id, cart_item_uid: $cart_item_uid }
  ) {
    cart {
      id
    }
  }
}
`


export const UPDATE_CART_ITEMS = gql`
mutation updateCartItems(
  $cartId: String!
  $cartItems: [CartItemUpdateInput!]!
) {
  action: updateCartItems(input: { cart_id: $cartId, cart_items: $cartItems }) {
    cart {
      id
    }
  }
}
`


export const CREATE_CART = gql`
mutation createCart {
  cart_id: createEmptyCart
}
`

export const MERGE_CARTS = gql`
mutation mergeCarts($source_cart_id: String!) {
  mergeCarts(source_cart_id: $source_cart_id) {
    id
  }
}
`


export const SET_SHIPPING_ADDRESS_ON_CART = gql`
mutation setShippingAddressesOnCart(
  $cart_id: String!
  $shipping_addresses: [ShippingAddressInput]!
){
  action:setShippingAddressesOnCart(
    input: {
      cart_id: $cart_id
      shipping_addresses: $shipping_addresses
    }
  ) {
    cart{
      id
    }
  }
}
`

export const SET_BILLING_ADDRESS_ON_CART = gql`
mutation setBillingAddressOnCart(
  $cart_id: String!
  $billing_address: BillingAddressInput!
){
  action:setBillingAddressOnCart(
    input: {
      cart_id: $cart_id
      billing_address: $billing_address
    }
  ) {
    cart{
      id
    }
  }
}
`
export const SAME_BILLING_AS_SHIPPING_ADDRESS_ON_CART = gql`
mutation setBillingAddressOnCart(
  $cart_id: String!
){
  action:setBillingAddressOnCart(
    input: {
      cart_id: $cart_id,
      billing_address: { same_as_shipping: true }
    }
  ) {
    cart{
      id
    }
  }
}
`

export const SET_SHIPPING_METHOD_ON_CART = gql`
mutation setShippingMethodsOnCart($cart_id:String!,$carrier_code: String!,$method_code: String!){
  action:setShippingMethodsOnCart(input: {
    cart_id: $cart_id
    shipping_methods: [
      {
        carrier_code: $carrier_code
        method_code: $method_code
      }
    ]
  }) {
    cart{
      id
    }
  }
}
`



export const SET_PAYMENT_METHOD = gql`
mutation setPaymentMethodOnCart($cart_id:String!,$payment_method:PaymentMethodInput!){
  setPaymentMethodOnCart(input: {
      cart_id: $cart_id
      payment_method: $payment_method
  }) {
    cart {
      selected_payment_method {
        code
      }
    }
  }
}
`

export const PLACE_ORDER = gql`
mutation placeOrder($cart_id:String!){
  placeOrder(input: {cart_id: $cart_id}) {
    order {
      order_number
    }
  }
}
`

export const SET_EMAIL_ON_GUEST_CART = gql`
mutation setGuestEmailOnCart($cart_id: String!, $email: String!){
  setGuestEmailOnCart(input: {
    cart_id: $cart_id
    email: $email
  }) {
    cart {
      email
    }
  }
}
`

export const SET_PICKUP_LOCATION_CART = gql`
mutation setPickupLocation($cart_id: String!, $pickup_location_code: String!) {
  setPickupLocation: setShippingAddressesOnCart(
    input: {
      cart_id: $cart_id
      shipping_addresses: [{ pickup_location_code: $pickup_location_code }]
    }
  ) {
    res: cart {
      locations: shipping_addresses {
        pickup_location_code
      }
    }
  }
}
`

export const APPLY_COUPON_TO_CART = gql`
mutation applyCouponToCart($cart_id: String!,$coupon_code: String!){
  applyCouponToCart(
    input: {
      cart_id: $cart_id
      coupon_code: $coupon_code
    }
  ) {
    cart {
      id
    }
  }
}
`

export const REMOVE_COUPON_FROM_CART = gql`
mutation removeCouponFromCart($cart_id: String!){
  removeCouponFromCart(
    input:
      { cart_id: $cart_id }
    ) {
    cart {
      id
    }
  }
}
`


//======================= PayPal =======================//

export const CREATE_PAYPAL_EXPRESS_TOKEN = gql`
mutation createPaypalExpressToken($cart_id: String!,){
  createPaypalExpressToken(
    input: {
      cart_id: $cart_id
      code: "paypal_express"
      express_button: true
      urls: {
        return_url: "checkout"
        cancel_url: "checkout"
      }
    }
  ) {
    token
    paypal_urls {
      start
      edit
    }
  }
}
`

export const CREATE_BRAINTREE_CLIENT_TOKEN = gql`
mutation createBraintreeClientToken{
  createBraintreeClientToken
}`

export const UPDATE_PRICING_DATE = gql`
mutation updatePricingOrderDate(
  $cart_id: String!
  $itm_pricing_order_date: String!
) {
  action: updatePricingOrderDate(
    input: {
      cart_id: $cart_id
      itm_pricing_order_date: $itm_pricing_order_date
    }
  ) {
    cart {
      id
    }
  }
}
`

export const UPDATE_CART_ITEMS_AFTER_DATE_DATE = gql`
mutation updatePricingOrderItem(
  $cart_id: String!
) {
  action: updatePricingOrderItem(
    input: {
      cart_id: $cart_id
    }
  ) {
    cart {
      id
    }
  }
}
`

// Wallee
export const WALLEE_SET_TRANSACTION_URLS = gql`
  mutation SetTransactionUrls(
    $cart_id: String!
    $success_url: String!
    $failure_url: String!
  ) {
    updateTransactionUrls(
      input: {
        cart_id: $cart_id
        success_url: $success_url
        failure_url: $failure_url
      }
    ) {
      result
    }
  }
`

export const RESTORE_WALLEE = gql`
mutation RestoreQuote($cartId: String!) {
restoreQuote(input: {cart_id: $cartId}) {
  result
  cart_id
}
}
`

export const SUCCESS_WALLEE = gql`
  mutation successWallee($orderId: String!, $token: String!) {
    order:processSuccess(input: { order_id: $orderId, token: $token }){
      order_entity_id
      order_number
      email
      firstname
      lastname
      message
    }
}
`;

// dataTrans
export const WE_ARE_PLANET_SET_TRANSACTION_URLS = gql`
  mutation SetTransactionUrls(
    $cart_id: String!
    $success_url: String!
    $failure_url: String!
  ) {
    updateTransactionUrls(
      input: {
        cart_id: $cart_id
        success_url: $success_url
        failure_url: $failure_url
      }
    ) {
      result
    }
  }
`

export const RESTORE_WE_ARE_PLANET = gql`
mutation RestoreQuote($cartId: String!) {
restoreQuote(input: {cart_id: $cartId}) {
  result
  cart_id
}
}
`

export const SUCCESS_WE_ARE_PLANET = gql`
  mutation successWeArePlanet($orderId: String!, $token: String!) {
    order:processSuccess(input: { order_id: $orderId, token: $token }){
      order_entity_id
      order_number
      email
      firstname
      lastname
      message
    }
}
`;

export const UPDATE_CART_STORE_CODE = gql`
  mutation updateCartStoreCode($cart_id: String!, $pickup_store_code: String!) {
    action: updateCartStoreCode(
      input: { cart_id: $cart_id, pickup_store_value: $pickup_store_code }
    ) {
      __typename
    }
  }
`
export const SET_CART_COMPANY = gql`
mutation setCartCompany($card_code: String!) {
  setCartCompany(
    itm_cardode: $card_code
    update_cart_price: true
  ) {
    status
    message
  }
}`;

export const ADD_NOTE_TO_CART = gql`
mutation addExtraOrderComments($cart_id: String!,$extra_comments:[ExtraOrderCommentInput]!){
addExtraOrderComments(
    input: {
      cart_id: $cart_id
      extra_comments: $extra_comments
    }
  ) {
    extra_comments {
        code 
        value
    }
  }
}
`;

export const WE_ARE_PLANET_GET_ORDER_TRANSACTION = gql`
query GetOrderTransactionSettings($order_number: String!, $integrationType: String!) {
  customerOrderTransaction(
    order_id: $order_number
    integration_type: $integrationType
  ) {
    order_id
    integration_type
    transaction_id
    transaction_state
    payment_url
  }
}
`