import { Backdrop, CircularProgress } from "@mui/material";
import { useGlobalStore } from "../../stores/globalStore";
import React from "react";
import cssVars from '../../styles/vars.module.scss'

const GlobalSpinnerLoader = () => {
    const loading = useGlobalStore(s => s.fullPageLoading)

    return (
        <Backdrop
            sx={{ backgroundColor: '#00000077', zIndex: 100000000 }}
            open={loading}
        >
            <CircularProgress color="inherit" sx={{ width: "60px !important", height: "60px !important", color: cssVars.primaryBlue }} />
        </Backdrop>
    );
}

export default GlobalSpinnerLoader;