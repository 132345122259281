/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import PlaceHolder from '../../media/images/placeholder.svg'
import { Box } from '@mui/material';

const MenuCard = ({ img, title, count, link, className = '', onClick = () => { } }) => {
    return (
        <Link href={link} className={'menu-card ' + className} style={{ textDecoration: "none", display: "inline-block" }} onClick={onClick}>
            <Box>
                {img
                    ?
                    <img src={img} alt='sub-cat-icon' className="b-img" />
                    :
                    <PlaceHolder className="b-img " style={{ width: "60%" }} />
                }
                <Box component="span" className="roboto count">{count}</Box>
            </Box>
            <Box>
                <Box component="p">{title}</Box>
            </Box>
        </Link>
    );
}

export default MenuCard;