import { useContext, useEffect, useRef, useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import SelectCompany from '../selectCompany/selectCompany';
import Link from 'next/link';
import { Box, Typography, Button, IconButton, Grid } from '@mui/material';
import { useAuthStore } from '../../stores/authStore';
import { CREATE_CART, SET_CART_COMPANY } from '../../graphql/cart/mutations';
import useTranslation from '../../hooks/useTranslation';
import { CustomerContext } from '../../Providers/CustomerContext';
import { useMutation, useQuery } from '@apollo/client';
import cssVars from '../../styles/vars.module.scss';
import { GET_COMPANY_CARD_CODE } from '../../graphql/cart/queries';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SelectCompany from './selectCompany';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRouter } from 'next/router';
import { useGlobalStore } from '../../stores/globalStore';
const B2bBar = () => {

    const { t } = useTranslation()
    const cartId = useAuthStore(state => state.cartId)
    const company = useAuthStore(state => state.company);
    const isAuthenticated = useAuthStore(state => state.token?.length > 0)
    const loginDone = useAuthStore(state => state.loginDone)
    const { isB2B, customerData, setCartCompany } = useContext(CustomerContext);
    const costumerRelativeCompany = customerData.companies?.filter(c => customerData.itm_cardcode === c?.company_code)?.at(0)?.company_name;
    const pickUpButton = useRef();
    const router = useRouter()
    const [open, setOpen] = useState(false)
    const setCompany = useAuthStore(state => state.setCompany)
    const { data: cardCode } = useQuery(GET_COMPANY_CARD_CODE, {
        variables: { cart_id: cartId },
        skip: !(cartId && isAuthenticated && loginDone && isB2B), nextFetchPolicy: "cache-only"
    })
    const [createCart] = useMutation(CREATE_CART)
    const setCartId = useAuthStore((state) => state.setCartId)
    const setFullPageLoading = useGlobalStore((state) => state.setFullPageLoading)
    const loadingPage = useGlobalStore(state => state.loadingPage)
   
    // console.log(isB2B);
    
    useEffect(() => {
        if (cardCode && cartId && isB2B) {
            if (cardCode.companyCode.itm_cardcode) {
                let d = customerData.companies?.filter(c => c.company_code === cardCode.companyCode.itm_cardcode).at(0)
                if (d?.company_code !== company?.card_code) {
                    setCompany(d)
                }
            } else {
                const code = customerData.itm_cardcode || ""
                if (code !== company?.card_code) {
                    let comp = customerData.companies?.filter(c => c.company_code === code).at(0)
                    setCompany(comp)
                }
                setCartCompany({
                    variables: { card_code: code }
                })
            }
        }
    }, [cardCode]);

    const handleCompanySelection = (comp) => {
        setFullPageLoading(true)
        
        if (comp.children) {
            delete comp.children
            delete comp.__typename
        }
        setCompany(comp)
        setCartCompany({
            variables: { card_code: comp.company_code },
            onCompleted: () => {
                router.reload()
            }
        })
        setOpen(false)
    }


    return (
        isB2B ? (
            <Box
                sx={{
                    backgroundColor: cssVars.primary,
                    color: 'white',
                    height: { xs: "fit-content", lg: 40 },
                    position: "relative"
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }} className="container" >
                    <Box sx={{ "span": { fontSize: "14px !important " }, ".icon-svg:last-child": { display: "none" } }}>
                        <Grid container spacing={0} py={1}>
                            <Grid item xs={12} sm="auto">
                                <Typography component="span" sx={{ mr: 6 }}>
                                    {
                                        customerData.itm_cardcode !== company.company_code ?
                                            <BusinessIcon sx={{ verticalAlign: 'text-bottom', marginRight: 1, fontSize: { xs: 22, lg: 24 } }} />
                                            :
                                            <ShoppingBasketIcon sx={{ verticalAlign: 'text-bottom', marginRight: 1, fontSize: { xs: 22, lg: 24 } }} />
                                    }
                                    {costumerRelativeCompany}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                {
                                    customerData.itm_cardcode !== company.company_code ?
                                        <Typography component="span" ml={2}>
                                            <ShoppingBasketIcon sx={{ verticalAlign: 'text-bottom', marginRight: 1, fontSize: { xs: 22, lg: 24 } }} />
                                            {company?.company_name}
                                        </Typography>
                                        :
                                        null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Button
                            ref={pickUpButton}
                            sx={{
                                display: { xs: 'none', lg: 'inline-block' },
                                backgroundColor: cssVars.primary,
                                color: 'white',
                                borderRadius: cssVars.buttonRadius,
                                border: '1px solid white',
                                fontWeight: "500 !important",
                                maxHeight: '32px',
                            }}
                            size='small'
                            onClick={() => { setOpen(true); }}
                        >
                            {t("Change company")}
                        </Button>
                        <IconButton onClick={() => { setOpen(true) }} sx={{ p: 0 }}>
                            <MoreHorizIcon
                                fontSize='medium'
                                sx={{
                                    display: { lg: 'none' },
                                    color: 'white',
                                }}
                            />
                        </IconButton>

                    </Box>
                </Box>
                <SelectCompany open={open} setOpen={setOpen} onClick={(code) => handleCompanySelection(code)} />
            </Box>
        ) : null
    );
};

export default B2bBar;