import { gql } from "@apollo/client";

export const COMPARE_LIST_FRAGMENT = gql`
fragment compareListFragment on CompareList{
    uid
    item_count
    attributes{
      code
      label
    }
    items{
      uid
      product {
        id
        sku
        canonical_url
        name
        rating_summary
        is_available
        availability{
        status
        label
      }
      custom_stock_item{
          qty
          min_sale_qty
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value
          }
          quantity
        }
        # show_price_without_Tax
      display_tax {
        regular_price_without_tax
        regular_price_with_tax
        final_price_without_tax
        final_price_with_tax
        base_price
        price
        tax
        rate
  }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
              
            }
            final_price {
              value
            }
            fixed_product_taxes {
              amount {
                value
                
              }
              label
              
            }
            regular_price {
              value
              
            } 
          } 
        }
        small_image {
          url
          __typename
        }
        brand
        custom_attributes{
          attribute_metadata{
            code
          }
          entered_attribute_value{
            value
          }
          selected_attribute_options{
            attribute_option{
              label
              swatch_image
              uid
            }
          }
        }
      }
      attributes{
        code
        value
      }
    }
}
`
