import { gql } from "@apollo/client";

export const CART_ITEM_FRAGMENT = gql`
fragment CartItemFragment on CartItemInterface {
  uid
  quantity
  errors{
    message
  }
  prices {
    price {
      value
      currency
    }
    row_total {
      value
    }
    price_including_tax {
      value
    }
    row_total_including_tax{
      value
    }
  }
  product {
    sku
    name
    url_key
    clearance
    has_low_price
    negotiated_discount
    normal_discount
    small_image {
      url
    }
    price_range {
      maximum_price {
        discount {
          amount_off
        }
      }
    }
    availability{
        status
        label
      }
      custom_stock_item{
          qty
          min_sale_qty
        }
    only_x_left_in_stock
    available_after
  }
  ... on SimpleCartItem {
    customizable_options {
      label
      values{
        label
        value
      }
    }
  }
}
`;


export const CART_PRICES_FRAGMENT = gql`
fragment CartPricesFragment on Cart {
  prices {
    subtotal_excluding_tax {
      value
      currency
    }
    grand_total {
      value
    }
    applied_taxes {
      amount {
        value
      }
    }
    discounts {
      amount {
        value
      }
    }
  }
}
`