import { useState } from "react";
import { useQuery } from '@apollo/client'
import { GET_PLP_PRODUCTS } from "../../graphql/products/queries"
import { Box, Grid, Skeleton, Tab, Tabs, useTheme } from "@mui/material";
import Link from "next/link";
import Carousel from "../carousel";
import ProductCard from "../product-card";
import { GET_STORE_CONFIG } from "../../graphql/store/queries"
import cssVars from "../../styles/vars.module.scss"
import Image from "next/image";
import useTranslation from "../../hooks/useTranslation";

const ProductsCarouselWidget = ({ catsData }) => {

    const [activeCat, setActiveCat] = useState(catsData?.items?.at(0) || catsData)
    const skip = !Boolean(activeCat)
    const { data: productsData, loading: productsLoading } = useQuery(GET_PLP_PRODUCTS, { variables: { pageSize: 12, currentPage: 1, filters: { category_uid: { eq: activeCat.uid } } }, skip, context: { fetchOptions: { method: "GET" } } })
    const { data: storeConfigData, loading: storeConfigLoading } = useQuery(GET_STORE_CONFIG, { context: { fetchOptions: { method: "GET" } } })

    const storeConfig = storeConfigData?.storeConfig

    const responsive = {
        0: {
            items: 1,
            nav: false,
            dots: true,
            margin: 12
        },
        550: {
            items: 2,
            margin: 17,
            nav: false,
            dots: true
        },
        900: {
            items: 3,
            margin: 35,
            nav: false,
        },

        1200: {
            items: 4,
            margin: 35
        },
    }


    const theme = useTheme()

    const { t } = useTranslation()

    return (
        catsData || productsLoading
            ?
            <Box sx={{ my: 6 }}>
                {
                    activeCat
                        ?
                        <Grid display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: { xs: "29px", md: "23px" } }}>
                            <Link href={`/${catsData?.url_path}.html`}>
                                <Box component="h2" textTransform="uppercase" sx={{ fontSize: { xs: "22px", md: "30px" }, color: cssVars.extraDarkGray }}>{(catsData.title || catsData.name)}</Box>
                            </Link>
                            <Link href={"/" + catsData?.url_path + ".html"} style={{ textTransform: "uppercase", border: `1px solid ${cssVars.darkGray}`, borderRadius: cssVars.radius, padding: "4px 11px", color: cssVars.extraDarkGray, fontSize: "14px" }}>{t("More")}</Link>
                        </Grid>
                        :
                        null
                }
                <Grid container columns={{ xs: 3, lg: (catsData?.items?.length > 0 ? 3.75 : 3) }} columnSpacing={{ xs: 0, lg: (catsData?.items?.length > 0 ? 4 : 0) }} sx={{ mt: 2 }}>
                    {
                        catsData?.items?.length > 0
                            ?
                            <Grid item xs={0} lg={0.75}>
                                <Box position="relative" width="100%" height="100%">
                                    <Image src={catsData.image} alt={activeCat.uid + "-image"} fill style={{ objectFit: "cover", objectPosition: "center", borderRadius: "6px" }} />
                                </Box>
                            </Grid>
                            :
                            null
                    }
                    <Grid item xs={3}>
                        {
                            catsData?.items?.length > 0
                                ?
                                <Box mb={2.5} sx={{
                                    "& .MuiTabs-flexContainer": {
                                        pb: 1, display: "flex", width: "100%", flexWrap: "nowrap", overflow: "auto", alignItems: "center",
                                        "&::-webkit-scrollbar": { height: 6 },
                                        "&::-webkit-scrollbar-track": { background: '#F6F6F6' },
                                        "&::-webkit-scrollbar-thumb": { background: cssVars.gray, borderRadius: 20 },
                                    }
                                }}>
                                    <Tabs
                                        aria-label="details-package-specs-tabs"
                                        sx={{ minHeight: "unset", '& button .MuiTouchRipple-child': { bgcolor: 'unset', minWidth: "fit-content", display: "block" }, ' & button.active': { borderBottom: `3px solid ${theme.palette.primary.main}` }, mb: 2 }}
                                    >
                                        {
                                            catsData?.items?.map((cat, i) =>

                                                <Tab key={cat.uid} className={(activeCat.uid === cat.uid) ? "active" : ""} label={cat.name} onClick={() => { setActiveCat(cat) }} sx={{ minHeight: "unset", color: cssVars.black, fontSize: "1rem", fontWeight: '500 !important', textTransform: "capitalize", p: "0 0 10px 0", mr: "40px" }} />
                                            )
                                        }
                                    </Tabs>
                                </Box>
                                :
                                null
                        }
                        <Box key={(productsLoading || storeConfigLoading) + activeCat.uid}>
                            <Carousel
                                className="owl-theme products-carousel"
                                dots={true}
                                nav={true}
                                responsive={responsive}
                            >
                                {
                                    productsData?.products?.items?.length > 0 && storeConfig
                                        ?
                                        productsData?.products?.items?.map(product =>
                                            <ProductCard key={product.sku} product={product} storeConfig={storeConfig} />
                                        )
                                        :
                                        Array.from(Array((catsData?.items?.length > 0 ? 4 : 5))).map((_, i) =>
                                            <Skeleton variant="rectangular" key={i} width="100%" height={396} />
                                        )
                                }
                            </Carousel>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
            :
            null
    );
}

export default ProductsCarouselWidget;