import { gql } from "@apollo/client";
import { CART_ITEM_FRAGMENT, CART_PRICES_FRAGMENT } from "./fragments";

export const GET_CART_ITEMS_COUNT = gql`
query getCartItemsCount($cart_id: String!) {
  cartItemsCount:cart(cart_id: $cart_id) {
    total_quantity
  }
}
`;

export const GET_PRICING_DATE = gql`
query getCartPricingDate($cart_id: String!) {
  cartPricingDate:cart(cart_id: $cart_id) {
    itm_pricing_order_date
  }
}
`;

export const GET_CART_GUEST_EMAIL = gql`
query getGuestEmail($cart_id: String!) {
  guest: cart(cart_id: $cart_id) {
    email
  }
}
`

export const GET_CART_ITEMS = gql`
${CART_ITEM_FRAGMENT}
query getCartItems($cart_id: String!) {
  cartItems: cart(cart_id: $cart_id) {
    total_quantity
    items {
      ...CartItemFragment
    }
  }
}
`;


export const GET_CART_PRICES = gql`
${CART_PRICES_FRAGMENT}
query getCartPrices($cart_id: String!) {
  cartPrices: cart(cart_id: $cart_id) {
    total_quantity
    ...CartPricesFragment
  }
}
`;

export const GET_CART_SHIPPING_COST = gql`
query getCartShippingCost($cart_id: String!) {
  cartShippingCost: cart(cart_id: $cart_id) {
    shipping_addresses {
      selected_shipping_method {
        amount {
          value
        }
      }
    }
  }
}
`;


export const GET_CART_SHIPPING_ADDRESS = gql`
query getCartShippingAddress($cart_id: String!) {
  cartShippingAddress: cart(cart_id: $cart_id) {
    shipping_addresses {
      firstname
      lastname
      street
      city
      company
      region {
        region_id
        code
        label
      }
      country {
        code
        label
      }
      telephone
      postcode
      pickup_location_code
    }
  }
}
`;


export const GET_CART_BILLING_ADDRESS = gql`
query getCartBillingAddress($cart_id: String!) {
  cartBillingAddress: cart(cart_id: $cart_id) {
    billing_address {
      city
      company
      country {
        code
        label
      }
      firstname
      lastname
      postcode
      region {
        region_id
        code
        label
      }
      street
      telephone
    }
  }
}
`;

export const GET_AVAILABLE_SHIPPING_METHODS = gql`
query getAvailableShippingMethods($cart_id: String!) {
  availableShippingMethods: cart(cart_id: $cart_id) {
    shipping_addresses {
      available_shipping_methods {
        amount {
          value
        }
        price_excl_tax {
          value
          currency
        }
        carrier_code
        method_code
        method_title
        carrier_title
      }
    }
  }
}
`;

export const GET_CART_SELECTED_SHIPPING_METHOD = gql`
query getCartSelectedShippingMethod($cart_id: String!) {
  cartSelectedShippingMethod: cart(cart_id: $cart_id) {
    shipping_addresses {
      selected_shipping_method {
        amount {
          value
        }
        carrier_code
        method_code
        method_title
        carrier_title
      }
    }
    selected_store_code
  }
}
`;

export const GET_AVAILABLE_PAYMENT_METHODS = gql`
query getAvailablePaymentMethods($cart_id: String!) {
  availablePaymentMethods: cart(cart_id: $cart_id) {
    available_payment_methods {
      code
      title
    }
  }
}
`;

export const CHECKOUT_AGREEMENT = gql`
query checkOutAgreements{
  checkoutAgreements {
    agreement_id
    checkbox_text
    content
    content_height
    is_html
    mode
    name
  }
}
`;


export const GET_SELECTED_PAYMENT_METHOD = gql`
query getSelectedPaymentMethod($cart_id: String!) {
  selectedPaymentMethod: cart(cart_id: $cart_id) {
    selected_payment_method {
      code
      title
    }
  }
}
`;

export const GET_APPLIED_COUPONS = gql`
query getAppliedCoupons($cart_id: String!) {
  coupons: cart(cart_id: $cart_id) {
    applied_coupons {
      code
    }
  }
}
`;

export const GET_MONERIS_DATA = gql`
query getMonerisData($id:String!){
  moneris_order_getorderdata(id:$id){
    key
    value
  }
}
`
export const GET_CART_ERRORS = gql`
query getCartErrors($cart_id: String!) {
  getCartErrors: cart(cart_id: $cart_id) {
      error_message
    }
}
`;

//Walllee
export const WALLEE_GET_ORDER_TRANSACTION = gql`
query GetOrderTransactionSettings($order_number: String!, $integrationType: String!) {
  customerOrderTransaction(
    order_id: $order_number
    integration_type: $integrationType
  ) {
    order_id
    integration_type
    transaction_id
    transaction_state
    payment_url
  }
}
`
export const GET_PICKUP_LOCATIONS = gql`
query getPickupLocations($cart_id: String!) {
  pickupLocations: cart(cart_id: $cart_id) {
    storePickupList {
      code
      label
      store_email
      map_url
      store_telephone
      company
      address
      opening_hours {
        key
        value
        __typename
      }
      __typename
    }
  }
}
`;

export const GET_COMPANY_CARD_CODE = gql`
query getCompanyCardCode($cart_id: String!) {
  companyCode: cart(cart_id: $cart_id) {
    itm_cardcode
  }
}
`;

export const GET_CUSTOMER_REMARKS = gql`
query CustomerRemarks{
    customerRemarks{
      code
      regex
      required
  }
}
`;
