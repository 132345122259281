import CheckoutLayout from "./checkoutLayout";
import GeneralLayout from "./generalLayout";
import { useCart } from '../../hooks/cart/useCart'

const SubLayout = ({ pathname, children }) => {
    const c = useCart()
    const Layout = pathname !== '/checkout/[[...slug]]' ? GeneralLayout : CheckoutLayout
    return (<Layout path={pathname}>{children}</Layout>)
}

export default SubLayout;