/* eslint-disable @next/next/no-img-element */
import { Box, Typography } from "@mui/material";
import useTranslation from "../../../hooks/useTranslation";
import Carousel from "../../carousel";
import Link from "next/link";

import CampaignCard from "./campaignCard";
const Campaigns = ({ data }) => {
    console.log(data);
    
    const { t } = useTranslation()

    const responsive = {
        0: {
            items: 1,
            nav: false,
            margin: 12
        },
        767: {
            items: 2,
            nav: false,
            margin: 17
        },
        992: {
            items: 3,
            nav: false,
            margin: 35
        },
        1200: {
            nav: true,
            items: 3,
            margin: 35
        },
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Carousel
                id="main-campaigns"
                className="owl-theme"
                dots={true}
                responsive={responsive}
            >
                {
                    data?.map(item =>
                        <CampaignCard key={item.id} item={item} />
                    )
                }
            </Carousel>
        </Box>
    );
}

export default Campaigns;