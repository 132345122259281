import { gql } from "@apollo/client";
import { PRICE_FRAGMENT } from "../products/fragments";

export const MULTI_WISHLIST_FRAGMENT = gql`
${PRICE_FRAGMENT}
fragment WishlistsFragment on CustomerWishlist {
  total_count
  total_product_count
  page_info {
    current_page
    page_size
    total_pages
  }
  items {
    id
    wishlist_id
    customer_id
    title
    image
    sort_order
    product_count
    shared
    item_collection {
      items {
        parent_sku
        sku
        qty
        item_id
        product {
          sku
          name
          small_image {
            url
          }
          only_x_left_in_stock
          is_available
          availability{
            status
            label
          }
      custom_stock_item{
          qty
          min_sale_qty
        }
          ...PriceFragment
        }
      }
    }
  }
}
`
