import { createTheme } from '@mui/material/styles';
import cssVars from "../../styles/vars.module.scss"

const darkenColor = (hex, amount = 0.1) => {
  // If hex code has 3 digits, expand to 6 digits
  if (hex.length === 4) {
    hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }

  // Convert hex color to RGB values
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Darken each RGB value by the given amount
  r = Math.round(r * (1 - amount));
  g = Math.round(g * (1 - amount));
  b = Math.round(b * (1 - amount));

  // Convert RGB values back to hex color
  let result = ((r << 16) | (g << 8) | b).toString(16);
  while (result.length < 6) {
    result = '0' + result;
  }
  return '#' + result;
}

export const themes = {
  blueTheme: {
    name: "Blue Theme",
    palette: {
      mode: 'light',
      primary: {
        main: cssVars.primary,
        dark: darkenColor(cssVars.primary)
      },
      secondary: {
        main: cssVars.black
      },
    },
  },
}



export const getTheme = (selectedTheme) => {
  return createTheme(themes[selectedTheme])
}

export const availableThemes = () => Object.keys(themes).map(key => ({
  theme: key,
  label: themes[key].name,
  color: themes[key].palette.primary.main,
}))

export const addTheme = (theme) => {
  const key = theme.name.toLowerCase().replaceAll(" ", "")
  themes[key] = {
    name: theme.name,
    palette: {
      mode: 'light',
      primary: {
        main: theme.color,
        dark: darkenColor(theme.color)
      },
      secondary: {
        main: cssVars.black
      },
    },
  }
  return availableThemes()
}