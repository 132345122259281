import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import cssVars from '../../styles/header.module.scss'

const StockStatus = ({ product, variant = "minimal" }) => {
    const { label: title, status } = product.availability
    const containerProps = { p: "3px", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", width: 25, height: 25, borderRadius: "50%", "& svg": { mr: -0.13 } }
    console.log(product.availability);

    const icon = {
        'available': <Box sx={containerProps} bgcolor={cssVars.green} title={title}><CheckIcon fontSize='small' /></Box>,
        'available-soon': <Box sx={containerProps} bgcolor={cssVars.yellow} title={title}><Box component="span" sx={{ color: "#fff", fontSize: 18, fontWeight: 600, textAlign: "center" }} >i</Box></Box>,
        'available-later': <Box sx={containerProps} bgcolor={cssVars.yellow} title={title}><CheckIcon fontSize='small' /></Box>,
        'not-available': <Box sx={containerProps} bgcolor={cssVars.red} title={title}><CloseIcon fontSize='small' /></Box>
    }[status] || <Box sx={containerProps} bgcolor={cssVars.red} title={title}><CloseIcon fontSize='small' /></Box>

    return (
        <Box display="flex" alignItems="center">
            <Box>{icon}</Box>
            {
                variant === "full"
                    ?
                    <Box className="stock-text" sx={{ fontSize: 14, fontWeight: 600, color: cssVars.black, ml: 1 }}>{title}</Box>
                    :
                    null
            }
        </Box>
    )
}

export default StockStatus