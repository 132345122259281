import { Box, Container } from "@mui/material";
import Logo from "../logo";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import useTranslation from "../../hooks/useTranslation";
import { useContext } from "react";
import { CustomerContext } from "../../Providers/CustomerContext";
import cssVars from "../../styles/vars.module.scss"
import { useRouter } from "next/router";
import { useAuthStore } from "../../stores/authStore";
import { useQuery } from "@apollo/client";
import { GET_CART_GUEST_EMAIL } from "../../graphql/cart/queries";

const CheckoutLayout = ({ children }) => {

    const { t } = useTranslation()
    const { customerData } = useContext(CustomerContext)
    const router = useRouter()
    const cartId = useAuthStore(state => state.cartId)
    const isAuthenticated = useAuthStore(state => state.token).length > 0

    const { data } = useQuery(GET_CART_GUEST_EMAIL, { variables: { cart_id: cartId }, skip: (!cartId || isAuthenticated), nextFetchPolicy: "cache-only" })
    const email = data?.guest?.email || null

    return (
        <Box sx={{
            minHeight: '100vh',
            margin: 0,
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
        }}>
            <Box className="container" component="header" maxWidth="xl" sx={{ borderBottom: { xs: ` 1px solid ${cssVars.lightGray}`, lg: `1px solid ${cssVars.extraLightGray}` } }}>
                <Box display="flex" alignItems="center" justifyContent={{ xs: "left", lg: "space-between" }} pt={{ xs: "24px", lg: "48px" }} pb="24px">
                    <Box mr={{ xs: 4, lg: 0 }}>
                        <Box display={{ xs: "none", lg: 'block' }}>
                            <Logo />
                        </Box>
                        <Box display={{ xs: "flex", lg: "none" }} alignItems="center" justifyContent="center" borderRadius="50%" sx={{ width: 34, height: 34, padding: '9px', mr: "12px", bgcolor: cssVars.extraLightGray }} onClick={() => router.replace('/checkout/cart')}>
                            <ArrowBackIcon fontSize="small" />
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" fontWeight={500} >
                        <VerifiedUserIcon htmlColor={cssVars.green} sx={{ mr: 1 }} />
                        {t("Secure Checkout")}
                    </Box>
                    <Box display={{ xs: "none", lg: 'block' }}>{customerData.firstname && customerData.lastname ? customerData.firstname + ' ' + customerData.lastname : email}</Box>
                </Box>
            </Box>
            <Box component="main">
                {children}
            </Box>
            <Box component="footer" bgcolor={cssVars.black} color={cssVars.white}>
                <Container className="container" maxWidth="xl">
                    <Box py={3} textAlign="center" fontSize={12} >
                        Copyright 2024 © Bugnard. Inc. {t("All rights reserved")}
                    </Box>
                </Container>
            </Box>
        </Box >
    );
}

export default CheckoutLayout;
