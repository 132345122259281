import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import { GET_CMS_BLOCK } from "../../graphql/cms/queries";
import { useCacheStore } from '../../stores/cacheStore'
import { CmsPageParser } from "../cms-parser";
import { useMediaQuery } from "@mui/material";

const Footer = () => {
    const footerData = useCacheStore(s => s.footer)
    const setFooterData = useCacheStore(s => s.setFooter)
    const cacheReady = useCacheStore(s => s.ready)

    const { data } = useQuery(GET_CMS_BLOCK, { variables: { id: 'new_footer' }, skip: (footerData || !cacheReady), context: { fetchOptions: { method: "GET" } } })
    const router = useRouter()
    useEffect(() => {
        if (footerData) {
            const links = document.querySelectorAll('#footer a')
            links.forEach(element => {
                element.addEventListener("click", (e) => {
                    e.preventDefault()
                    if (element.getAttribute('target') === '_blank') {
                        window.open(element.getAttribute('href'))
                    }
                    else if (element.getAttribute('href').startsWith('#') && element.getAttribute('href').length > 1) {
                        document.getElementById(element.getAttribute('href').substring(1)).scrollIntoView({ behavior: 'smooth' })
                    }
                    else {
                        router.push(element.getAttribute('href'))
                    }
                })
            });
        }
    }, [footerData])

    useEffect(() => {
        if (data) {
            setFooterData(data)
        }
    }, [data])


    return (
        <footer id="new-footer" className="container" style={!footerData ? { height: 306 } : {}}>
            <CmsPageParser id={"main-website-footer"} useContainer={false}>
                {footerData?.cmsBlocks.items[0].content}
            </CmsPageParser>
        </footer>
    );
}

export default Footer;