import { gql } from "@apollo/client"
import { MULTI_WISHLIST_FRAGMENT } from "./fragments";

export const CREATE_WISHLIST = gql`
${MULTI_WISHLIST_FRAGMENT}
mutation createWishList_v2(
  $title: String!
  $sort_order: Int
  $pageSize: Int = 200
  $currentPage: Int = 1
) {
  wishlists:createWishList_v2(
    input: { title: $title, sort_order: $sort_order }
    pageSize: $pageSize
    currentPage: $currentPage
  ) {
    ...WishlistsFragment
    __typename
  }
}
`

export const ADD_PRODUCT_TO_CUSTOM_WISH_LIST = gql`
${MULTI_WISHLIST_FRAGMENT}
mutation assignProductsToWishList_v2($wishlistId:String!,$sku:String!,$qty:Float!){
  wishlists:assignProductsToWishList_v2(
      wishlistId: $wishlistId
      assignType: ADD
      input: {
        items: [
          {
            sku:$sku
            parent_sku:$sku
            qty:$qty
          }
        ]
      }
    ) {
      ...WishlistsFragment
    }
}
`

export const REMOVE_PRODUCT_FROM_CUSTOM_WISHLIST = gql`
${MULTI_WISHLIST_FRAGMENT}
mutation removeItemFromWishList_v2($itemId:String!) {
  wishlists:removeItemFromWishList_v2(itemId:$itemId) {
    ...WishlistsFragment
  }
}
`;

export const REMOVE_CUSTOMER_WISHLIST = gql`
${MULTI_WISHLIST_FRAGMENT}
mutation deleteCustomerWishList_v2($wishlistId: String!){
  wishlists:deleteCustomerWishList_v2(wishlistId: $wishlistId) {
    ...WishlistsFragment
  }
}
`;

export const UPDATE_CUSTOM_WISHLIST = gql`
${MULTI_WISHLIST_FRAGMENT}
mutation updateWishList_v2($wishlistId: String!, $title: String!, $sort_order: Int $comments: String, $image_base64: String){
  wishlists:updateWishList_v2(wishlistId: $wishlistId, input: {
    title: $title,
    sort_order: $sort_order,
    comments: $comments,
    image_base64: $image_base64
  }) {
    ...WishlistsFragment
  }
}
`;



