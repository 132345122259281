import React from 'react';
import { Badge as MuiBadge, Box, Typography } from '@mui/material';


const Badge = ({ children, text, value, color, style = {}, className = "" }) => {
  return (
    <Box className={className} sx={{ ...style, display: 'inline-flex', alignItems: 'center', "& .MuiBadge-badge": { top: "-12px !important", right: "-2px !important" } }}>
      {children}
      {value ? (
        <MuiBadge
          badgeContent={value}
          color="primary"
          className='roboto'
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: color,
              fontFamily: "inherit !important",
              fontSize:14,
              
            },
          }}
        />
      ) : null}
      {text ? (
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          {text}
        </Typography>
      ) : null}
    </Box>
  );
};

export default Badge;