import { Box } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_STORE_CONFIG } from "../../graphql/store/queries";
import { useTheme } from "@mui/material";
import { useCacheStore } from "../../stores/cacheStore";

const Logo = () => {


    const storeConfig = useCacheStore(s => s.storeConfig)
    const setStoreConfig = useCacheStore(s => s.setStoreConfig)
    const cacheReady = useCacheStore(s => s.ready)
    const { data } = useQuery(GET_STORE_CONFIG, { skip: (storeConfig || !cacheReady), context: { fetchOptions: { method: "GET" } } })

    const whiteDot = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

    useEffect(() => {
        if (data) {
            setStoreConfig(data.storeConfig)
        }
    }, [data])

    return (
        <Link href="/">
            <Box height={91} sx={{ display: { xs: "none", sm: "block" } }} className="logo-container">
                <Image width={388} height={91} className="logo" src={(!storeConfig ? whiteDot : process.env.NEXT_PUBLIC_LOGO_BASE_URL + storeConfig.header_logo_src)} alt={storeConfig?.logo_alt} style={{ objectFit: "contain", objectPosition: "center" }} />
            </Box>
            <Box height={62} sx={{ display: { sm: "none" } }} className="logo-container">
                <Image width={264} height={62} className="logo" src={(!storeConfig ? whiteDot : process.env.NEXT_PUBLIC_LOGO_BASE_URL + storeConfig.header_logo_src)} alt={storeConfig?.logo_alt} style={{ objectFit: "contain", objectPosition: "center" }} />
            </Box>
        </Link>
    );
}

export default Logo;
