import { Box, Container, Grid, useTheme } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const CategoryCards = ({ cats }) => {
    const theme = useTheme()
    return (
        <Box my={10}>
            <Grid container columns={{ xs: 1, sm: 2, lg: 3 }} spacing="20px">
                {
                    cats.map(cat =>
                        <Grid key={cat.uid} item xs={1} >
                            <Link href={`/${cat.url_path}.html`} style={{ borderRadius: "6px" }}>
                                <Box borderRadius={"6px"} sx={{ "&:hover .text": { bgcolor: theme.palette.primary.main, color: "#fff" } }} >
                                    <Box paddingTop={{ xs: "50%", sm: "66.6%" }} position="relative" borderRadius={"6px 6px 0px 0px"}>
                                        <Image alt={cat.uid + "-image"} src={cat.image} fill style={{ objectFit: "cover", objectPosition: "center", borderRadius: "6px 6px 0px 0px" }} />
                                        {/* <Image src={PhImage} fill style={{ objectFit: "cover", objectPosition: "center", borderRadiu: "6px" }} /> */}
                                    </Box>
                                    <Box borderRadius={"0px 0px 6px 6px"} className="text" bgcolor={"#F5F5F5"} px={{ xs: 2, sm: 3 }} py={{ xs: 1, sm: 1.5 }} fontSize={{ xs: 16, sm: 20 }} fontWeight={500} display="flex" alignItems="center" justifyContent="space-between">
                                        <Box height={{ xs: 48, sm: 60 }} display="flex" alignItems="center"><Box className="dots2">{cat.name}</Box></Box>
                                        <Box display={{ xs: "none", sm: "block" }}><ChevronRightIcon fontSize="large" /></Box>
                                        <Box display={{ xs: "block", sm: "none" }}><ChevronRightIcon fontSize="medium" /></Box>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
}

export default CategoryCards;