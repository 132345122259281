import { Box, SwipeableDrawer, Modal, useMediaQuery, useTheme } from "@mui/material";

interface DrawerOrModalTypeProps {
    open: boolean
    setOpen: (open: boolean) => void
    children: React.ReactNode
    anchor: "left" | "right" | "top" | "bottom"
    id?: string
}

const DrawerOrModal = ({ children, open, setOpen, anchor, id }: DrawerOrModalTypeProps) => {

    const theme = useTheme()

    const matchesLg = useMediaQuery('(min-width:1200px)');
    const styles =
        matchesLg
            ?
            {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                borderRadius: `${theme.shape.borderRadius}px`,
                backgroundColor: "#fff",
                outline: "none"
            }
            :
            {}

    const Component = matchesLg ? Modal : SwipeableDrawer

    return (
        <Component
            id={id}
            anchor={anchor}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            disableSwipeToOpen
            PaperProps={{ sx: { borderRadius: (anchor === "bottom" ? "20px 20px 0px 0px" : 0) } }}
            sx={{ ".MuiPaper-root": { backgroundColor: "#fff" } }}
        >
            <Box sx={{ p: (anchor !== "bottom" ? "24px 16px" : "12px 0px"), ...styles }}>
                {!matchesLg && anchor === "bottom" ? <Box width={50} height={7} bgcolor="divider" mx="auto" mb="30px" borderRadius="20px" /> : null}
                {children}
            </Box>
        </Component >
    )
}

export default DrawerOrModal
