import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../products/fragments";

export const GET_WISHLIST = gql`
${PRODUCT_FRAGMENT}
query customerWishlist($currentPage: Int!, $pageSize: Int!) {
  customer {
    wishlists {
      id
      items_count
      items_v2(currentPage: $currentPage, pageSize: $pageSize) {
        page_info {
          current_page
          page_size
          total_pages
        }
        items {
          id
          quantity
          ... on ConfigurableWishlistItem{
            configurable_options{
              configurable_product_option_uid 
              configurable_product_option_value_uid
              option_label
              value_label
            }
          }
          product {
            ...ProductFragment
          }
        }
      }
    }
  }
}
`;
