import { Box, List, ListItem, Skeleton } from "@mui/material";
import Link from "next/link";
import CategoryIcon from '@mui/icons-material/Category';
import Image from "next/image";
import PriceBox from "../price-box";
import cssVars from "../../styles/vars.module.scss"
import useTranslation from "../../hooks/useTranslation";
import { useContext } from "react";
import { strEncode } from "../../helpers/SearchParams";
import { SearchContext } from "../../Providers/SearchProvider";
import { useTheme } from "@mui/material";

const SearchRes = ({ onNavigate }) => {
    const { t } = useTranslation()

    const { popularSearches, categoriesSearches, productsSearches, popularLoading, categoriesLoading, productsLoading } = useContext(SearchContext)
    const theme = useTheme()

    return (
        <>
            <Box className="popular-searches"
                display={(popularSearches?.getPopularSearches.items.length > 0 || popularLoading) ? 'block' : 'none'}>
                <Box px="18px" py="12px" fontSize={14} fontWeight={600} bgcolor={cssVars.extraLightGray}>
                    {t("Popular Searches")}
                </Box>
                <Box className="mememem" px="18px" py="6px" display="flex" flexWrap="wrap">
                    {
                        popularSearches && !popularLoading
                            ?
                            popularSearches.getPopularSearches.items.map(item =>
                                <Box key={item.name} sx={{ "& .amsearch-highlight": { color: theme.palette.primary.main, fontWeight: 500 } }} mb={1}>
                                    <Link href={`/catalogsearch/result/${strEncode('{"q":"' + item.url.split('?q=').at(1) + '"}')}`}
                                        style={{
                                            border: `1px solid ${cssVars.gray}`,
                                            borderRadius: '15px',
                                            padding: "4px 16px",
                                            fontSize: "14px",
                                            margin: '4px',
                                            textTransform: "capitalize"
                                        }}
                                        dangerouslySetInnerHTML={{ __html: item.name }}
                                        onClick={onNavigate}
                                    />
                                </Box>
                            )
                            :
                            Array.from(Array(5)).map((x, i) =>
                                <Skeleton key={i} variant="text" height={43.34} width={90} sx={{ borderRadius: "15px", margin: '4px', }}
                                />
                            )
                    }

                </Box>
            </Box>
            <Box className="categories-searches"
                display={(categoriesSearches?.getCategoriesSearches.items.length > 0 || categoriesLoading) ? 'block' : 'none'}>
                <Box px="18px" py="12px" fontSize={14} fontWeight={600} bgcolor={cssVars.extraLightGray}>{t("Categories")}</Box>
                <List sx={{ p: "10px 0", fontSize: "14px", textTransform: "capitalize" }} >
                    {
                        categoriesSearches && !categoriesLoading
                            ?
                            categoriesSearches.getCategoriesSearches.items.slice(0, 5).map(item =>
                                <ListItem disablePadding key={item.name}>
                                    <Link href={'/' + item.url} style={{ display: "flex", padding: "10px 22px", width: '100%' }} onClick={onNavigate}>
                                        <CategoryIcon sx={{ mr: '8px', color: cssVars.lightGray, fontSize: 16, mb: 0.3 }} />
                                        <Box dangerouslySetInnerHTML={{ __html: item.name }} sx={{ "& .amsearch-highlight": { color: theme.palette.primary.main, fontWeight: 500 } }} />
                                    </Link>
                                </ListItem>
                            )
                            :
                            Array.from(Array(5)).map((x, i) =>
                                <ListItem disablePadding key={i}>
                                    <Box sx={{ display: "flex", p: "10px 22px", width: '100%' }}>
                                        <CategoryIcon sx={{ mr: '8px', color: cssVars.lightGray, fontSize: 16, mb: 0.3 }} />
                                        <Skeleton width="25%" />
                                    </Box>
                                </ListItem>

                            )
                    }
                </List>
            </Box>
            {
                productsLoading
                    ?
                    <Box className="products-searches">
                        <Box sx={{ px: "18px", py: "12px", bgcolor: cssVars.extraLightGray, color: cssVars.white }} >
                            <Skeleton variant="rectangular" width="25%" sx={{ bgcolor: cssVars.extraLightGray, color: cssVars.black }} />
                        </Box>
                        <List sx={{ p: "10px 0 !important", fontSize: "14px", textTransform: "capitalize" }} onClick={onNavigate}>
                            {
                                Array.from(Array(8)).map(i_ =>
                                    <ListItem disablePadding key={i_}>
                                        <Box sx={{ display: "block", padding: "10px 22px", width: '100%' }}>
                                            <Box display="flex" alignItems="center">
                                                <Skeleton variant="rectangular" width={54} height={54} />
                                                <Box className="details" fontSize={13} ml={2} width="calc(100% - 70px)">
                                                    <Skeleton width="40%" sx={{ color: theme.palette.primary.main, fontWeight: 500 }} variant="text" />
                                                    <Skeleton width="80%" sx={{ my: 0.5 }} />
                                                    <Skeleton width="25%" sx={{ color: theme.palette.primary.main, fontWeight: 500 }} variant="text" />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Box>
                    :
                    productsSearches?.getProductsSearches.items.length > 0
                        ?
                        <Box className="products-searches">
                            <Box px="18px" py="12px" fontSize={14} fontWeight={600} bgcolor={cssVars.extraLightGray}>{t("Products")}</Box>
                            <List sx={{ p: "10px 0 !important", fontSize: "14px", textTransform: "capitalize", maxHeight: 500, overflow: "auto" }} onClick={onNavigate}>
                                {
                                    productsSearches.getProductsSearches.items.map(product =>
                                        <ListItem disablePadding key={product.sku}>
                                            <Link href={'/' + product.canonical_url} style={{ display: "block", padding: "10px 22px", width: '100%' }}>
                                                <Box display="flex" alignItems="center">
                                                    <Image width={54} height={54} style={{ objectFit: "contain", objectPosition: "center" }} alt={product.name} src={product.small_image.url} />
                                                    <Box className="details" fontSize={13} ml={2}>
                                                        <Box sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                                                            {

                                                                product?.brand
                                                                    ?
                                                                    product?.custom_attributes?.filter(attr => attr?.attribute_metadata.code === "brand")[0]?.selected_attribute_options?.attribute_option[0]?.label
                                                                    :
                                                                    null

                                                            }
                                                        </Box>
                                                        <Box dangerouslySetInnerHTML={{ __html: product.name }} sx={{ my: 0.5, "& .amsearch-highlight": { color: theme.palette.primary.main, fontWeight: 500 } }} />
                                                        <PriceBox product={product} variant="search" />
                                                    </Box>
                                                </Box>
                                            </Link>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Box>
                        :
                        null
            }
        </>
    );
}

export default SearchRes;