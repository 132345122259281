import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { createJSONStorage, persist } from 'zustand/middleware';


interface GlobalStoreType {
    miniCartOpen: boolean
    openMiniCart: () => void
    closeMiniCart: () => void

    selectedTheme: string
    changeTheme: (theme: string) => void

    loadingPage: boolean
    startLoadingPage: () => void
    stopLoadingPage: () => void

    listsDrawerOpen: boolean
    openListsDrawer: () => void
    closeListsDrawer: () => void
    listsDrawerSku: string
    setListDrawerSku: (sku: string) => void
    currentList: string
    setCurrentList: (id: string) => void

    listsModalOpen: boolean
    openListsModal: () => void
    closeListsModal: () => void
    listsModalAction: string,
    setListsModalAction: (action: string) => void,

    openOrderDate: boolean
    setOpenOrderDate: (openOrderDate: boolean) => void
    lastOrder: string | null
    setLastOrder: (orderId: string | null) => void

    fullPageLoading: boolean
    setFullPageLoading: (fullPageLoading: boolean) => void

    includeTaxModified: boolean
    includeTax: boolean
    setIncludeTax: (includeTax: boolean, includeTaxModified?: boolean) => void

    aribaPunchOutId: string
    setAribaPunchOutId: (id: string) => void
    isAriba: boolean

    pickup: string
    setPickup: (id: string) => void



    openCalibrationModal: boolean
    setOpenCalibrationModal: (openCalibrationModal: boolean) => void

    calibrationProduct: any
    setCalibrationProduct: (product: any) => void
}

export const useGlobalStore = create<GlobalStoreType>()(
    persist(
        (set, get) => ({
            miniCartOpen: false,
            openMiniCart: () => {
                set(() => ({ miniCartOpen: true }));
            },
            closeMiniCart: () => {
                set(() => ({ miniCartOpen: false }));
            },
            selectedTheme: "blueTheme",
            changeTheme: (theme) => {
                set(() => ({ selectedTheme: theme }))
            },
            loadingPage: false,
            startLoadingPage: () => {
                set(() => ({ loadingPage: true }))
            },
            stopLoadingPage: () => {
                set(() => ({ loadingPage: false }))
            },

            listsDrawerOpen: false,
            openListsDrawer: () => {
                set(() => ({ listsDrawerOpen: true }));
            },
            closeListsDrawer: () => {
                set(() => ({ listsDrawerOpen: false }));
            },
            listsDrawerSku: "",
            setListDrawerSku: (sku) => {
                set(() => ({ listsDrawerSku: sku }));
            },
            currentList: "",
            setCurrentList: (id) => {
                set(() => ({ currentList: id }));
            },

            listsModalOpen: false,
            openListsModal: () => {
                set(() => ({ listsModalOpen: true }));
            },
            closeListsModal: () => {
                set(() => ({ listsModalOpen: false, currentList: "", listsModalAction: "create" }));
            },
            listsModalAction: "create",
            setListsModalAction: (action) => {
                set(() => ({ listsModalAction: action }));
            },
            openOrderDate: false,
            setOpenOrderDate: (openOrderDate) => {
                set(() => ({ openOrderDate }));
            },
            fullPageLoading: false,
            setFullPageLoading: (fullPageLoading) => {
                set(() => ({ fullPageLoading }));
            },
            lastOrder: null,
            setLastOrder: (orderId) => {
                set(() => ({ lastOrder: orderId }))
            },
            includeTaxModified: false,
            includeTax: false,
            setIncludeTax: (includeTax, includeTaxModified = true) => {
                set(() => ({ includeTax, includeTaxModified }));
            },
            isAriba: false,
            aribaPunchOutId: '',
            setAribaPunchOutId: (id) => {
                set(() => ({ aribaPunchOutId: id, isAriba: id.length > 0 }))
            },
            pickup: "",
            setPickup: (id) => {
                set(() => ({ pickup: id }))
            },
            openCalibrationModal: false,
            setOpenCalibrationModal: (openCalibrationModal) => {
                set(() => ({ openCalibrationModal }))
            },
            calibrationProduct: null,
            setCalibrationProduct: (product) => {
                set(() => ({ calibrationProduct: product }))
            },
        }),
        {
            name: "globalStore",
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                includeTaxModified: state.includeTaxModified,
                includeTax: state.includeTax,
            })
        }
    )
);

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('globalStore', useGlobalStore);
}
