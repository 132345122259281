import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { ADD_PRODUCT_TO_COMPARE_LIST, ASSIGN_COMPARE_LIST_TO_CUSTOMER, CREATE_COMPARE_LIST, REMOVE_PRODUCT_FROM_COMPARE_LIST } from '../graphql/comparelist/mutaitions';
import { GET_COMPARE_LIST } from '../graphql/comparelist/queries';
import useTranslation from '../hooks/useTranslation';
import { useAuthStore } from '../stores/authStore';

export const CompareListContext = createContext({})

export const CompareListProvider = ({ children }) => {

	const isAuthenticated = useAuthStore((state) => state.token).length > 0

	const [customerCompareListID, setCustomerCompareListID] = useState('undefined');
	const [guestCompareListID, setGuestCompareListID] = useState('undefined');
	const [compareList, setCompareList] = useState({})
	const [skip, setSkip] = useState(true)
	const [addRemoveLoading, setAddRemoveLoading] = useState([])

	const { data: data1, error: compareListError } = useQuery(GET_COMPARE_LIST, { variables: { uid: (isAuthenticated ? customerCompareListID : guestCompareListID) }, skip: skip })

	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()

	const [addProductToCompareList, { data: addData, loading: addProductToCompareListLoading }] = useMutation(ADD_PRODUCT_TO_COMPARE_LIST, {
		onCompleted: (data) => {
			if (data) {
				enqueueSnackbar(t("Product has been added successfully."), { variant: 'success' });
			} else {
				enqueueSnackbar(t("Something went wrong. Please try again."), { variant: 'error' })
			}
		},
		onError: (err) => {
			setAddRemoveLoading([])
			enqueueSnackbar(err.message, { variant: 'error' })
		}
	})

	const [removeProductFromCompareList, { data: removeData, loading: removeProductFromCompareListLoading }] = useMutation(REMOVE_PRODUCT_FROM_COMPARE_LIST, {
		onCompleted: (data) => {
			if (data) {
				enqueueSnackbar(t("Product has been removed successfully."), { variant: 'success' });
			} else {
				enqueueSnackbar(t("Something went wrong. Please try again."), { variant: 'error' })
			}
		},
		onError: (err) => {
			setAddRemoveLoading([])
			enqueueSnackbar(err.message, { variant: 'error' })
		}
	})
	const [mergeCompareLists] = useMutation(ASSIGN_COMPARE_LIST_TO_CUSTOMER)

	const [createEmptyCompareList, { data: createData }] = useMutation(CREATE_COMPARE_LIST, {
		onCompleted: (data) => {
			if (!isAuthenticated) {
				setGuestCompareListID(data.action.uid)
				localStorage.setItem('guestCompareListID', JSON.stringify(data.action.uid))
				setCustomerCompareListID('')
				localStorage.setItem('customerCompareListID', JSON.stringify(''))
			} else {
				setGuestCompareListID("")
				localStorage.setItem('guestCompareListID', JSON.stringify(''))
				setCustomerCompareListID(data.action.uid)
				localStorage.setItem('customerCompareListID', JSON.stringify(data.action.uid))
			}
		}
	})

	useEffect(() => {
		setGuestCompareListID(JSON.parse(localStorage.getItem('guestCompareListID')))
		if (!isAuthenticated) {
			if (guestCompareListID !== "undefined") {
				if (!guestCompareListID || guestCompareListID === "") {
					createEmptyCompareList()
				}
			}
		}
		else {
			setCustomerCompareListID(JSON.parse(localStorage.getItem('customerCompareListID')))
			if ((guestCompareListID !== "undefined") && (guestCompareListID > "")) {
				mergeCompareLists({
					variables: {
						uid: guestCompareListID,
					},
					onCompleted: (data) => {
						if (data) {
							setCustomerCompareListID(data.action.compare_list.uid)
							localStorage.setItem('customerCompareListID', JSON.stringify(data.action.compare_list.uid))
							setGuestCompareListID('')
							localStorage.setItem('guestCompareListID', JSON.stringify(''))
						}
					}
				})
			}
		}
	}, [isAuthenticated, guestCompareListID])

	useEffect(() => {
		if (compareListError) {
			createEmptyCompareList()
		}
	}, [compareListError])

	useEffect(() => {
		if (isAuthenticated && customerCompareListID && customerCompareListID !== "undefined") {
			setSkip(false)
		}
	}, [customerCompareListID, isAuthenticated])

	useEffect(() => {
		if (guestCompareListID && guestCompareListID !== "undefined") {
			setSkip(false)
		}
	}, [guestCompareListID])

	useEffect(() => {
		if (data1 && data1.compareList) {
			setCompareList(data1.compareList)
			setSkip(true)
		}
		else if (data1 && data1.compareList === null) {
			createEmptyCompareList()
		}
	}, [data1])

	useEffect(() => {
		if (addData) {
			setCompareList(addData.action)
		}
	}, [addData])

	useEffect(() => {
		if (removeData) {
			setCompareList(removeData.action)
		}
	}, [removeData])

	useEffect(() => {
		if (createData)
			setCompareList(createData.action)
	}, [createData])

	const isCompared = useCallback((id) => {
		return compareList?.items?.filter(obj => obj.uid === String(id)).length > 0
	}, [compareList])

	const handleClick = useCallback((id) => {
		setAddRemoveLoading([...addRemoveLoading, id])
		if (isCompared(id)) {
			removeProductFromCompareList({
				variables: {
					uid: compareList.uid,
					productID: id
				},
			})
		}
		else {
			addProductToCompareList({
				variables: {
					uid: compareList.uid,
					productID: id,
				},
			});
		}
	}, [compareList, addRemoveLoading])

	useEffect(() => {
		setAddRemoveLoading([])
	}, [compareList])


	const contextValue = useMemo(() => (
		{
			compareList,
			compareListLoading: !skip,
			isCompared,
			handleClick,
			addProductToCompareList,
			removeProductFromCompareList,
			addRemoveLoading,
			removeProductFromCompareListLoading
		}
	), [
		compareList,
		isCompared,
		handleClick,
		addProductToCompareList,
		removeProductFromCompareList,
		addRemoveLoading,
		removeProductFromCompareListLoading,
		skip
	]
	)

	return (
		<CompareListContext.Provider value={contextValue} >
			{children}
		</CompareListContext.Provider>
	)
}

