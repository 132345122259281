import Link from "next/link";
import Carousel from "../../carousel";
import { Box, Grid, Typography } from "@mui/material";
import cssVars from "../../../styles/vars.module.scss";
import useTranslation from "../../../hooks/useTranslation";

const Banners = ({ data }) => {
    const { t } = useTranslation()
    const responsive = {
        0: {
            nav: false,
            margin: 12
        },
        1200: {
            nav: true,
            margin: 35
        },
    }
    return (
        <Carousel
            id="main-banner"
            className="owl-theme"
            items={1}
            nav={true}
            dots={true}
            responsive={responsive}
        >
            {
                data?.map((item, i) =>
                    <Link
                        key={item.id}
                        href={(item.link || "#")}
                        style={{ padding: "0 0 9px", marginBottom: "-8px", display: "block", textDecoration: "none", display: "block", width: "100%" }}
                    >
                        <Grid container columns={{ xs: 1, lg: 12 }} sx={{ pt: { xs: '1.2rem', lg: 7 }, boxShadow: cssVars.shadow, border: `1px solid ${cssVars.darkGray}`, borderRadius: cssVars.radius, px: 0 }}>
                            <Grid item xs={1} lg={5} className="col1 col-lg-5" sx={{ p: "0 1.5rem" }}>
                                <Box sx={{
                                    color: item.color, display: "inline-flex",
                                    borderBottom: '4px solid currentColor',
                                    padding: '8px 0',
                                    '& h3': {
                                        fontSize: '1.5rem',
                                        lineHeight: '1.625rem',
                                        color: cssVars.lightBlack,
                                        textTransform: 'uppercase',
                                        fontWeight: 400,
                                    },
                                    '& svg, & img': {
                                        height: '1.555rem !important',
                                        width: 'auto !important',
                                    },
                                }}>
                                    <img src={item.icon} alt="icon" />
                                    <Typography component="h3" sx={{ ml: 1, textTransform: "uppercase" }} >{item.title}</Typography>
                                </Box>

                                <Box sx={{
                                    mt: 1.5,
                                    '& .sheet-p': {
                                        fontSize: '1.125rem',
                                        color: cssVars.black,
                                        lineHeight: '1.8rem',
                                    },
                                    '& .sheet-d': {
                                        fontSize: '0.875rem',
                                        color: cssVars.extraDarkGray,
                                    },
                                }}>
                                    <Box className="sheet-p dots4" sx={{ minHeight: { xs: 132, md: 87 } }} dangerouslySetInnerHTML={{ __html: item.content }}></Box>
                                    <Box className="sheet-d" >{t(`Valid until`)} {item.toDate}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1} lg={7}
                                sx={{
                                    minHeight: '462px',
                                    backgroundPosition: 'bottom -4px right -4px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'auto 100%',
                                    backgroundImage: `url(${item.image})`,
                                    borderBottomRightRadius: cssVars.radius,
                                    height: { xs: 230, lg: "100%" },
                                }}
                            />

                        </Grid>
                    </Link>
                )
            }
        </Carousel >
    );
}

export default Banners;