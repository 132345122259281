import { gql } from "@apollo/client";

export const RECOMMENDATION_SUGGESTIONS = gql`
fragment RecommendationSuggestions on Products{
    advanced_search_suggested_queries(search: $inputText) {
      title
      query_text {
        link
        text
      }
    }
    advanced_search_recommendations(search: $inputText) {
      title
      query_text {
        link
        text
      }
    }
  }
`;