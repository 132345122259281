import { gql } from "@apollo/client";

export const STORE_FRAGMENT = gql`
fragment StoreFragment on StoreConfig{
    id
    store_name
    website_name
    store_code
    base_currency_code
    locale
    is_default_store
    additional_config_data
    base_currency_code
    base_url
    base_link_url
    base_media_url
    default_title
    default_description
    default_keywords
    default_display_currency_code
    head_includes
    no_route
    root_category_uid
    timezone
    logo_alt
    header_logo_src
    brand_category_id
    selections_category_id
    # customer_type_professional
    # customer_type_distributor
}
`;

export const STORE_BLOG_FRAGMENT = gql`
fragment StoreBlogFragment on StoreConfig{
    blog_seo_meta_title
    blog_seo_meta_keywords
    blog_seo_meta_description
}
`;

