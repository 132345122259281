/* eslint-disable @next/next/no-img-element */
import Box from '@mui/material/Box';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import cssVars from '../../styles/vars.module.scss';
import useTranslation from '../../hooks/useTranslation';

const BrandCard = ({ cat, productImgUrl }) => {
    const { url, title: name, item_count: count, thumbnail: image_url } = cat;
    const { t } = useTranslation()
    console.log(cat);

    const image = (image_url ? (image_url.startsWith("http") ? image_url : process.env.NEXT_PUBLIC_BASE_URL + image_url) : null);
    if (image) {
        console.log(image);
    }

    return (

        <Box
            sx={{
                "& a": {
                    display: 'block',
                    textDecoration: 'none',
                    borderRadius: cssVars.radius,
                    border: `1px solid ${cssVars.darkGray}`,
                    boxShadow: cssVars.shadow,
                    '& .product-img, & .img': {
                        height: '100px',
                        padding: '1.6rem',
                        '& img': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                    },
                    '& .product-img': {
                        height: '145px',
                        padding: '34px 42px 0 42px',
                    },
                    '& .card-details': {
                        boxShadow: cssVars.shadow,
                        borderTop: `1px solid ${cssVars.darkGray}`,
                        height: '55px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .number': {
                            fontSize: '16px',
                            lineHeight: '30px',
                            color: cssVars.black,
                            fontWeight: 600,
                            textAlign: 'center',
                            '& .articles': {
                                fontSize: '18px',
                                lineHeight: '22px',
                                fontWeight: 500,
                                color: cssVars.extraDarkGray,
                            },
                        },
                    },
                    '& .brand-name': {
                        fontWeight: 600,
                        color: cssVars.lightBlack,
                        lineHeight: 1,
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '25px',
                        overflowWrap: 'anywhere',
                    },
                }
            }}
        >
            <Link href={"/" + cat.url_path + ".html"} passHref>
                {productImgUrl &&
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="product-img">
                        <img src={productImgUrl} alt={(name || cat.name)} style={{ objectFit: "contain" }} />
                    </Box>}
                <Box className="img" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {image ? (
                        <img src={image} alt={(name || cat.name)} style={{ objectFit: "contain" }} />
                    ) : (
                        <Typography className="brand-name">{(name || cat.name)}</Typography>
                    )}
                </Box>

                <Box className="card-details center">
                    <Typography className="number">
                        {(count || cat.product_count)}
                        <Box component="span" className="articles"> {t("articles")} </Box>
                    </Typography>
                </Box>
            </Link>
        </Box>
    );
};

export default BrandCard;