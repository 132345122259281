import { Box } from '@mui/material';
import cssVars from '../../styles/vars.module.scss'
import Modal from '@mui/material/Modal';

const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: cssVars.white,
    border: '1px solid #707070',
    borderRadius: cssVars.radius,
    boxShadow: 24,
    p: "46px 28px",
    maxWidth: "535px",
    minWidth: "300px"
};

const MyModal = ({ open, setOpen, sx = {}, children, noOnClose = false }) => {

    return (
        <Modal
            open={open}
            onClose={noOnClose ? () => { } : () => setOpen(false)}
            aria-labelledby="modal-cart-delete"
            aria-describedby="modal-cart-delete"

        >
            <Box sx={{ ...modalStyle, ...sx }}>
                {children}
            </Box>
        </Modal>
    );
}


export default MyModal;
