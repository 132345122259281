import { Box } from "@mui/material";
import ProductTitle from "./productTitle";
import StockStatus from "../stock-status/stockStatus";

const ProductCardHeader = ({ product, isVariant, isNew, isCalibration }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" mr="-5px" minHeight={38}>
            <ProductTitle product={product} isVariant={isVariant} isNew={isNew} isCalibration={isCalibration}/>
            {
                isCalibration
                    ?
                    null
                    :
                    <StockStatus product={product} />
            }

        </Box>
    );
}

export default ProductCardHeader;