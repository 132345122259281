import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useGlobalStore } from "../../stores/globalStore";
import MyModal from "../my-modal";
import useTranslation from "../../hooks/useTranslation";
import { useEffect } from "react";
import { useState } from "react";
import cssVars from "../../styles/vars.module.scss";
import { useSnackbar } from "notistack";
import { useCartActions } from "../../hooks/cart/useCartActions";
import { useAuthStore } from "../../stores/authStore";
import parseSearchParams, { strDecode } from "../../helpers/SearchParams";
import { useRouter } from "next/router";

const CalibrationModal = () => {
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const calibrations = { brand: searchParams?.filters?.brand, model: searchParams?.filters?.model }

    const open = useGlobalStore(s => s.openCalibrationModal)
    const setOpen = useGlobalStore(s => s.setOpenCalibrationModal)
    const calibrationProduct = useGlobalStore(s => s.calibrationProduct)


    const { t } = useTranslation()

    const [serialNumber, setSerialNumber] = useState("")

    useEffect(() => {
        setSerialNumber("")
    }, [open])

    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const { addSimpleProductsToCart, disabled: cartDisabled } = useCartActions()
    const cartId = useAuthStore(state => state.cartId);
    const cartReady = !cartDisabled


    const { openMiniCart } = useGlobalStore(s => s)

    const addToCart = (e, product) => {
        e.preventDefault();
        e.stopPropagation();
        if (cartReady) {
            if (serialNumber) {
                setLoading(true)
                const options = product.selected_options.sort((a, b) => a.sort_order - b.sort_order)
                console.log(options);
                console.log(calibrations);
                const customizable_options = [
                    { id: parseInt(strDecode(options.at(0).uid)?.split('/')?.at(1)), value_string: calibrations.brand },
                    { id: parseInt(strDecode(options.at(1).uid)?.split('/')?.at(1)), value_string: calibrations.model },
                    { id: parseInt(strDecode(options.at(2).uid)?.split('/')?.at(1)), value_string: serialNumber }
                ]

                addSimpleProductsToCart({
                    variables: { cartId: cartId, cartItems: [{ data: { sku: product.sku, quantity: product.custom_stock_item.min_sale_qty }, customizable_options }] },
                    onCompleted: (data) => {
                        setLoading(false)
                        if (data) {
                            if (data.action?.user_errors?.length > 0) {
                                for (let i = 0; i < data.action?.user_errors?.length; i++) {
                                    const userError = data.action?.user_errors.at(i)?.message
                                    enqueueSnackbar(userError, { variant: 'error' })
                                }
                            }
                            else {
                                setOpen(false)
                                openMiniCart()
                            }
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false)
                    }
                })
            }
            else {
                setFormCheck(true)
            }
        }
    }

    const [formCheck, setFormCheck] = useState(false)

    return (
        <MyModal open={open} setOpen={setOpen} noOnClose={false} sx={{ p: 4 }}>
            <Box component="form" noValidate onSubmit={(e) => addToCart(e, calibrationProduct)}>
                <Box position="relative">
                    <TextField
                        id="outlined-basic"
                        label={t("Serial Number")}
                        variant="outlined"
                        value={serialNumber}
                        onChange={(e) => {
                            setSerialNumber(e.target.value)
                        }}
                        sx={{ width: "100%", mb: 2, minWidth: "300px" }}
                    />
                    <Box position="absolute" top={60} fontSize={12} color={cssVars.red} display={formCheck && !serialNumber ? "block" : "none"}>{t("Serial number is required")}</Box>
                </Box>
                <Button
                    variant="outlined"
                    sx={{ width: "100%", bgcolor: `${cssVars.blue} !important`, color: "#fff !important", "&:hover": { bgcolor: `${cssVars.darkBlue} !important` }, mt: 2 }}
                    type="submit"
                    disabled={loading}
                >
                    {t("Add to cart")}
                    {loading ? <CircularProgress sx={{ ml: 1 }} style={{ width: "17px", height: "17px", color: cssVars.extraDarkGray }} /> : null}

                </Button>
            </Box>
        </MyModal>
    );
}

export default CalibrationModal;