import { Box } from "@mui/material";
import useAddZeroes from "../../hooks/useAddZeroes";
import { useCurrencyStore } from "../../stores/currencyStore";
import { useGlobalStore } from "../../stores/globalStore";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import cssVars from "../../styles/vars.module.scss";
import useTranslation from "../../hooks/useTranslation";
import PercentOff from "../product-card/percentOff"
import WhatshotIcon from "@mui/icons-material/Whatshot";

/**
 * PriceBox component displays the price of a product, optionally including tax and showing the old price if applicable.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.product - The product object containing price details.
 * @param {string} [props.variant="plp"] - The variant of the price box, defaults to "plp".
 * @returns {JSX.Element} The rendered PriceBox component.
 */
const PriceBox: React.FC<{ product: any, variant?: string }> = ({ product, variant = "plp" }) => {
    const currency = useCurrencyStore(state => state.currency);
    const includeTax = useGlobalStore(state => state.includeTax);
    if (product.sku === "419.050.100") {
        console.log(product.display_tax);
    }

    // Determine the price based on whether tax is included
    const price = includeTax
        ? product.display_tax.final_price_with_tax
        : product.display_tax.final_price_without_tax;

    // Determine the old price if there is a discount
    const oldPrice = product.price_range.maximum_price.discount.amount_off > 0
        ? (includeTax
            ? product.display_tax.regular_price_with_tax
            : product.display_tax.regular_price_without_tax)
        : null;

    const { z } = useAddZeroes();
    const { t } = useTranslation();

    return (
        variant === "plp" || variant === "search"
            ?
            <Box className="roboto" display="flex" alignItems="end" sx={{ fontWeight: 500, fontSize: variant === 'plp' ? "20px" : variant === "search" ? "16px" : { xs: "26px", lg: "30px" }, color: oldPrice && product.on_sale ? cssVars.red : cssVars.black }}>
                {
                    variant === 'plp'
                        ?
                        <Box>
                            <ShoppingBasketIcon
                                sx={{ fontSize: "30px", color: oldPrice && product.on_sale ? cssVars.red : cssVars.blue, mr: 1 }}
                            />
                        </Box>
                        :
                        null
                }
                <Box mb={-.1}>
                    <Box component="span" mr={0.75}>{z(price)}</Box>
                    {oldPrice && (
                        <Box fontSize={12} mr={0.5} component="span">
                            {t("before")} {z(oldPrice)}
                        </Box>
                    )}
                </Box>
            </Box>
            :
            variant === "pdp" || variant === "secondary" || variant === "relation"
                ?
                <Box className="roboto" fontWeight={600} sx={{ display: variant === "relation" ? "flex" : "block", alignItems: "center", justifyContent: "end" }}>
                    {
                        oldPrice
                            ?
                            <Box display="flex" alignItems="center" justifyContent="end">
                                <Box color={cssVars.extraDarkGray} sx={{ textDecoration: "line-through" }}>{z(oldPrice, currency)}</Box>
                                <Box sx={{ display: "flex", alignItems: "center" }}><WhatshotIcon sx={{ fill: cssVars.red, mx: .5, mb: .5 }} /><PercentOff product={product} isVariant={false} type="pdp" /></Box>
                            </Box>
                            :
                            null
                    }
                    <Box sx={{ color: oldPrice && product.on_sale ? cssVars.red : cssVars.black, textAlign: "end" }} fontSize={variant === "pdp" ? 36 : 18}>
                        {z(price, currency)}
                    </Box>
                </Box>
                :
                null
    );
};

export default PriceBox;