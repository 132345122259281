const useAddZeroes = () => {

    const groupNumber = (n, s = "'", s2 = '.') => {
        let [a, b] = n.split('.')
        if (a.length < 4)
            return n
        let aa = ""
        for (let i = a.length; i > 0; i = i - 3) {
            if (i - 3 >= 0)
                aa = a.slice(i - 3, i) + (aa ? s : "") + aa;
            else
                aa = a.slice(0, i) + s + aa;
        }
        return aa + (b ? s2 + b : "")
    }

    const z = (num, currency) => {
        let n = Number(num)
        n = n.toFixed(2)
        const c = currency || ""
        const spaceOrNot = c.length === 3 ? ' ' : ''
        return `${c}${spaceOrNot}${groupNumber(n)}`
    }
    return { z }
}


export default useAddZeroes;