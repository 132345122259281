import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Variants = ({ isVariant, variantOptions }) => {

    const getVariantAttrLabel = (option) => {
        return (parentVariantAttributes.filter((attr) => attr.sku === product.sku && attr.code === option)[0]?.label || option)
    }

    const getVariantAttrValue = (option) => {
        return (parentVariantAttributes.filter((attr) => attr.sku === product.sku && attr.code === option)[0]?.value || '- - -')
    }

    return (
        isVariant ? (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {
                    variantOptions.split(',').map((option, i) => (
                        <Box
                            key={i}
                            className="option"
                            sx={{
                                textAlign: 'center',
                                mb: 3,
                                px: 2,
                                width: 'calc(33% - 8px)',
                            }}
                        >
                            <Typography
                                title={getVariantAttrLabel(option)}
                                className="option-label"
                                sx={{
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    mb: 2,
                                    fontSize: 12,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {getVariantAttrLabel(option)}
                            </Typography>
                            <Box
                                className="roboto"
                                sx={{
                                    fontSize: 12,
                                    px: 1,
                                    fontWeight: 500,
                                    py: 1,
                                    width: 'fit-content',
                                    borderRadius: 25,
                                    minWidth: 60,
                                }}
                            >
                                {getVariantAttrValue(option)}
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        ) : null
    );
};

export default Variants;