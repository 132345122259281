import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT, CUSTOMER_FRAGMENT } from "./fragments";

export const CREATE_CUSTOMER = gql`
mutation createCustomerV2(
  $firstname: String!
  $lastname: String!
  $email: String!
  $password: String!
  $gender: Int
  $telephone: String
  $company: String
  
) {
  createCustomerV2(
    input: {
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      gender: $gender
      telephone: $telephone
      company: $company
    }
  ) {
    __typename
  }
}
`;

export const UPDATE_CUSTOMER = gql`
${CUSTOMER_FRAGMENT}
mutation updateCustomerV2(
  $firstname: String!
  $lastname: String!
  $gender: Int
  $is_subscribed: Boolean
) {
  action:updateCustomerV2(
    input: {
      firstname: $firstname
      lastname: $lastname
      gender: $gender
      is_subscribed: $is_subscribed
    }
  ) {
    customer{
        ...CustomerFragment
    }
  }
}
`;

export const UPDATE_CUSTOMER_EMAIL = gql`
${CUSTOMER_FRAGMENT}
mutation updateCustomerEmail($email:String!,$password:String!){
  action:updateCustomerEmail(email:$email,password:$password){
    customer{
        ...CustomerFragment
    }
  }
}
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
${ADDRESS_FRAGMENT}
mutation createCustomerAddress(
  $firstname: String!
  $lastname: String!
  $telephone: String
  $fax: String
  $company: String
  $country_code: CountryCodeEnum!
  $region_id: Int
  $postcode: String!
  $city: String
  $street: [String]
  $default_shipping: Boolean = false
  $default_billing: Boolean = false
  $suffix: String
  $prefix: String
) {
  action:createCustomerAddress(
    input: {
      firstname: $firstname
      lastname: $lastname
      telephone: $telephone
      fax: $fax
      company: $company
      country_code: $country_code
      region: { region_id: $region_id }
      postcode: $postcode
      city: $city
      street: $street
      default_shipping: $default_shipping
      default_billing: $default_billing
      suffix: $suffix
      prefix: $prefix
    }
  ) {
    ...AddressFragment
  }
}
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
${ADDRESS_FRAGMENT}
mutation updateCustomerAddress(
  $id: Int!
  $firstname: String
  $lastname: String
  $telephone: String
  $company: String
  $country_code: CountryCodeEnum
  $region_id: Int
  $postcode: String
  $city: String
  $street: [String]
  $default_shipping: Boolean
  $default_billing: Boolean
  $suffix: String
  $prefix: String
) {
  action:updateCustomerAddress(
    id: $id
    input: {
      firstname: $firstname
      lastname: $lastname
      telephone: $telephone
      company: $company
      country_code: $country_code
      region: { region_id: $region_id }
      postcode: $postcode
      city: $city
      street: $street
      default_shipping: $default_shipping
      default_billing: $default_billing
      suffix: $suffix
      prefix: $prefix
    }
  ) {
    ...AddressFragment
  }
}
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
mutation deleteCustomerAddress($id:Int!){
  action:deleteCustomerAddress(id:$id)
}
`;

export const CHANGE_CUSTOMER_PASSWORD = gql`
mutation changeCustomerPassword($currPass:String!,$newPass:String!){
  changeCustomerPassword(currentPassword:$currPass,newPassword:$newPass){
    __typename
  }
}`;

export const SUBSCRIBE_EMAIL_TO_LETTER = gql`
mutation subscribeEmailToNewsletter($email: String!){
  action:subscribeEmailToNewsletter(
    email: $email
  ) {
    status
  }
}
`;


// b2b files upload
export const CREATE_CUSTOMER_FILES = gql`
mutation createCustomerFiles($input: [CustomerFileInput!]!) {
  createCustomerFiles(input: $input) {
    id
    status
    message
  }
}
`

export const CREATE_B2B_CUSTOMER = gql`
mutation createCustomerV2(
  $firstname: String!
  $lastname: String!
  $email: String!
  $itm_cardcode: String!
  $gender: Int
  $telephone: String
  $role: String!
) {
    createCustomerV2(
    input: {
    firstname: $firstname
    lastname: $lastname
    email: $email
    itm_cardcode: $itm_cardcode
    gender: $gender
    telephone: $telephone
    role: $role
    }
    ) {
        __typename
    }
}`


export const UPDATE_COMPANY_EMPLOYEE = gql`
mutation updateCompanyEmployee($id:Int!, $is_disabled:Boolean!,$role: String){
    updateCompanyCustomer(
      input: {
        company_customer_id: $id
        role: $role
      }
      is_disabled: $is_disabled
    ) {
      customer {
        is_disabled
        role
      }
    }
}`;

export const REMOVE_CUSTOMER_FROM_WISHLIST = gql`
mutation removeCustomerFromWishlist($wishlistId:String!, $customerEmail:String!){
  removeCustomerFromWishlist(wishlistId: $wishlistId, customerEmail: $customerEmail) {
      status
      message
  }
}`;

export const ASSIGN_CUSTOMER_TO_WISHLIST = gql`
mutation assignCustomerToWishlist($wishlistId:String!, $customerEmail:String!){
  assignCustomerToWishlist (wishlistId: $wishlistId, customerEmail: $customerEmail) {
      status
      message
  }
}`;
