import { Box, Button, CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { validateEmail } from "../../helpers/validation";
import { CustomerContext } from "../../Providers/CustomerContext";
import cssVars from "../../styles/vars.module.scss"
import useTranslation from "../../hooks/useTranslation";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Newsletter = () => {

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { subscribeNewsLetter, subscribeLoading } = useContext(CustomerContext)

    const [email, setEmail] = useState("")

    const subscribe = () => {
        if (validateEmail(email)) {
            subscribeNewsLetter({
                variables: { email },
                onCompleted: (data) => {
                    if (data) {
                        enqueueSnackbar(t("Thank you for subscribing to our newsletter!"), { variant: 'success' });
                        setEmail("")
                    } else {
                        enqueueSnackbar(t("Subscription failed. Please try again."), { variant: 'error' })
                    }
                },
                onError: (err) => {
                    enqueueSnackbar(err.message, { variant: 'error' })
                }
            })
        }
        else {
            enqueueSnackbar(t("Invalid email format. Please enter a valid address and try again."), { variant: 'error' })
        }
    }

    return (
        <Box className="newsletter" py="35px" px={2}>
            <div className="container-lg" >
                <hr style={{ marginBottom: "40px" }} />
                <Grid container columns={2} spacing={{ xs: 0, lg: 3 }} >
                    <Grid item xs={2} lg={1}>
                        <Box display="block" sx={{ textAlign: { xs: "center", lg: "start" }, pr: { lg: 3 }, color: cssVars.primary, fontWeight: "700 !important", fontSize: 25 }} >
                            {t("Subscribe to our newsletter")}  <br />

                        </Box>
                        <Box
                            display="block"
                            sx={{
                                mt: 2,
                                color: "#575758",
                                lineHeight: "24px",
                                fontSize: "18px",
                                textAlign: { xs: "center", lg: "start" }
                            }}
                        >
                            {t("Every week, new products and actions to discover")}!
                        </Box>
                    </Grid>
                    <Grid sx={{ display: { xs: "block", lg: "flex" }, textAlign: { xs: "center", lg: "start" }, mt: { xs: 3, lg: 0 } }} alignItems="center" justifyContent="end" item xs={2} lg={1}>
                        {/* <Box maxWidth={304} component="span" sx={{ color: cssVars.darkBlue, fontSize: "16px" }} >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</Box> */}
                        <Box sx={{ maxWidth: { xs: 300, sm: 500 }, mt: { xs: 3, lg: 0 }, mx: { xs: "auto", lg: 0 }, "& .MuiFormControl-root": { borderRadius: "30px", ".MuiInputBase-root": { borderRadius: "30px" } }, width: "100%" }} position="relative">
                            <TextField fullWidth id="outlined-basic" variant="outlined" value={email}
                                onChange={(e) => { setEmail(e.target.value) }} placeholder={t("Your email address") + '...'}
                                InputProps={{
                                    sx: { pr: "130px" },
                                    startAdornment: <InputAdornment position="start" sx={{ color: cssVars.darkGray }}><MailOutlineIcon sx={{ fontSize: "20px" }} /></InputAdornment>,
                                }}
                            />
                            <Button variant="contained" sx={{ position: { sm: "absolute" }, my: { xs: 2, sm: 0 }, right: 0, borderRadius: "30px", px: "30px", bgcolor: cssVars.primary, fontWeight: "400 !important", ':hover': { bgcolor: cssVars.hoverColor } }} onClick={subscribe} disabled={subscribeLoading}>
                                {t("Subscribe")}
                                {subscribeLoading ? <CircularProgress sx={{ ml: 1 }} style={{ width: "15px", height: "15px", color: cssVars.white }} /> : null}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box >
    );
}

export default Newsletter;