import { gql } from "@apollo/client";

export const GET_CMS_PAGE = gql`
query GetCmsPage($id: String!) {
  cmsPage(identifier: $id) {
    url_key
    content
    content_heading
    title
    page_layout
    meta_title
    meta_keywords
    meta_description
    identifier
  }
}
`;


export const GET_CMS_BLOCK = gql`
query cmsBlocks($id:String!) {
  cmsBlocks(identifiers:[$id]){
    items{
      content
      identifier
      title
    }
  }
}
`