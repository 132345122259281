import { gql } from "@apollo/client";

export const LOGIN = gql`
mutation generateCustomerToken($email:String!,$password:String!){
	login:generateCustomerToken(email:$email,password:$password){
        token
    }
}
`;

export const LOGOUT = gql`
mutation{
    logout:revokeCustomerToken{
    result
  }
}
`;


export const REQUEST_NEW_PASSWORD = gql`
 mutation requestPasswordResetEmail($email:String!){
       requestPasswordResetEmail(
         email: $email
       )
     }
`;

export const RESET_PASSWORD = gql`
mutation resetPassword($email: String!, $resetPasswordToken:String!,$newPassword:String!) {
      resetPassword(
        email: $email
        resetPasswordToken: $resetPasswordToken
        newPassword: $newPassword
    )
}
`;

export const CHANGE_CUSTOMER_PASSWORD = gql`
mutation changeCustomerPassword($currPass:String!,$newPass:String!){
  changeCustomerPassword(currentPassword:$currPass,newPassword:$newPass){
    __typename
  }
}`;

export const LOGIN_AS_CUSTOMER = gql`
mutation generateCustomerTokenAsAdmin($customer_email:String!){
    generateCustomerTokenAsAdmin(input:{customer_email:$customer_email}){
        customer_token
    }
}
`