import dictionary from '../lang/translations.json'
import { useRouter } from 'next/router';

type TranslationType = {
    [key: string]: { [key: string]: string }
}

const useTranslation = () => {

    const router = useRouter()
    const locale: string = router.locale ? router.locale : ''
    const translations: TranslationType = dictionary

    const t = (word: string): string => {
        if (translations && translations[word] && translations[word][locale]) {
            return translations[word][locale]
        }
        else {
            return word
        }
    }

    return { t }
}

export default useTranslation;