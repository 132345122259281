import { Box } from "@mui/material";
import cssVars from '../../styles/vars.module.scss'
import Tag from "./tag";
import useTranslation from "../../hooks/useTranslation";

const Tags = ({ product }) => {
    const { t } = useTranslation()
    return (
        <Box position="absolute" bottom={12} left={0}>
            <Tag text={t("pieces")} color={cssVars.blue} show={(product.__typename === "BundleProduct" && product.items[0].options[0]?.quantity)} />
            <Tag text={t("B.choice")} color={cssVars.blue} show={product.bugnard_choice} />
            <Tag text={t("swiss made")} color={cssVars.red} show={product.country_of_manufacture === "CH"} />
        </Box>
    );
}

export default Tags;