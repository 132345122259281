import Banners from "./Banners";
import Campaigns from "./Campaigns";

const PromoProWidget = ({ data, useFor }) => {
    const type = data.every(item => item.type === 'banner') ? "banner" : data.every(item => item.type === 'campaign') ? "campaign" : null;
    return (
        {
            banner: (<Banners data={data} />),
            campaign: (<Campaigns data={data} />)
        }[(useFor || type)] || null
    );
}

export default PromoProWidget;