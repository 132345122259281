import WhatshotIcon from "@mui/icons-material/Whatshot";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import useTranslation from "../../hooks/useTranslation";
import { Box, Typography } from "@mui/material";
import cssVars from '../../styles/vars.module.scss'
import HandshakeIcon from '@mui/icons-material/Handshake';

const ProductTitle = ({ product, isVariant, isNew, isCalibration }) => {
    const { t } = useTranslation((state) => state.translate)

    return (
        isVariant
            ?
            <Box fontSize={12} color={cssVars.darkGray} pt="14px" >
                {product.sku}
            </Box >
            :
            <Box
                sx={{
                    color: product.on_sale ? cssVars.red : isNew ? cssVars.lightBlue : isCalibration ? cssVars.yellow : cssVars.black,
                    display: "inline-flex", alignItems: "center",
                    borderBottom: "4px solid",
                    textTransform: "uppercase",
                    py: .5
                }}
            >
                {product.on_sale
                    ?
                    <>
                        <WhatshotIcon sx={{ mr: 1 }} />
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                lineHeight: '1.625rem',
                                position: 'relative',
                                color: cssVars.black,
                            }}
                            component="h2" variant="h5">{t("Actions")}
                        </Typography>
                    </>
                    :
                    isNew
                        ?
                        <>
                            <RocketLaunchIcon sx={{ mr: 1 }} />
                            <Typography
                                sx={{
                                    fontSize: '0.875rem',
                                    fontWeight: 500,
                                    lineHeight: '1.625rem',
                                    position: 'relative',
                                    color: cssVars.black,
                                }}
                                component="h2" variant="h5">{t("New arrivals")}
                            </Typography>
                        </>
                        :
                        isCalibration
                            ?
                            <>
                                <HandshakeIcon sx={{ mr: 1 }} />
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: 500,
                                        lineHeight: '1.625rem',
                                        position: 'relative',
                                        color: cssVars.black,
                                    }}
                                    component="h2" variant="h5">{t("Calibration")}
                                </Typography>
                            </>
                            :
                            null
                }
            </Box>
    )
}

export default ProductTitle