import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../products/fragments";

export const WISHLIST_FRAGMENT = gql`
${PRODUCT_FRAGMENT}
fragment WishlistsFragment on Wishlist {
  id
  items_count
  items_v2 {
    page_info {
      current_page
      page_size
      total_pages
    }
    items {
      id
      quantity
      ... on ConfigurableWishlistItem{
        configurable_options{
          configurable_product_option_uid 
          configurable_product_option_value_uid
          option_label
          value_label
        }
      }
      product {
        ...ProductFragment
      }
    }
  }
}

`
