import { gql } from "@apollo/client";
import { BUNDLE_PRODUCT_FRAGMENT, PRODUCT_FRAGMENT } from "../products/fragments";
import { RECOMMENDATION_SUGGESTIONS } from "./fragments";


export const PRODUCTS_SEARCH = gql`
${PRODUCT_FRAGMENT}
${BUNDLE_PRODUCT_FRAGMENT}
query ProductSearch($currentPage: Int = 1, $inputText: String!, $pageSize: Int = 6, $filters: ProductAttributeFilterInput!, $sort: ProductAttributeSortInput) {
  products(currentPage: $currentPage, pageSize: $pageSize, search: $inputText, filter: $filters, sort: $sort) {
    items{
      ...ProductFragment
      ...BundleProductFragment
    }
    page_info {
      total_pages
      page_size
      current_page
      __typename
    }
    total_count
    __typename
  }
}
`;

export const GET_SEARCH_FILTERS = gql`
query getFilters($inputText: String!){
  products(search: $inputText){
    total_count
    aggregations {
    attribute_code
    count
    label
    position
    options{
        count
        label
        value
      }
    }
  }
}
`;

export const GET_SEARCH_SORT_FIELDS = gql`
query getSortFields($inputText: String!){
  products(search: $inputText){
    sort_fields {
      default
      options {
        label
        value
      }
    }
  }
}
`
export const GET_AUTO_COMPLETE_RESULTS = gql`
query getAutocompleteResults($search: String!) {
  products(search: $search, currentPage: 1, pageSize: 4) {
    items {
      id
      uid
      name
      sku
      canonical_url
      small_image {
        url
        __typename
      }
      custom_attributes{
        attribute_metadata{
          code
        }
        entered_attribute_value{
            value
          }
        selected_attribute_options{
          attribute_option{
            label
            swatch_image
            uid
          }
        }
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
    __typename
  }
  }
}
`;

export const GET_POPULAR_SEARCHES = gql`
query getPopularSearches($search: String) {
  getPopularSearches: xsearchPopularSearches(search: $search) {
    code
    total_count
    items {
      name
      num_results
      url
    }
  }
}
`

export const GET_CATEGORIES_SEARCHES = gql`
query getCategoriesSearches($search: String) {
  getCategoriesSearches: xsearchCategories(search: $search) {
    code
    total_count
    items {
      name
      url
    }
  }
}
`

export const GET_PRODUCTS_SEARCHES = gql`
query getProductsSearches($search: String) {
  getProductsSearches: xsearchProducts(search: $search) {
    code
    total_count
    items {
      id
      sku
      name
      canonical_url
      brand_label
      small_image{
        url
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
    }
  }
}
`


export const GET_PRODUCTS_SEARCHES1 = gql`
query getProductsSearches1($search: String) {
  getProductsSearches: products(search: $search) {
    total_count
    items {
      id
      sku
      name
      canonical_url
      brand
      on_sale
      small_image{
        url
      }
      display_tax {
        regular_price_without_tax
        regular_price_with_tax
        final_price_without_tax
        final_price_with_tax
        base_price
        price
        tax
        rate
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      custom_attributes{
          attribute_metadata{
            code
          }
          entered_attribute_value{
            value
          }
          selected_attribute_options{
            attribute_option{
              label
              swatch_image
              uid
            }
          }
        }
    }
  }
}
`