import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface PaymentStoreType {
    paymentOngoing: boolean
    startPayment: () => void
    endPayment: () => void
    backupCartId: string
    setBackupCartId: (id: string) => void
}

export const usePaymentStore = create<PaymentStoreType>()(
    persist(
        (set) => ({
            paymentOngoing: false,
            startPayment: () => {
                set(() => ({ paymentOngoing: true }))
            },
            endPayment: () => {
                set(() => ({ paymentOngoing: false, backupCartId: "" }))
            },
            backupCartId: "",
            setBackupCartId: (id) => {
                set(() => ({ backupCartId: id }))
            },
        }),
        {
            name: "paymentStore",
            storage: createJSONStorage(() => localStorage),
        }
    )
)
