import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import { useGlobalStore } from '../../stores/globalStore';

const ProgressBar = ({ setShowProgress, loadingPage }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (!loadingPage) {
      setProgress(100);
    }
  }, [loadingPage])

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 100) {
        setShowProgress(false)
      }
    }, 350);

    return () => {
      clearInterval(timer);
    };
  }, [progress])


  return (
    <Box id="page-progress-bar" zIndex={1201} sx={{ width: '100%' }} position="fixed" top={0} right={0} left={0}>
      <LinearProgress variant="determinate" value={progress} onCom />
    </Box>
  );
}

const WithProgress = () => {
  const loadingPage = useGlobalStore((state) => state.loadingPage)
  const [showProgress, setShowProgress] = useState(false)

  useEffect(() => {
    if (loadingPage) {
      setShowProgress(true)
    }
  }, [loadingPage])

  return (
    loadingPage || showProgress
      ?
      <ProgressBar setShowProgress={setShowProgress} loadingPage={loadingPage} />
      :
      null
  )

}

export default WithProgress;