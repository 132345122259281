import { Box } from "@mui/material";
import Link from "next/link";
import cssVars from "../../styles/vars.module.scss";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";

const ThemeCard = ({ cat, wideView = false }) => {
    const isLG = useMediaQuery('(min-width:992px)');
    const { t } = useTranslation()
    console.log(cat);
    console.log(wideView);

    const { title, item_count, name, product_count } = cat;
    const thumbnail = cat.thumbnail.startsWith("http") ? cat.thumbnail : process.env.NEXT_PUBLIC_BASE_URL + cat.thumbnail;

    return (
        <Box sx={{
            "& a": {
                display: "block",
                textDecoration: "none",
                boxShadow: cssVars.shadow,
            }
        }}>
            <Link href={"/" + cat.url_path + ".html"}>
                <Box
                    sx={{
                        borderRadius: cssVars.radius,
                        py: 1,
                        px: 2,
                        height: '305px',
                        border: `1px solid ${cssVars.darkGray}`,
                        backgroundImage: `url(${thumbnail})`,
                        backgroundPosition: 'bottom -10px right 12px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: wideView && isLG ? 'auto 232px' : 'auto 212px',
                        boxShadow: cssVars.shadow,
                        '& .card-title': {
                            '& h3': {
                                fontSize: '20px',
                                lineHeight: '44px',
                                color: '#3E3E40',
                                fontWeight: 600,
                            },
                            '& .number': {
                                fontSize: '16px',
                                lineHeight: '30px',
                                color: cssVars.black,
                                fontWeight: 600,
                                display: wideView && isLG ? 'inline-block' : 'block',
                                '& .articles': {
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    fontWeight: 500,
                                    color: cssVars.extraDarkGray,
                                },
                            },
                        },
                        '& .selection-body': {
                            lineHeight: '1.4rem',
                            color: cssVars.extraDarkGray,
                            width: '50%',
                            fontSize: '15px',
                            mt: 2,
                        },
                    }}
                >
                    <Box className="card-title">
                        <Typography variant="h3">{(title || name)}</Typography>
                        <Typography className="number">
                            {(item_count || product_count)} <Box component="span" className="articles">{t("articles")}</Box>
                        </Typography>
                    </Box>
                    {wideView && isLG && cat.description && (
                        <Box className="selection-body" dangerouslySetInnerHTML={{ __html: cat.description }} />
                    )}
                </Box>
            </Link>
        </Box>
    );
}

export default ThemeCard;