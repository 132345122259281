import { Box, Grid } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import Link from "next/link";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlogCard from '../blogs/blogCard'
import Carousel from "../carousel";

const BlogsCarousel = ({ blogs }) => {
    console.log(blogs);
    const { t } = useTranslation()

    const responsive = {
        0: {
            items: 1,
            margin: 12
        },
        600: {
            items: 2,
            margin: 35
        },

        1200: {
            items: (3),
            margin: 35
        },
    }



    return (
        blogs?.length > 0
            ?
            <Box sx={{ my: 10 }}>
                <Grid display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: { xs: "29px", md: "26px" } }}>
                    <Box component="h2" textTransform="uppercase" sx={{ fontSize: { xs: "22px", md: "30px" } }}>{t("latest from blog")}</Box>
                    <Box>
                        <Link style={{ display: "flex", alignItems: "center", fontSize: { xs: '16px', md: '18px' } }} href="/blog.html">
                            {t('View All')}
                            <ArrowForwardIcon sx={{ ml: 1 }} />
                        </Link>
                    </Box>
                </Grid>
                <Carousel
                    className="owl-theme blogs-carousel"
                    dots={true}
                    nav={false}
                    responsive={responsive}
                >
                    {
                        blogs?.map(blog => (
                            <Box key={blog.url_key} mb={2}>
                                <BlogCard blog={blog} />
                            </Box>
                        ))
                    }
                </Carousel>
            </Box>
            :
            null
    );
}

export default BlogsCarousel;