import Link from 'next/link';
import React from 'react'
import { generateRandomId, prepareLink } from './helper';
import { Box } from '@mui/material';
import Script from 'next/script';
import Style from './style';
import CategoryCards from '../category-cards'
import ProductsCarouselWidget from '../widgets/productsCarouselWidget'
import Carousel from '../carousel';
import Image from 'next/image';
import BlogsCarousel from '../widgets/blogsCarousel'
import { strDecode } from '../../helpers/SearchParams';
import PromoProWidget from '../widgets/promoPro/promoProWidget';
import SubCatsWidget from '../widgets/subCats';


const elName = (name) => name.replaceAll("-", "_") + "Var"

const createJSX = (tagName, attributes, children) => {

    if (typeof tagName === "undefined") tagName = "div"
    if (tagName === "div" && attributes['data-element'] && attributes['data-element'].startsWith('sfc:')) {
        tagName = "sfc"
        attributes['data-element'] = attributes['data-element'].slice(4)
    }

    let Extra = null
    let scriptBody = ''
    let found = false
    let varName = ""
    for (const attrName in attributes) {
        if (attrName.startsWith("on")) {
            if (!found) {
                found = true
                if (!attributes.id) attributes.id = generateRandomId()
                varName = elName(attributes.id)
                scriptBody = ``;
            }
            const eventName = attrName.slice(2);
            scriptBody += `document.addEventListener('${eventName}', function(event) {
                if (event.target && event.target.id === '${attributes.id}') {
                    const ${varName} = document.getElementById('${attributes.id}'); 
                    ${attributes[attrName].replaceAll('this', varName)}
                }
            });
`;
            delete attributes[attrName];
        }
    }
    if (found)
        Extra = <Script id={varName + "Script"} dangerouslySetInnerHTML={{ __html: scriptBody }} defer={true} strategy='afterInteractive' />;


    let jsxElement;
    const customStyles = {
        "& .swiper-button-prev:after, .swiper-button-next:after": { fontSize: "24px" },
        "& .swiper-button-next": {
            cursor: 'pointer',
            right: "-5px",
            color: '#999999 !important',
        },
        "& .swiper-button-prev": {
            cursor: 'pointer',
            color: '#999999 !important',
        },
    };

    switch (tagName) {
        case 'a':
            attributes.href = prepareLink(attributes.href, process.env.NEXT_PUBLIC_BACKEND_URL)
            try {
                jsxElement = <Link {...attributes} >{children}</Link>;
            } catch (error) {
                jsxElement = <Link href="/">{children}</Link>;
            }
            break;
        case 'link':
            jsxElement = <Style src={attributes.href} />;
            break;
        case 'br':
            jsxElement = <br />;
            break;
        case 'body':
            jsxElement = <>{children}</>;
            break;
        case 'input':
            attributes.defaultValue = attributes.value
            delete attributes.value
            jsxElement = <input {...attributes} />;
            break;
        case 'hr':
            jsxElement = <hr {...attributes} />;
            break;
        case 'wbr':
            jsxElement = <wbr {...attributes} />;
            break;
        case 'img':
            if (!attributes.alt) attributes.alt = ""
            attributes.src = prepareLink(attributes.src)
            // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
            jsxElement = <img {...attributes} />;
            break;
        case 'script':
            if (attributes.src) {
                jsxElement = <Script {...attributes} defer={true} strategy="afterInteractive" />
            } else if (!children?.at(0)?.includes('require')) {
                children = children[0].replace("document.addEventListener('readystatechange', function () {", "setTimeout(function () {")
                jsxElement = <Script {...attributes} defer={true} strategy='lazyOnload' >{children}</Script>
            }
            break;
        case 'meta':
            jsxElement = null
            break;
        case "style":
            jsxElement = <Style>{children}</Style>
            break;
        case "sfc":
            switch (attributes['data-element']) {
                case "default-category-card":
                    const cats = JSON.parse(children)
                    jsxElement = <CategoryCards cats={cats} />
                    break;
                case "default-category-children":
                    const catsData = JSON.parse(children)
                    jsxElement = <ProductsCarouselWidget catsData={catsData} />
                    break;
                case "magb1graphql_subcategories":
                    console.log(children);
                    const subcats = JSON.parse(children)
                    jsxElement = <SubCatsWidget cats={subcats} type={attributes['data-type']}/>
                    break;
                case "main-slider":
                    const sliderData = JSON.parse(children)
                    if (sliderData?.banners?.length > 0) {
                        jsxElement = (
                            <Box sx={{ marginLeft: { xs: 'calc(50% - 50vw)', md: 0 }, width: { xs: '100vw', md: "100%" } }}>
                                <Carousel
                                    id={"main-slider" + "-" + sliderData.slider_id}
                                    className="owl-theme main-slider"
                                    items={1}
                                    nav={attributes["data-show-arrows"] && attributes["data-show-arrows"] === "1"}
                                    dots={(attributes["data-show-dots"] && attributes["data-show-dots"] === "1")}
                                    loop={attributes["data-infinite-loop"] && attributes["data-infinite-loop"] === "1"}
                                    autoplay={attributes["data-autoplay"] && attributes["data-autoplay"] === "1"}
                                    autoplaySpeed={2}
                                >
                                    {sliderData.banners.map((banner) =>
                                        <Link href={(banner?.url_banner || '/')} key={banner.id} style={{ width: "100%", marginTop: 16 }}>
                                            <Box position="relative" pt={{ xs: "160%", md: "46%" }} sx={{ "& img.image": { display: { xs: "none !important", md: "block !important" } }, "& img.mobile-image": { display: { xs: "block !important", md: "none !important" } } }}>
                                                <Image className='image' fill alt={"banner-image-" + banner.id} src={(process.env.NEXT_PUBLIC_BANNER_IMAGE_PATH + "image/" + banner.image)}
                                                    style={{
                                                        objectFit: "cover",
                                                        objectPosition: "center",
                                                    }}
                                                />
                                                <Image className='mobile-image' fill alt={"banner-image-" + banner.id} src={(process.env.NEXT_PUBLIC_BANNER_IMAGE_PATH + "mobile_image/" + banner.mobile_image)}
                                                    style={{
                                                        objectFit: "cover",
                                                        objectPosition: "center",
                                                    }}
                                                />
                                            </Box>
                                        </Link>
                                    )}
                                </Carousel>
                            </Box>
                        )
                    }
                    else {
                        jsxElement = null
                    }

                    break;
                case "default-blog":
                    jsxElement = <BlogsCarousel blogs={JSON.parse(children)} />
                    break;
                case "promopro_promotion":
                    const data = JSON.parse(children);
                    const newData = data.map(item => ({ ...item, content: strDecode(item.content) }))
                    jsxElement = <PromoProWidget data={newData} />
                    break;
                default:
                    jsxElement = null
                    break;
            }
            break;
        default:
            if (tagName === 'html' || tagName === 'body' || tagName === "head") tagName = "div"
            jsxElement = React.createElement(tagName || 'div', attributes, children);
            break;
    }
    return Extra ? <>{Extra}{jsxElement}</> : jsxElement
}

export default createJSX