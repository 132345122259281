import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    from
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuthStore } from "../stores/authStore";
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import possibleTypes from '../possibleTypes.json';

/**
 * Creates and returns a GraphQL client instance.
 *
 * @param {string | null} locale - The locale to be used by the client. Can be null.
 * @param {string | null} [token=null] - The authentication token to be used by the client. Defaults to null.
 * @param {string | null} [company_id=null] - The company ID to be used by the client. Defaults to null.
 * @param {boolean} [useGETForQueries=true] - Flag to determine if GET should be used for queries. Defaults to true.
 * @param {(() => void) | null} [handleSignOut=null] - Optional callback function to handle sign out. Defaults to null.
 * @returns {GraphQLClient} - The configured GraphQL client instance.
 */
export const getClient = (locale: string | null, token: string | null = null, company_id: string | null = null, useGETForQueries: boolean = true, handleSignOut: ((() => void) | null) = null) => {
    /**
     * Determines whether the application is in SSR mode.
     */
    const ssrMode = typeof window === 'undefined';
    /**
     * The GraphQL URI based on the environment and SSR mode.
     */
    const graphqlUri = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
        ? process.env.BACKEND_URL + "/graphql"
        : ssrMode
            ? "http://127.0.0.1:3025" + "/api/graphql"
            : window.location.origin + "/api/graphql";

    /**
     * The signOut function from the authStore.
     */
    const signOut = useAuthStore.getState().signOut;

    /**
     * The setCartId function from the authStore.
     */
    const setCartId = useAuthStore.getState().setCartId;


    /**
     * Creates an HTTP link with the specified URI, credentials, and useGETForQueries options.
     */
    const httpLink = createHttpLink({
        uri: graphqlUri,
        credentials: 'same-origin',
        useGETForQueries
    });

    /**
     * Creates an error link that catches errors from the GraphQL API and signs out the user if necessary or reset cart id.
     */
    const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
            graphQLErrors.map((error) => {
                const { extensions } = error
                if (token && extensions && extensions.category === `graphql-authorization`) {

                    signOut();
                    if (handleSignOut) {
                        handleSignOut()
                    }
                }
                if (error.message?.includes("The cart isn't active.") || error.message?.includes("Could not find a cart with ID") || error.message?.includes("Impossible de trouver un panier avec l'ID") || error.message?.includes("Kann keinen Warenkorb mit der ID")) {
                    setCartId('')
                }
            });
        }
    });


    /**
     * Creates an authentication link that sets the context for the Apollo Client.
     */
    const authLink = setContext((_, { headers }) => {

        return {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
                store: locale,
                ...(company_id ? { "client-id": company_id } : {}),
                ...headers,
            }
        }
    });

    /**
     * Creates an instance of ApolloClient with the specified options.
     */
    const client = new ApolloClient({
        ssrMode: ssrMode,
        assumeImmutableResults: true,
        cache: new InMemoryCache({
            possibleTypes
        }),
        link: from([authLink, errorLink, httpLink]),
        connectToDevTools: true,
        ...(
            ssrMode
                ? {
                    defaultOptions: {
                        query: {
                            fetchPolicy: 'no-cache'
                        }
                    }
                }
                : {}
        )
    });

    return client;
}