import HttpsRedirect from 'react-https-redirect';
import { CompareListProvider } from '../../Providers/CompareListContext';
import { CustomerProvider } from '../../Providers/CustomerContext';
import GraphqlProvider from "../../Providers/GraphqlProvider";
import { SearchProvider } from '../../Providers/SearchProvider';
import { WishListProvider } from '../../Providers/WishListContext';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cssVars from '../../styles/vars.module.scss';
import ProgressBar from '../progress-bar';
import { MultiWishListProvider } from '../../Providers/MultiWishListContext';
import SubLayout from './subLayout';
import B2bBar from '../b2b-bar';
import GlobalSpinnerLoader from './globalSipnnnerLoader';

const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
    return <IconButton sx={{ position: "absolute", right: 4, top: "calc(50% - 20px)" }} onClick={() => closeSnackbar(snackbarKey)}> <CloseIcon fontSize='small' sx={{ color: cssVars.white }} /> </IconButton>
}

const Layout = ({ pathname, children }) => {

    return (
        <HttpsRedirect>
            <SnackbarProvider autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
                <GraphqlProvider>
                    <CustomerProvider>
                        <B2bBar />
                        <MultiWishListProvider>
                            {/* <CompareListProvider> */}
                                <WishListProvider>
                                    <SearchProvider>
                                        <ProgressBar />
                                        <GlobalSpinnerLoader />
                                        <SubLayout pathname={pathname}>
                                            {children}
                                        </SubLayout>
                                    </SearchProvider>
                                </WishListProvider>
                            {/* </CompareListProvider> */}
                        </MultiWishListProvider>
                    </CustomerProvider>
                </GraphqlProvider>
            </SnackbarProvider>
        </HttpsRedirect>
    );
}

export default Layout;


