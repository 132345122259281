import { useContext, useState, useEffect, useRef } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { WishListContext } from '../../Providers/WishListContext';
import useTranslation from '../../hooks/useTranslation';
import { Box, Checkbox, CircularProgress, Collapse, FormControlLabel, Grow, List, ListItem, Slide, TextField, useMediaQuery, Zoom } from '@mui/material';
import Drawer from '../drawer/drawer';
import { MultiWishListContext } from '../../Providers/MultiWishListContext';
import { Fragment } from 'react';
import cssVars from '../../styles/vars.module.scss';
import { useSnackbar } from 'notistack';

const WishListSection = ({ show, setShowWishLists, sku, sx, className }) => {

    const menuRef = useRef()

    useEffect(() => {
        function handle(e) {
            if (menuRef.current && !menuRef.current?.parentElement.contains(e.target)) {
                setShowWishLists(false)
            }
        }
        window.addEventListener('click', handle);
        return () => {
            window.removeEventListener('click', handle);
        }
    }, [])

    const [currCheckBox, setCurrCheckBox] = useState(null)

    const {
        wishLists,
        addProductToWishlist,
        removeProductFromWishlist,
        addProductToWishlistLoading: addLoading,
        removeProductFromWishlistLoading: removeLoading,
        createWishListLoading: createLoading,
        createWishlist,
        isFav
    } = useContext(MultiWishListContext)

    const { enqueueSnackbar } = useSnackbar()

    const setFav = (e, id) => {
        e.stopPropagation();
        setCurrCheckBox(id)
        if (e.target.checked) {
            addProductToWishlist({
                variables: { wishlistId: id, sku: sku, qty: 1 },
                onCompleted: () => {
                    enqueueSnackbar("Product added to wishlist", { variant: 'success' });
                },
                onError: (err) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                }
            })
        }
        else {
            const wishList = wishLists.items.filter(wishList => wishList.wishlist_id === id)[0]
            const itemId = wishList.item_collection.items.filter(item => item.sku === sku)[0].item_id
            removeProductFromWishlist({
                variables: { itemId },
                onCompleted: () => {
                    enqueueSnackbar("Product removed from wishlist", { variant: 'success' });
                },
                onError: (err) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                }
            })
        }
    }

    const { t } = useTranslation((state) => state.translate)

    const [newWishList, setNewWishList] = useState('')

    const [formCheck, setFormCheck] = useState(false)

    const createNewWishListHandler = () => {
        if (newWishList && !createLoading) {
            createWishlist({
                variables: { title: newWishList, sort_order: (wishLists?.items?.length || 0) },
                onCompleted: (data) => {
                    addProductToWishlist({
                        variables: { wishlistId: data.wishlists.items.filter(wishlist => wishlist.title === newWishList)[0].wishlist_id, sku: sku, qty: 1 },
                        onCompleted: () => {
                            setNewWishList("")
                        },
                        onError: (err) => {
                            enqueueSnackbar(err.message, { variant: 'error' });
                        }
                    })
                },
                onError: (err) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                }
            })

        }
        else {
            setFormCheck(true)
        }
    }

    useEffect(() => {
        setFormCheck(false)
    }, [show])
    const filteredWishLists = wishLists?.items?.filter(item => !item.shared);
    
    const wishListSectionBody = (
        <Fragment>
            <List
                sx={{
                    mb: wishLists?.items?.length ? 3 : 0,
                    maxHeight: '250px',
                    overflowY: 'auto',
                    "&::-webkit-scrollbar": { width: 8 },
                    "&::-webkit-scrollbar-track": { background: '#F6F6F6' },
                    "&::-webkit-scrollbar-thumb": { background: '#ECECEC', borderRadius: 20 },
                }}
            >
                {filteredWishLists?.map(wishlist =>
                    <ListItem disablePadding key={wishlist.wishlist_id} sx={{ px: 2 }}>
                        <FormControlLabel
                            control={
                                ((addLoading || removeLoading) && currCheckBox === wishlist.wishlist_id)
                                    ?
                                    <Box display="flex" alignItems="center" justifyContent="center" width={24} height={42} mr={1.2}>
                                        <CircularProgress sx={{ display: "block", width: "18px !important", height: "18px !important", color: cssVars.darkGray }} />
                                    </Box>
                                    :
                                    <Checkbox
                                        checked={isFav(sku, wishlist.wishlist_id)}
                                        onClick={(e) => { setFav(e, wishlist.wishlist_id) }}
                                        sx={{
                                            color: `${cssVars.darkGray} ! important`,
                                            '&.Mui-checked': { color: `${cssVars.blue} !important` },
                                            pl: 0,
                                            ":hover": {
                                                bgcolor: "transparent"
                                            }
                                        }}
                                    />
                            }
                            label={
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        fontFamily: "inherit"
                                    }}>
                                    {wishlist.title} <Box component="span" className="count">({wishlist.product_count})</Box>
                                </Box>
                            }
                        />
                    </ListItem>
                )}
            </List>
            <Box fontSize={15} mb={1} maxWidth="100%">{t('Add a new wishlist')}</Box>
            <Box width="100%">
                <Box display="inline-flex" justifyContent="space-between" alignItems="center"  width="100%">
                    <TextField fullWidth error={formCheck && !newWishList} sx={{ mr: 1 }} variant='outlined' size='small' placeholder={t('name')} type="text" value={newWishList} onChange={(e) => { setNewWishList(e.target.value) }} />
                    <AddCircleIcon sx={{ fontSize: 42, color: createLoading ? cssVars.darkGray : cssVars.blue, cursor: "pointer" }} onClick={createNewWishListHandler} />
                </Box>
                <Box position="absolute" display={(formCheck && !newWishList) ? "block" : "none"} color={cssVars.red} fontSize={12}>{t("name is required")}</Box>
            </Box>
        </Fragment>
    )

    const desktopBaseStyle = {
        padding: '20px 18px 20px 20px',
        boxShadow: cssVars.show,
        borderRadius: cssVars.radius,
        color: cssVars.lightBlack,
        top: '90px',
        right: '16px',
        left: '16px',
        position: 'absolute',
        backgroundColor: '#fff',
        zIndex: 3,
        border: `1px solid ${cssVars.darkGray} !important`,
        cursor: 'default',
        ...sx,
    }

    return (
        <>
            <Zoom in={show}>
                <Box sx={desktopBaseStyle} ref={menuRef} onClick={(e) => { e.preventDefault(); e.stopPropagation() }} >
                    {wishListSectionBody}
                </Box>
            </Zoom>
        </>
    );
}

export default WishListSection;